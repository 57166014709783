import { EditableRow } from 'components/StyledBoostrapTable/EditableRow'
import { TenantId } from 'contexts/Tenants'
import { Quantity as QuantityType } from 'types/Quantity'
import { OrAllNulls } from 'types/utils'

// Either all limits + updated_by|at info are defined, or they're all null,
// hence the OrAllNulls wrapper for these properties.
// Note that balance and exposure can individually be null.
// There are relationships between them and margin_ratio and margin_limit_proportion,
// but they aren't really worth encoding.
export type MarginLimitUsage = {
  wallet_id: number
  tenant_id: TenantId
  exchange_id: number
  note: string | null
  balance: QuantityType | null
  exposure: QuantityType | null
  long_exposure: QuantityType | null
  short_exposure: QuantityType | null
  margin_ratio: number | null
  margin_ratio_long: number | null
  margin_ratio_short: number | null
  margin_limit_proportion: number | null
  margin_limit_proportion_long: number | null
  margin_limit_proportion_short: number | null
  worst_lower_limit: number | null
  worst_exposure: QuantityType | null
  worst_margin_ratio: number | null
  worst_margin_limit_proportion: number | null
  worst_limit_direction: 'long' | 'short' | null
  wallet_name: string
  wallet_type: string
} & OrAllNulls<{
  lower_limit_short: number
  upper_limit_short: number
  lower_limit_long: number
  upper_limit_long: number
  updated_at: string
  updated_by_id: number
  updated_by: {
    name: string
  }
}>

export type UncollateralizedPosition = {
  asset_id: number
  tenant_id: TenantId
  exchange_id: number
  position: number
}

export type TableDatum = MarginLimitUsage | UncollateralizedPosition

export type MarginLimitUsageEditable = EditableRow<MarginLimitUsage>

export type Row = MarginLimitUsageEditable | UncollateralizedPosition

export function isMarginLimitUsageEditable(
  row: Row,
): row is MarginLimitUsageEditable {
  return 'exposure' in row && 'isEditing' in row
}

export function isUncollateralizedPosition(
  row: Row | TableDatum,
): row is UncollateralizedPosition {
  return 'asset_id' in row && 'position' in row
}

export type MarginLimitKey =
  | 'lower_limit_short'
  | 'upper_limit_short'
  | 'lower_limit_long'
  | 'upper_limit_long'

export type FormValues = Record<MarginLimitKey, string> & { note: string }
