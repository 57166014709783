/** Possible row statuses, in order of descending priority */
export const RowStatuses = [
  'unknown',
  'data-error',
  'urgent-breach',
  'breach',
  'near-breach',
  'info',
  'normal',
] as const
export type RowStatus = (typeof RowStatuses)[number]

export type LimitComponentProps = {
  showStatuses?: RowStatus[]
  dashboard?: boolean
  onBreachStatusChange?: (inBreach: boolean) => void
}

export function maxRowStatus<T>(
  rows: T[],
  rowStatus: (row: T) => RowStatus,
): RowStatus {
  for (const status of RowStatuses) {
    if (rows.some(row => rowStatus(row) === status)) {
      return status
    }
  }
  return 'normal'
}

/**
 * JSX.Element undefined means we shouldn't render the table, e.g. because it
 * would have no rows.
 */
export type LimitsDashboardHook = () =>
  | { loaded: false }
  | { loaded: true; Component: JSX.Element | undefined; status: RowStatus }
