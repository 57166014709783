import React from 'react'

import { Button } from 'react-bootstrap'

import { Glyphicon } from './BootstrapShim'

type EditButtonProps = {
  isEditing: boolean
  setEditing: () => void
  dirty: boolean
  size?: 'sm' | 'lg'
}

const EditButton: React.FC<EditButtonProps> = ({
  isEditing,
  setEditing,
  dirty,
  size,
}) => {
  if (isEditing) {
    return (
      <React.Fragment>
        <Button
          key="submit"
          type="submit"
          disabled={!dirty}
          variant="success"
          size={size}
        >
          <Glyphicon glyph="check" />
        </Button>{' '}
        <Button type="reset" variant="danger" size={size}>
          <Glyphicon glyph="remove" />
        </Button>
      </React.Fragment>
    )
  } else {
    return (
      <Button
        key="edit"
        onClick={setEditing}
        variant="outline-primary"
        size={size}
      >
        <Glyphicon glyph="pencil" />
      </Button>
    )
  }
}

export default EditButton
