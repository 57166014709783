import { useEffect } from 'react'

import config, { isDevEnvironment } from 'config'
import { useLocation } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

type Event = { [k: string]: unknown }

export const tabId = uuidv4()

let logFetchErr = true

export const sendEvent = function (event: Event, timestamp?: string): void {
  event['trace.trace_id'] = tabId
  event.service_name = 'firefly'
  const headers = new Headers()
  headers.set('X-Honeycomb-Event-Time', timestamp ?? Date.now().toString())
  fetch(config.apiUrl + 'honeycomb', {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(event),
  }).catch(err => {
    if (isDevEnvironment()) {
      if (logFetchErr) {
        console.warn(err)
        logFetchErr = false
      }
    } else throw err
  })
}

export default function useHoneycomb(): void {
  const location = useLocation()

  useEffect(() => {
    const startTime = Date.now()
    const event = {
      name: 'navigation',
      pathname: location.pathname,
      'trace.parent_id': tabId,
      'trace.span_id': uuidv4(),
    } as Event
    const sendNavigation = (): void => {
      const end_time = Date.now()
      event.duration_ms = end_time - startTime
      event.end_time = end_time
      sendEvent(event, startTime.toString())
    }
    // Dismount doesn't run when the page is closed; listening to pagehide is
    // necessary to successfully send the last navigation event of a session.
    window.addEventListener('pagehide', sendNavigation)
    return (): void => {
      window.removeEventListener('pagehide', sendNavigation)
      setTimeout(sendNavigation, 0)
    }
  }, [location])
}

// Memory usage stats collected as soon as JS executes, so we can compare the
// delta later on page unload
const jsHeapUsed = window.performance.memory?.usedJSHeapSize
const jsHeapTotal = window.performance.memory?.totalJSHeapSize

const pageLoadEvent = function (): Event {
  const [nt] = window.performance.getEntriesByType(
    'navigation',
  ) as PerformanceNavigationTiming[]

  const event = {
    name: 'page-load',
    'trace.span_id': tabId,

    // User agent. We can parse the user agent into device, os name, os version,
    // browser name, and browser version fields server-side if we want to later.
    user_agent: window.navigator.userAgent,

    // Capture how large the user has made their current window
    window_height: window.innerHeight,
    window_width: window.innerWidth,
    // Capture how large the user's entire screen is
    screen_height: window.screen?.height,
    screen_width: window.screen?.width,

    // Navigation (page load) timings, transformed from timestamps into deltas
    timing_unload_ms: nt.unloadEventEnd,
    timing_dns_end_ms: nt.domainLookupEnd,
    timing_ssl_end_ms: nt.connectEnd,
    timing_response_end_ms: nt.responseEnd,
    timing_dom_interactive_ms: nt.domInteractive,
    timing_dom_complete_ms: nt.domComplete,
    timing_dom_loaded_ms: nt.loadEventEnd,

    // Some calculated navigation timing durations, for easier graphing in Honeycomb
    // We could also use a derived column to do these calculations in the UI
    // from the above fields if we wanted to keep our event payload smaller.
    timing_dns_duration_ms: nt.domainLookupEnd - nt.domainLookupStart,
    timing_ssl_duration_ms: nt.connectEnd - nt.connectStart,
    timing_server_duration_ms: nt.responseEnd - nt.requestStart,
    timing_dom_loaded_duration_ms: nt.loadEventEnd - nt.domComplete,

    // Entire page load duration
    duration_ms: nt.duration,
  } as Event

  const paints = window.performance.getEntriesByType('paint')

  // Loop through array of two PerformancePaintTimings and send both
  paints.forEach(paint => {
    if (paint.name === 'first-paint') {
      event.timing_first_paint_ms = paint.startTime
    } else if (paint.name === 'first-contentful-paint') {
      event.timing_first_contentful_paint_ms = paint.startTime
    }
  })

  // Redirect count
  // Find out if the user was redirected on their way to landing on this page,
  // so we can have visibility into whether redirects are slowing down the experience
  event.redirect_count = window.performance.navigation?.redirectCount

  // Memory info — also send this on unload so we can compare heap size and
  // understand how much memory we're using as the user interacts with the page
  if (window.performance.memory) {
    event.js_heap_size_total_b = jsHeapTotal
    event.js_heap_size_used_b = jsHeapUsed
  }

  return event
}

const timeOrigin = Math.round(window.performance.timeOrigin)

window.addEventListener('load', () => {
  // Wait a tick so this all runs after any onload handlers
  setTimeout(
    // Sends the event to our servers for forwarding on to api.honeycomb.io
    () => sendEvent(pageLoadEvent(), timeOrigin.toString()),
    0,
  )
})

const pageUnloadEvent = function (): Event {
  // Capture how long the user kept this window or tab open for
  const openDuration = (Date.now() - timeOrigin) / 1000

  const event = {
    name: 'page-unload',
    'trace.parent_id': tabId,
    'trace.span_id': uuidv4(),
    user_timing_window_open_duration_s: openDuration,
  } as Event

  // Memory info — also send this on load so we can compare heap size and
  // understand how much memory we're using as the user interacts with the page.
  if (window.performance.memory) {
    event.js_heap_size_used_start_b = jsHeapUsed
    event.js_heap_size_total_b = window.performance.memory.totalJSHeapSize
    event.js_heap_size_used_b = window.performance.memory.usedJSHeapSize
    event.js_heap_change_b =
      window.performance.memory.usedJSHeapSize - jsHeapUsed
  }

  return event
}

window.addEventListener('pagehide', () => sendEvent(pageUnloadEvent()))
