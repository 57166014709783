import React, { useEffect, useState } from 'react'

import { isProdEnvironment } from 'config'
import { useInterval, usePrevious } from 'react-use'

import useAlerts from 'contexts/Alerts'

type VersionData = {
  version: string
  builtAt: string
}

const VersionWatcherBase: React.FC = () => {
  const [version, setVersion] = useState<string | null>(null)
  const prevVersion = usePrevious(version)
  useInterval(getVersion, 15000)
  const showAlert = useAlerts()

  async function getVersion() {
    try {
      const res = await fetch('/version.json')
      if (res.ok) {
        const { version }: VersionData = await res.json()
        setVersion(version)
      }
    } catch (err) {
      console.warn('Unable to determine current version')
    }
  }

  useEffect(() => {
    if (!version || !prevVersion) return
    if (version !== prevVersion) {
      showAlert({
        message: `A new version of Firefly is available. Press 'R R' to refresh all windows.`,
        style: 'info',
        timeout: 1e9,
      })
    }
  }, [prevVersion, showAlert, version])

  return <></>
}

export const VersionWatcher: React.FC = () => {
  if (isProdEnvironment()) {
    return <VersionWatcherBase />
  }
  return <></>
}
