import React from 'react'
import { useRef } from 'react'

import { Temporal } from '@js-temporal/polyfill'
import { Col, Form } from 'react-bootstrap'

import { DateTimePicker } from 'components/DateTimePicker'
import { HotKeyHandlerMap } from 'components/HotKeysWithModal'
import { TimeZone } from 'types/TimeZone'

import { PlainDateTimeRange } from './PlainDateTimeRange'

export * from './PlainDateTimeRange'

type DateTimeRangePickerProps = {
  range: PlainDateTimeRange
  /**
   * Display a time zone at the end of the input, to denote what time zone the
   * `PlainDateTime` returned by this component is 'supposed' to be in. This is
   * used to display the time zone in the component, and is used for calculating
   * periods when using the hotkeys
   */
  timeZone?: TimeZone
  onChange: (range: PlainDateTimeRange) => void
  fromLabel?: React.ReactNode
  toLabel?: React.ReactNode
  startRequired?: boolean
  endRequired?: boolean
  min?: Temporal.PlainDateTime
  max?: Temporal.PlainDateTime
  autoFocus?: boolean
}

const hotKeyMap = {
  setDuration1Hour: 's h',
  setDuration1Day: 's d',
  setDuration1Week: 's w',
  setDuration1Month: 's m',
  switchField: 'f',
}

export const DateTimeRangePicker: React.FC<DateTimeRangePickerProps> = ({
  onChange,
  range,
  timeZone,
  fromLabel = 'From',
  toLabel = 'To',
  startRequired = false,
  endRequired = false,
  min,
  max,
  autoFocus = false,
}) => {
  const { start, end } = range
  const startRef = useRef<HTMLInputElement>(null)
  const endRef = useRef<HTMLInputElement>(null)

  function setStart(date: Temporal.PlainDateTime | undefined) {
    onChange(range.withStart(date))
  }

  function setEnd(date: Temporal.PlainDateTime | undefined) {
    onChange(range.withEnd(date))
  }

  function makeSetDuration(period: Temporal.DurationLike) {
    return function (event: KeyboardEvent) {
      event.preventDefault()

      if (!range.start) {
        return
      }
      const newEnd = timeZone
        ? range.start.toZonedDateTime(timeZone).add(period).toPlainDateTime()
        : range.start.add(period)
      setEnd(newEnd)
    }
  }

  function switchField(event: KeyboardEvent) {
    event.preventDefault()
    if (startRef.current?.isSameNode(document.activeElement)) {
      endRef.current?.focus()
    } else if (endRef.current?.isSameNode(document.activeElement)) {
      startRef.current?.focus()
    }
  }

  const hotKeyHandlers: HotKeyHandlerMap<typeof hotKeyMap> = {
    // duration handlers
    setDuration1Hour: makeSetDuration({ hours: 1 }),
    setDuration1Day: makeSetDuration({ days: 1 }),
    setDuration1Week: makeSetDuration({ weeks: 1 }),
    setDuration1Month: makeSetDuration({ months: 1 }),
    // focus handlers
    switchField,
  }

  return (
    <>
      <Form.Row>
        <Form.Group as={Col} style={{ marginBottom: 0 }}>
          <Form.Label>{fromLabel}</Form.Label>

          <DateTimePicker
            value={start}
            onChange={setStart}
            timeZone={timeZone}
            min={min}
            max={max}
            required={startRequired}
            hotKeyMap={hotKeyMap}
            hotKeyHandlers={hotKeyHandlers}
            hotKeyTitle="Date/Time Range Picker"
            ref={startRef}
            autoFocus={autoFocus}
          />
        </Form.Group>
        <Form.Group as={Col} style={{ marginBottom: 0 }}>
          <Form.Label>{toLabel}</Form.Label>
          <DateTimePicker
            value={end}
            timeZone={timeZone}
            min={start?.add({ minutes: 1 }) ?? min}
            max={max}
            onChange={setEnd}
            required={endRequired}
            hotKeyMap={hotKeyMap}
            hotKeyHandlers={hotKeyHandlers}
            hotKeyTitle="Date/Time Range Picker"
            ref={endRef}
          />
        </Form.Group>
      </Form.Row>
    </>
  )
}
