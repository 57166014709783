import React, { useLayoutEffect, useState } from 'react'

import mapValues from 'lodash/mapValues'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import StyledBootstrapTable from 'components/StyledBoostrapTable'
import useAlerts from 'contexts/Alerts'

import { MarginLimitsForApi, useActions } from './actions'
import { rowStatus } from './rows'
import {
  FormValues,
  MarginLimitUsage,
  Row,
  TableDatum,
  isUncollateralizedPosition,
} from './types'
import { ViewMode, useColumns } from './useColumns'
import {
  limitFieldsAllDirty,
  limitFieldsDirtyDefaults,
  useLimitsFieldWatcher,
} from './useLimitFieldsWatcher'

const rowClasses = (row: Row): string => {
  const status = rowStatus(row)
  switch (status) {
    case 'unknown':
    case 'data-error':
    case 'breach':
      return 'text-warning'
    case 'urgent-breach':
      return 'text-danger'
    case 'info':
      // we apply this at the column level
      return ''
    default:
      return ''
  }
}

function rewriteApiError(msg: string): string {
  if (/well_ordered_check/.test(msg)) {
    return 'Upper limit must be greater than lower limit'
  } else {
    return msg
  }
}

type MarginLimitsTableProps = {
  rows: TableDatum[]
  viewMode: ViewMode
}

export const MarginLimitsTable: React.FC<MarginLimitsTableProps> = ({
  rows,
  viewMode,
}) => {
  const [editingRow, setEditingRow] = useState<MarginLimitUsage>()
  const { register, handleSubmit, watch, setValue } = useForm<FormValues>()
  const { upsert } = useActions({ rewriteApiError })
  const showAlert = useAlerts()
  const setLimitFieldsDirty = useLimitsFieldWatcher({ watch, setValue })
  const columns = useColumns({
    register,
    setLimitFieldsDirty,
    viewMode,
  })

  function handleReset() {
    setLimitFieldsDirty(limitFieldsDirtyDefaults)
    setEditingRow(undefined)
  }

  async function onSubmit(values: FormValues) {
    if (!editingRow) throw new Error('Editing row is not defined')
    const { note, ...limitEntries } = values

    const preparedValues: MarginLimitsForApi = {
      note: note.trim() !== '' ? note.trim() : null,
      ...mapValues(limitEntries, v => +v),
    }
    const { succeeded } = await upsert(preparedValues, editingRow)
    if (succeeded) {
      showAlert({
        style: 'success',
        message: 'Limit updated successfully',
      })
      handleReset()
    }
  }

  // Select the first form field when we start editing
  useLayoutEffect(() => {
    if (editingRow?.wallet_id !== undefined) {
      document
        .querySelector<HTMLInputElement>('[name=lower_limit_short]')
        ?.select()
      // if we have a row which already has values, set all fields to dirty to
      // prevent auto-updating the upper limit fields
      if (editingRow.lower_limit_short !== null) {
        setLimitFieldsDirty(limitFieldsAllDirty)
      }
    }
  }, [
    editingRow?.lower_limit_short,
    editingRow?.wallet_id,
    setLimitFieldsDirty,
  ])

  const data: Row[] = rows
    .map(row => {
      if (isUncollateralizedPosition(row)) {
        return {
          ...row,
          id: `${row.tenant_id}-${row.exchange_id}-${row.asset_id}`,
        }
      }
      return {
        ...row,
        id: row.wallet_id,
        isEditing: row.wallet_id === editingRow?.wallet_id,
        setEditing: () => setEditingRow(row),
      }
    })
    .filter(row => {
      if (viewMode !== 'edit' && !isUncollateralizedPosition(row)) {
        return row.exposure !== null
      }
      return true
    })

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} onReset={handleReset}>
        <StyledBootstrapTable
          bootstrap4
          keyField="id"
          columns={columns}
          data={data}
          rowClasses={rowClasses}
          key={viewMode}
          defaultSorted={[
            { dataField: 'worst_margin_limit_proportion', order: 'asc' },
          ]}
          defaultSortDirection="desc"
          condensed
          striped
        />
      </Form>
    </>
  )
}
