import React, { useImperativeHandle, useRef } from 'react'

/**
 * Allows passing a ref down to child components, but returns a ref that is
 * accessible in the calling component. See:
 * https://reactjs.org/docs/hooks-reference.html#useimperativehandle and
 * https://stackoverflow.com/a/68163315
 */
export function useForwardedRef<T>(
  // React.Ref<T | null> is a slightly odd type because `ref.current` can already accept `null` anyway,
  // but typing it this way means that `useImperativeHandle` is OK with the callback returning `null`.
  ref: React.Ref<T | null> | undefined,
): React.RefObject<T> {
  const usableRef = useRef<T>(null)
  useImperativeHandle(ref, () => usableRef.current)
  return usableRef
}
