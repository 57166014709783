import { RowStatus } from '../types'

import { DeltaLimit, DeltaLimitUsage, isDeltaLimitUsage } from './types'

export const rowStatus = (row: DeltaLimitUsage | DeltaLimit): RowStatus => {
  if (!isDeltaLimitUsage(row)) {
    return 'normal'
  }
  if (row.delta_limit_lower === null) {
    return 'data-error'
  }
  if (row.delta_limit_proportion === null) {
    return 'unknown'
  }
  if (Math.abs(row.delta_limit_proportion) > 1) {
    return 'urgent-breach'
  }
  if (Math.abs(row.delta_limit_proportion) > 0.95) {
    return 'near-breach'
  }

  return 'normal'
}

export function addIdToRow<T extends DeltaLimitUsage | DeltaLimit>(
  deltaLimit: T,
): T & { id: string } {
  return {
    ...deltaLimit,
    id: `${deltaLimit.tenant_id}-${deltaLimit.asset_id ?? 'all-assets'}`,
  }
}
