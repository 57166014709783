import { useCallback, useEffect, useState } from 'react'

function getDocumentIsVisible() {
  return !document.hidden
}

export function usePageVisibility(cb?: (visible: boolean) => void): boolean {
  const [isVisible, setIsVisible] = useState(getDocumentIsVisible())

  const handleVisibilityChange = useCallback(() => {
    const visible = getDocumentIsVisible()
    setIsVisible(visible)
    if (typeof cb === 'function') {
      cb(visible)
    }
  }, [cb])

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [handleVisibilityChange])

  return isVisible
}
