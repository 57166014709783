import React, { useContext } from 'react'

import config from 'config'

import { usePollApi } from 'hooks/usePollApi'

export type TailnetUser = {
  /** Full email address of the user: 'sam.deere@lanternventures.com' */
  email: string
  /** Real name of the user: 'Sam Deere' */
  full_name: string
  /** Username portion of email address, before @ symbol: 'sam.deere' */
  username: string
  /** Hostname of our tailnet (with trailing period): 'tail6f62.ts.net.' */
  tailnet: string
  /** URL to user's profile picture */
  profile_picture_url: string
}

const TailnetUserContext = React.createContext<TailnetUser>({} as TailnetUser)

const devTailnetUser: TailnetUser = {
  email: 'seniortrader@lanternventures.com',
  full_name: 'Senior Trader',
  username: 'seniortrader',
  tailnet: 'tail6f62.ts.net.',
  profile_picture_url:
    'https://firefly.lanternventures.com/fake-profile-picture-url.png',
}

export const TailnetUserProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }): React.ReactElement => {
  if (config.isLocalhost) {
    return (
      <TailnetUserContext.Provider value={devTailnetUser}>
        {children}
      </TailnetUserContext.Provider>
    )
  }
  return <TailnetUserProviderBase>{children}</TailnetUserProviderBase>
}

export const TailnetUserProviderBase: React.FC<{
  children: React.ReactNode
}> = ({ children }): React.ReactElement => {
  const tailnetUser = usePollApi<TailnetUser, false>({ path: '/whoami' }, 60000)

  if (!tailnetUser) {
    return <>Loading Tailscale user...</>
  }

  return (
    <TailnetUserContext.Provider value={tailnetUser}>
      {children}
    </TailnetUserContext.Provider>
  )
}

export function useTailnetUser(): TailnetUser {
  return useContext(TailnetUserContext)
}
