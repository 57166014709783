import React, { useCallback, useContext, useState } from 'react'

import { Alert as AlertComponent } from 'react-bootstrap'

const AlertsContext = React.createContext<(..._: AlertOptions[]) => void>(
  () => {},
)

type AlertVariant =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'dark'
  | 'light'
export type AlertOptions = {
  style: AlertVariant
  message: string
  timeout?: number
}

type Alert = AlertOptions & {
  count: number
  timeoutId: ReturnType<typeof setTimeout>
}

function getAlertId(alert: AlertOptions): string {
  return JSON.stringify(alert)
}

export function AlertsProvider({
  children,
}: {
  children: React.ReactNode
}): React.ReactElement {
  const [state, setState] = useState({} as { [k: string]: Alert })

  const removeAlert = useCallback((id: string) => {
    setState(alerts => {
      const { [id]: removed, ...filtered } = alerts
      return filtered
    })
  }, [])

  const addAlerts = useCallback(
    (...alerts: AlertOptions[]) => {
      const newAlerts: { [k: string]: Alert } = {}
      alerts.flat().forEach(alert => {
        const id = getAlertId(alert)
        const timeout = alert.timeout ?? 10 * 1000

        newAlerts[id] = {
          ...alert,
          count: 1,
          timeoutId: setTimeout(() => removeAlert(id), timeout),
        }
      })
      setState(oldAlerts => {
        const newAlertIds = Object.keys(newAlerts)

        for (const id of newAlertIds) {
          // if we have an existing alert which is exactly the same as a new
          // one, just increment the count and reset the timeout
          if (oldAlerts[id]) {
            oldAlerts[id].count = oldAlerts[id].count + 1
            clearTimeout(oldAlerts[id].timeoutId)
            oldAlerts[id].timeoutId = newAlerts[id].timeoutId
            delete newAlerts[id]
          }
        }
        return { ...oldAlerts, ...newAlerts }
      })
    },
    [removeAlert],
  )

  const alerts = Object.entries(state).map(
    ([id, { style, message, count }]) => (
      <AlertComponent
        key={id}
        variant={style}
        dismissible
        onClose={(): void => removeAlert(id)}
      >
        {message}
        {count > 1 ? ` (${count})` : ''}
      </AlertComponent>
    ),
  )

  return (
    <>
      <AlertsContext.Provider value={addAlerts}>
        {children}
      </AlertsContext.Provider>
      <div className="alerts">{alerts}</div>
    </>
  )
}

const useAlerts = (): ((..._: AlertOptions[]) => void) =>
  useContext(AlertsContext)
export default useAlerts
