import { useCallback } from 'react'

import APIError, { ResponseFormat } from 'api/apiError'
import useAlerts from 'contexts/Alerts'

const defaultRewriteMessage = (msg: string) => msg

export default function useHandleApiError(
  rewriteMessage: (msg: string) => string = defaultRewriteMessage,
): (apiErrorResponse: unknown) => void {
  const addAlerts = useAlerts()
  return useCallback(
    apiErrorResponse => {
      const err = new APIError(apiErrorResponse as ResponseFormat)
      console.error(err)
      addAlerts({
        style: 'danger',
        message: rewriteMessage(err.message),
      })
    },
    [addAlerts, rewriteMessage],
  )
}
