import React from 'react'

import { Button, Col, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import useToggle from 'react-use/lib/useToggle'

import useActions from './actions'
import { assetField, deltaField } from './fields'

const defaultValues = { delta: 0 }

type NewTargetDeltaProps = {
  existingAssets: number[]
}

type FormValues = {
  delta: number
  asset_id: number
}

const NewTargetDelta: React.FC<NewTargetDeltaProps> = ({
  existingAssets = [],
}) => {
  const [expanded, toggleExpanded] = useToggle(false)
  const { create } = useActions()
  const { register, control, handleSubmit } = useForm<FormValues>({
    defaultValues,
  })

  const onSubmit = async (values: FormValues) => {
    const succeeded = await create({
      asset_id: values.asset_id,
      delta: { quantity: values.delta, asset_id: values.asset_id },
    })
    if (succeeded) {
      toggleExpanded()
    }
  }

  return expanded ? (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Row>
        <Form.Group
          as={Col}
          md="auto"
          className="align-self-end"
          controlId="targetDeltaFormAsset"
        >
          <Form.Label>Asset</Form.Label>
          {assetField(control, existingAssets)}
        </Form.Group>
        <Form.Group
          as={Col}
          md="auto"
          className="align-self-end"
          controlId="targetDeltaFormDelta"
        >
          <Form.Label>Delta</Form.Label>
          {deltaField(register)}
        </Form.Group>
        <Form.Group as={Col} md="auto" className="align-self-end">
          <Button
            type="submit"
            variant="success"
            style={{ verticalAlign: 'bottom' }}
          >
            Create
          </Button>
        </Form.Group>
        <Form.Group as={Col} md="auto" className="align-self-end">
          <Button
            variant="outline-secondary"
            onClick={toggleExpanded}
            style={{ verticalAlign: 'bottom' }}
          >
            Cancel
          </Button>
        </Form.Group>
      </Form.Row>
    </Form>
  ) : (
    <Button
      variant="outline-secondary"
      onClick={() => {
        toggleExpanded()
        setTimeout(() => {
          document
            .querySelector<HTMLInputElement>('#targetDeltaFormAsset')
            ?.focus()
        }, 0)
      }}
    >
      New
    </Button>
  )
}

export default React.memo(NewTargetDelta)
