import React, { useContext } from 'react'

import config from 'config'

import { Api } from '../api/FireflyApi'

const ApiContext = React.createContext<Api<null>>(new Api())

export const ApiProvider: React.FC = ({ children }) => {
  const api = new Api<null>({
    // we need to use origin instead of href, as the API client requires no
    // trailing slash -- the URL object automatically appends a trailing slash
    // to href, but removes it from origin
    baseUrl: config.apiUrl.origin,
    baseApiParams: {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      format: 'json',
    },
  })
  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
}

export const useApi = (): Api<null> => useContext(ApiContext)
export default useApi
