import React, { useEffect, useRef } from 'react'

import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import { Container, Nav } from 'react-bootstrap'
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router'
import { Link, useHistory } from 'react-router-dom'

import HotkeysWithModal from 'components/HotKeysWithModal'

export type NavRoute = {
  slug: string
  label: string
  labelSuffix?: React.ReactNode
  component: React.ComponentType
  hotkey:
    | 'a'
    | 'b'
    | 'c'
    | 'd'
    | 'e'
    | 'f'
    | 'g'
    | 'h'
    | 'i'
    | 'j'
    | 'k'
    | 'l'
    | 'm'
    | 'n'
    | 'o'
    | 'p'
    | 'q'
    | 'r'
    | 's'
    | 't'
    | 'u'
    | 'v'
    | 'w'
    | 'x'
    | 'y'
    | 'z'
}

type TabbedNavigationProps = {
  routes: NavRoute[]
}

const TabbedNavigation: React.FC<TabbedNavigationProps> = ({ routes }) => {
  const { path } = useRouteMatch()
  const [defaultPath] = routes
  const history = useHistory()
  const containerRef = useRef<HTMLDivElement>(null)

  const allHotkeys = routes.map(({ hotkey }) => hotkey)
  if (new Set(allHotkeys).size !== allHotkeys.length) {
    throw new Error(`Not all hotkeys are unique`)
  }

  const routesByHandler = keyBy(
    routes,
    ({ label }) => `goTo${label.replace(/\s/g, '')}`,
  )

  const keyMap = mapValues(routesByHandler, ({ hotkey }) => `t ${hotkey}`)
  const handlers = mapValues(
    routesByHandler,
    ({ slug }) =>
      (event: KeyboardEvent) => {
        event.stopPropagation()
        event.preventDefault()
        history.push(`${path}/${slug}`)
      },
  )

  return (
    <HotkeysWithModal
      title="Tabbed Navigation"
      handlers={handlers}
      keyMap={keyMap}
    >
      <Container ref={containerRef} tabIndex={-1}>
        <Nav variant="tabs" style={{ margin: '1rem 0' }}>
          <Route path={`${path}/:activePath`}>
            <NavigationTabs
              path={path}
              routes={routes}
              containerRef={containerRef}
            />
          </Route>
        </Nav>
      </Container>

      <Switch>
        <Route exact path={path}>
          <Redirect to={`${path}/${defaultPath.slug}`} />
        </Route>
        {routes.map(({ slug, component }) => (
          <Route key={slug} path={`${path}/${slug}`} component={component} />
        ))}
      </Switch>
    </HotkeysWithModal>
  )
}

export default TabbedNavigation

type NavigationTabsProps = {
  routes: NavRoute[]
  path: string
  containerRef: React.MutableRefObject<HTMLDivElement | null>
}

const NavigationTabs: React.FC<NavigationTabsProps> = ({
  routes,
  path,
  containerRef,
}) => {
  const { activePath } = useParams<{ activePath: string }>()

  // Ensure tab container element is focused on route change so hotkeys work
  useEffect(() => {
    containerRef.current?.focus()
  }, [activePath, containerRef])

  return (
    <>
      {routes.map(({ slug, label, hotkey, labelSuffix }) => {
        const active = activePath === slug
        const position = label
          .split('')
          .findIndex(letter => letter.toLowerCase() === hotkey)
        let prefix: string, highlight: string, suffix: string
        if (position === -1) {
          // if the hotkey isn't contained in the word, just append ` (<hotkey>)`
          prefix = `${label} (`
          highlight = `${hotkey}`
          suffix = ')'
        } else {
          prefix = label.slice(0, position)
          highlight = label.slice(position, position + 1)
          suffix = label.slice(position + 1)
        }

        return (
          <Nav.Item key={slug}>
            <Link
              to={`${path}/${slug}`}
              className={`nav-link ${active ? 'active' : ''}`}
              style={{
                fontSize: '1.1rem',
                padding: '0.5rem 2rem',
                fontWeight: active ? 'bold' : 'inherit',
              }}
            >
              {prefix}
              <u>{highlight}</u>
              {suffix}
              {labelSuffix && <> {labelSuffix}</>}
            </Link>
          </Nav.Item>
        )
      })}
    </>
  )
}
