import { usePollApi } from 'hooks/usePollApi'

import { CapitalConcentrationLimitUsage } from './types'

export function useCapitalConcentrationLimitsUsage(props?: {
  params?: URLSearchParams
}): CapitalConcentrationLimitUsage[] | undefined {
  return usePollApi<CapitalConcentrationLimitUsage>({
    path: 'capital_concentration_limit_usage',
    params: new URLSearchParams({
      select: '*,user:users(name),exchange:exchanges(name)',
      ...(props?.params && Object.fromEntries(props.params)),
    }),
    addTenantId: true,
  })
}
