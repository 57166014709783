import React from 'react'

import { isEqual } from 'lodash'
import { Link } from 'react-router-dom'

import { assetIdToString, useAsset, useAssets } from 'contexts/Assets'
import { AssetId } from 'types/Asset'

export const ASSET_COLORS: Record<string, React.CSSProperties> = {
  USDT: { backgroundColor: '#aadb65' },
}

type AssetProps = {
  asset: AssetId
  link?: boolean
  colored?: boolean
}

const Asset: React.FC<AssetProps> = ({
  asset: assetLike,
  link = true,
  colored = false,
}) => {
  const asset = useAsset(assetLike)

  if (!asset) {
    // This usually shouldn't happen, because most pages don't load until we've
    // finished loading all assets.
    return <>[Asset {assetIdToString(assetLike)}]</>
  }
  const wrappedAsset =
    colored && ASSET_COLORS[asset.ticker] ? (
      <span
        style={{
          padding: '0 2px',
          color: '#000',
          ...ASSET_COLORS[asset.ticker],
        }}
      >
        {asset.ticker}
      </span>
    ) : (
      <>{asset.ticker}</>
    )
  if (link) {
    /* When assetLike specifies a ticker, we could return this even if !asset,
       but as discussed above we don't expect !asset to often happen, so I
       haven't bothered. */
    return <Link to={`/assets/ticker/${asset.ticker}`}>{wrappedAsset}</Link>
  } else {
    return wrappedAsset
  }
}

export default React.memo(Asset, isEqual)

type AssetOptionsInnerProps = {
  spotOnly?: boolean
  assets?: AssetId[]
  exclude?: number[]
  field?: 'id' | 'ticker'
}

// TODO: Replace dropdowns with autocompletes
const AssetOptionsInner: React.FC<AssetOptionsInnerProps> & {
  whyDidYouRender: boolean
} = ({ assets: assetLikes, spotOnly = false, exclude = [], field = 'id' }) => {
  let assets = useAssets(assetLikes)
  if (spotOnly) {
    assets = assets.filter(a => a.asset_class === 'crypto')
  }
  if (exclude.length > 0) {
    assets = assets.filter(a => !exclude.includes(a.id))
  }
  const assetOptions = assets
    .sort((a, b) => a.ticker.localeCompare(b.ticker))
    .map(asset => (
      <option key={asset.ticker} value={asset[field]}>
        {asset.ticker}
      </option>
    ))

  return <React.Fragment>{assetOptions}</React.Fragment>
}

AssetOptionsInner.whyDidYouRender = true

export const AssetOptions = React.memo(AssetOptionsInner, isEqual)
