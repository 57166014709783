import React, { useCallback, useMemo, useState } from 'react'

import PropTypes from 'prop-types'

import {
  CurrencyOption,
  CurrencySelectionContext,
  defaultOptions,
} from 'contexts/CurrencySelection'
import { SelectedCurrencyContext } from 'contexts/SelectedCurrency'

type CurrencySelectionManagerState = {
  currencyOptions: CurrencyOption[]
  selectedCurrency: CurrencyOption
}

const initialState: CurrencySelectionManagerState = {
  currencyOptions: defaultOptions,
  selectedCurrency: 'Native',
}

const CurrencySelectionManager: React.FC = ({ children }) => {
  const [state, setState] = useState(initialState)

  const setOptions = useCallback(currencyOptions => {
    setState(old => ({
      ...old,
      currencyOptions,
      selectedCurrency: currencyOptions[0],
    }))
  }, [])

  const updateCurrency = useCallback(
    selectedCurrency => setState(old => ({ ...old, selectedCurrency })),
    [],
  )

  const currencySelection = useMemo(
    () => ({
      currencyOptions: state.currencyOptions,
      setOptions,
      updateCurrency,
    }),
    [state.currencyOptions, setOptions, updateCurrency],
  )

  return (
    <SelectedCurrencyContext.Provider value={state.selectedCurrency}>
      <CurrencySelectionContext.Provider value={currencySelection}>
        {children}
      </CurrencySelectionContext.Provider>
    </SelectedCurrencyContext.Provider>
  )
}

export default React.memo(CurrencySelectionManager)

CurrencySelectionManager.propTypes = {
  children: PropTypes.node,
}
