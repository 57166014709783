import { useAsset } from 'contexts/Assets'
import { useFairs } from 'contexts/Fairs'
import { AssetId } from 'types/Asset'

export default function useConversion(
  fromAssetId: AssetId | undefined,
  toAssetId: AssetId | undefined,
): number {
  const fromAsset = useAsset(fromAssetId)
  const toAsset = useAsset(toAssetId)

  const assets = []
  let fromId: number | undefined
  let toId: number | undefined

  if (fromAsset) {
    fromId = fromAsset.contract_size
      ? fromAsset.contract_size.asset_id
      : fromAsset.id
    assets.push(fromId)
  }
  if (toAsset) {
    toId = toAsset.contract_size ? toAsset.contract_size.asset_id : toAsset.id
    assets.push(toId)
  }

  const fairs = useFairs(assets)

  // This is ugly, but rules of hooks mean we can't call useFairs
  // conditionally, so we have to wait until here to bail out.
  if (!fromId || !toId || !fromAsset || !toAsset) {
    return NaN
  }

  const fromMultiplier = fromAsset.contract_size
    ? fromAsset.contract_size.quantity
    : 1
  const toMultiplier = toAsset.contract_size
    ? toAsset.contract_size.quantity
    : 1

  if (fromId === toId) {
    return fromMultiplier / toMultiplier
  }

  return (fairs[fromId] * fromMultiplier) / (fairs[toId] * toMultiplier)
}
