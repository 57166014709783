import React, { useEffect, useMemo, useRef, useState } from 'react'

import { Button, Dropdown, Form, InputGroup } from 'react-bootstrap'
import { usePrevious } from 'react-use'
import styled from 'styled-components'

import { useForwardedRef } from 'hooks/useForwardedRef'
import { KeyBy } from 'types/utils'

import { AutocompleteItem, AutocompletePropsBase } from './types'

export const DEFAULT_MAX_ITEMS = 10

const DownArrow = styled.span`
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
`

const UpArrow = styled(DownArrow)`
  border-bottom: 0.3em solid;
  border-top: 0;
`

const SearchInput = styled.input`
  // Hide the default field styling (e.g. clear button) for an input[type='search'] field
  // See https://stackoverflow.com/questions/9421551/how-do-i-remove-all-default-webkit-search-field-styling
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
`

function isNumberOrString(val: unknown): val is string | number {
  return ['string', 'number'].includes(typeof val)
}

/** By default, just filter items with a simple substring match */
function defaultFilterItems<T extends AutocompleteItem>(
  // The KeyBy<T, string> part is technically redundant but unfortunately
  // the typechecker can't see it
  searchKey: KeyBy<T, string> | KeyBy<T, string | number>,
) {
  return (inputValue: string, items: T[]): T[] => {
    return items.filter(item => {
      const val = item[searchKey]
      if (isNumberOrString(val)) {
        return val.toString().toLowerCase().includes(inputValue.toLowerCase())
      } else {
        throw new Error(
          `Cannot filter, ${String(searchKey)} is not a string or number key`,
        )
      }
    })
  }
}

type AutocompleteBaseProps<T extends AutocompleteItem> =
  AutocompletePropsBase<T> & { onSelect: (item: T | null) => void } & (
      | {
          isMultiple: false
          selectedItem: T | null
          selectedItems?: never
        }
      | {
          isMultiple: true
          selectedItem?: never
          selectedItems: T[]
        }
    )

export function AutocompleteBase<T extends AutocompleteItem>({
  items,
  searchKey,
  filterItems,
  formatItem,
  keyBy,
  inputProps,
  style,
  label,
  maxItems = DEFAULT_MAX_ITEMS,
  onSelect,
  dropdown = false,
  isMultiple = false,
  selectedItem,
  selectedItems,
}: AutocompleteBaseProps<T>): JSX.Element {
  const inputRef = useForwardedRef(inputProps?.ref)
  const [inputValue, setInputValue] = useState<string>('')
  const [isOpen, setIsOpen] = useState(false)
  const [highlightedIndex, setHighlightedIndex] = useState(0)
  const dropDownRef = useRef<HTMLDivElement>(null)
  const selectedItemId =
    !isMultiple && selectedItem ? selectedItem[keyBy] : null
  const prevSelectedItemId = usePrevious(selectedItemId)

  const filterItemsInUse = filterItems ?? defaultFilterItems(searchKey ?? keyBy)
  const inputItems = useMemo(
    () => filterItemsInUse(inputValue, items).slice(0, maxItems),
    [filterItemsInUse, inputValue, items, maxItems],
  )

  function handleSelectItem(index: number) {
    const item = inputItems[index]
    if (!isMultiple) {
      setInputValue(formatItem(item))
    } else {
      setInputValue('')
    }
    onSelect(item)
  }

  function handleChangeHighlightedIndex(direction: 'up' | 'down') {
    let newIndex =
      direction === 'down' ? highlightedIndex + 1 : highlightedIndex - 1

    if (direction === 'up' && newIndex < 0) {
      newIndex = inputItems.length - 1
    } else if (direction === 'down' && newIndex >= inputItems.length) {
      newIndex = 0
    }
    setHighlightedIndex(newIndex)
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value)
    setIsOpen(event.target.value !== '')
    inputProps?.onInput?.(event)
  }

  /** Custom key event handler for special keys */
  function handleInputKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    const { key } = event
    switch (key) {
      case 'Escape':
        if (!isOpen) {
          inputRef.current?.blur()
        } else {
          setIsOpen(false)
        }
        break
      case 'Tab': {
        if (isOpen) {
          handleSelectItem(highlightedIndex)
        }
        setIsOpen(false)
        break
      }
      case 'Enter': {
        event.preventDefault()
        if (isOpen) {
          // Update the selected item to the currently-highlighted index
          handleSelectItem(highlightedIndex)
          setIsOpen(false)
        } else if (!isMultiple && selectedItem) {
          // if we press enter when we've already got a selection, and we're not
          // trying to select a new item, just re-run the onSelect
          onSelect(selectedItem)
        }
        break
      }
      case 'ArrowDown':
        event.preventDefault()
        if (!isOpen) {
          setIsOpen(true)
          setHighlightedIndex(0)
        } else {
          handleChangeHighlightedIndex('down')
        }
        break
      case 'ArrowUp':
        event.preventDefault()
        if (!isOpen) {
          setIsOpen(true)
          setHighlightedIndex(inputItems.length - 1)
        } else {
          handleChangeHighlightedIndex('up')
        }
        break
      default: {
        inputProps?.onKeyDown?.(event)
      }
    }
  }

  function handleItemClick(index: number) {
    setIsOpen(false)
    setTimeout(() => {
      handleSelectItem(index)
    }, 1)
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    inputProps?.onBlur?.(event)
    // If we blur but didn't clear our selection, restore the field text to
    // the formatted version of the currently-selected item
    if (!isMultiple && !isOpen && selectedItem) {
      // the timeout means that if the previous event does something dumb, like overwriting the input value, that we'll run this last, and keep it set
      setTimeout(() => setInputValue(formatItem(selectedItem)), 10)
    }
  }

  function handleClear() {
    setInputValue('')
    onSelect(null)
    focusInput()
  }

  function focusInput() {
    inputRef.current?.focus()
  }

  // Reset highlighted index on open/close
  useEffect(() => {
    if (!isOpen) {
      setHighlightedIndex(0)
    }
  }, [isOpen])

  // Set field value if selected item changes (and there's something selected),
  // so that if selectedItem gets set by some means other than by typing in the
  // input (e.g. a default value etc) it'll be reflected correctly
  useEffect(() => {
    if (selectedItemId !== prevSelectedItemId && selectedItem) {
      setInputValue(formatItem(selectedItem))
    }
  }, [formatItem, inputValue, selectedItemId, prevSelectedItemId, selectedItem])

  useEffect(() => {
    if (isOpen) {
      requestAnimationFrame(() => {
        const selectedEl =
          dropDownRef.current?.querySelector<HTMLDivElement>('.selected')
        if (selectedEl && dropDownRef.current) {
          const topPos = selectedEl.offsetTop
          const height = selectedEl.clientHeight
          const dropDownHeight = dropDownRef.current.clientHeight
          const upperBound = dropDownRef.current.scrollTop
          const lowerBound = upperBound + dropDownHeight
          if (topPos < upperBound) {
            dropDownRef.current.scrollTop = topPos
          } else if (topPos + height > lowerBound) {
            dropDownRef.current.scrollTop = topPos - dropDownHeight + height
          }
        }
      })
    }
  }, [highlightedIndex, isOpen])

  // Focus component on mount if inputProps.autoFocus is set to true
  useEffect(() => {
    if (inputProps?.autoFocus) {
      focusInput()
      // If there's already text in the field (e.g. because a default has been set),
      // select it so that the user can easily start typing without having to
      // delete the text
      setTimeout(() => {
        inputRef.current?.select()
      }, 50)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function isSelected(item: T) {
    if (item === selectedItem) return true
    if (selectedItems) {
      return selectedItems.some(selectedItem => selectedItem === item)
    }
    return false
  }

  return (
    <div style={style}>
      {label && <Form.Label style={{ width: '100%' }}>{label}</Form.Label>}
      <div>
        <InputGroup style={{ flexWrap: 'initial' }}>
          <SearchInput
            autoComplete="off"
            // Without setting type to `search`, 1Password (and possibly other
            // password managers) will decorate the input with the option to
            // fill a password. It seems semantically appropriate anyway, so we
            // set it...
            type="search"
            {...inputProps}
            // For some reason wrapping the control in StyledComponents forces
            // us to set the `as` prop. It doesn't appear to do any harm.
            as="input"
            className={['form-control', inputProps?.className ?? ''].join(' ')}
            onKeyDown={handleInputKeyDown}
            onChange={handleInputChange}
            onBlur={handleBlur}
            value={inputValue}
            ref={inputRef}
            style={{
              paddingRight: selectedItem ? 0 : undefined,
              ...inputProps?.style,
            }}
          />
          {selectedItem && (
            <InputGroup.Append>
              <Button variant="secondary" onClick={handleClear} tabIndex={-1}>
                &times;
              </Button>
            </InputGroup.Append>
          )}
          {dropdown && (
            <InputGroup.Append>
              {!isOpen ? (
                <Button
                  variant="secondary"
                  onClick={() => setIsOpen(true)}
                  tabIndex={-1}
                >
                  <DownArrow />
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  onClick={() => setIsOpen(false)}
                  tabIndex={-1}
                >
                  <UpArrow />
                </Button>
              )}
            </InputGroup.Append>
          )}
        </InputGroup>
      </div>
      <Dropdown show={isOpen}>
        <Dropdown.Menu
          style={{
            maxHeight: '40vh',
            overflowY: 'auto',
            overflowX: 'hidden',
            width: '100%',
          }}
          ref={dropDownRef}
        >
          {isOpen && inputItems.length ? (
            inputItems.map((item, index) => (
              <Dropdown.Item
                tabIndex={-1}
                key={item[keyBy] as string | number}
                style={{
                  backgroundColor:
                    highlightedIndex === index ? 'lightgray' : 'white',
                  fontWeight: isSelected(item) ? 'bold' : 'normal',
                }}
                className={highlightedIndex === index ? 'selected' : undefined}
                onClick={() => handleItemClick(index)}
              >
                {formatItem(item)}
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.Item style={{ color: 'darkgray' }}>
              <em>No results</em>
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default AutocompleteBase
