import { useCallback } from 'react'

import Big from 'big.js'

import useApi from 'contexts/Api'
import { TenantId } from 'contexts/Tenants'
import useHandleApiError from 'hooks/useHandleApiError'

import { DeltaLimitRow, DeltaLimitUsageRow, isDeltaLimitUsage } from './types'

type Row = DeltaLimitRow | DeltaLimitUsageRow

export type FormValues = {
  delta_limit_lower: string
  delta_limit_upper: string
  note: string
}

type Response = Promise<{ succeeded: boolean }>

type UseActionsMethods = {
  create: (
    values: FormValues,
    tenantId: TenantId,
    assetId: number | null,
  ) => Response
  upsert: (values: FormValues, row: Row) => Response
  destroy: (row: Row) => Response
}

const rewriteApiError = (msg: string) => {
  if (/delta_limit_well_ordered_check/.test(msg)) {
    return 'Error: upper limit must be greater than lower limit'
  }
  if (/forbid_duplicate_all_assets/.test(msg)) {
    return 'Error: a limit already exists that applies to all assets'
  }
  if (/violates unique constraint/.test(msg)) {
    return 'Error: a limit already exists for this asset'
  }
  return msg
}

function bpsToDecimal(bps: number) {
  return Big(bps).div(1e4).toNumber()
}

export function useActions(): UseActionsMethods {
  const api = useApi()
  const handleApiError = useHandleApiError(rewriteApiError)

  const create: UseActionsMethods['create'] = useCallback(
    async (values, tenantId, assetId) => {
      try {
        await api.deltaLimits.deltaLimitsCreate({
          tenant_id: tenantId,
          // the API type doesn't explicitly allow passing null, but not passing
          // the argument has the same effect
          asset_id: assetId ?? undefined,
          delta_limit_lower: bpsToDecimal(+values.delta_limit_lower),
          delta_limit_upper: bpsToDecimal(+values.delta_limit_upper),
          note: values.note === '' ? undefined : values.note,
        })
        return { succeeded: true }
      } catch (err) {
        handleApiError(err)
        return { succeeded: false }
      }
    },
    [api.deltaLimits, handleApiError],
  )

  const upsert: UseActionsMethods['upsert'] = useCallback(
    async (values, row) => {
      try {
        if (
          row.delta_limit_lower === null ||
          (isDeltaLimitUsage(row) && row.delta_limit_asset_id === '*')
        ) {
          await create(values, row.tenant_id, row.asset_id)
        } else {
          await api.deltaLimits.deltaLimitsPartialUpdate(
            {
              delta_limit_lower: bpsToDecimal(+values.delta_limit_lower),
              delta_limit_upper: bpsToDecimal(+values.delta_limit_upper),
              // The API typings don't allow passing null here, but the API will
              // accept it fine. We need to explicitly pass null, because that means
              // 'set the value to null', whereas undefined means 'don't change this
              // value'.
              note: (values.note === '' ? null : values.note) as unknown as
                | string
                | undefined,
            },
            {
              tenant_id: `eq.${row.tenant_id}`,
              asset_id: row.asset_id ? `eq.${row.asset_id}` : 'is.null',
            },
          )
        }
        return { succeeded: true }
      } catch (err) {
        handleApiError(err)
        return { succeeded: false }
      }
    },
    [api.deltaLimits, create, handleApiError],
  )

  const destroy: UseActionsMethods['destroy'] = useCallback(
    async row => {
      try {
        await api.deltaLimits.deltaLimitsDelete({
          tenant_id: `eq.${row.tenant_id}`,
          asset_id: row.asset_id ? `eq.${row.asset_id}` : `is.null`,
        })
        return { succeeded: true }
      } catch (err) {
        handleApiError(err)
        return { succeeded: false }
      }
    },
    [api.deltaLimits, handleApiError],
  )

  return { create, upsert: upsert, destroy }
}
