import React, { useState } from 'react'

import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'

import { Glyphicon } from 'components/BootstrapShim'

import { LimitsDashboardHook, RowStatus, maxRowStatus } from '../types'

import { CapitalConcentrationLimitBreaches } from './Breaches'
import { NewCCL } from './New'
import { CapitalConcentrationLimitsTable } from './Table'
import { useCapitalConcentrationLimitsUsage } from './query'
import { prepareCapitalConcentrationLimitsRow, rowStatus } from './rows'

const DEFAULT_MIN_CONCENTRATION = 0.0001

export const CapitalConcentrationLimits: React.FC = () => {
  const [minProportion, setMinProportion] = useState(DEFAULT_MIN_CONCENTRATION)
  const concentrationUsage = useCapitalConcentrationLimitsUsage({
    params: new URLSearchParams({
      concentration_proportion: `gte.${minProportion}`,
    }),
  })

  if (!concentrationUsage) {
    return <>Loading...</>
  }
  const rows = concentrationUsage.map(prepareCapitalConcentrationLimitsRow)

  return (
    <Container>
      <CapitalConcentrationLimitBreaches rows={rows} />
      <Row>
        <Col md="auto" className="ml-auto">
          <Form.Group style={{ display: 'flex', alignItems: 'center' }}>
            <Form.Label
              style={{
                marginBottom: 0,
                marginLeft: '0.5rem',
                whiteSpace: 'nowrap',
              }}
            >
              Min proportion:{' '}
            </Form.Label>
            <InputGroup>
              <Form.Control
                type="number"
                step="0.0001"
                min={0}
                style={{ width: '7rem' }}
                value={minProportion}
                onChange={e => setMinProportion(+e.target.value)}
              />
              <InputGroup.Append>
                <Button
                  variant="secondary"
                  onClick={() => setMinProportion(DEFAULT_MIN_CONCENTRATION)}
                  disabled={minProportion === DEFAULT_MIN_CONCENTRATION}
                >
                  <Glyphicon glyph="refresh" />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <CapitalConcentrationLimitsTable rows={rows} />
      <NewCCL />
    </Container>
  )
}

const DashboardStatuses: RowStatus[] = [
  'urgent-breach',
  'breach',
  'near-breach',
]

export const useCapitalConcentrationLimitsDashboard: LimitsDashboardHook =
  () => {
    const concentrationUsage = useCapitalConcentrationLimitsUsage({
      params: new URLSearchParams({
        concentration_proportion: `gte.${DEFAULT_MIN_CONCENTRATION}`,
      }),
    })

    if (!concentrationUsage) {
      return { loaded: false }
    }

    const rows = concentrationUsage
      .map(prepareCapitalConcentrationLimitsRow)
      .filter(row => DashboardStatuses.includes(rowStatus(row)))
    const Component = rows.length ? (
      <CapitalConcentrationLimitsTable rows={rows} dashboard />
    ) : undefined

    const status = maxRowStatus(rows, rowStatus)

    return {
      loaded: true,
      Component,
      status,
    }
  }
