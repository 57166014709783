import React, { useContext, useEffect } from 'react'

import useSessionStorage from 'react-use/lib/useSessionStorage'

import useSelectedTenant, { SelectedTenantContext } from './SelectedTenant'
import {
  TENANT_IDS,
  Tenant,
  TenantId,
  TenantsContextProvider,
  useTenants,
} from './Tenants'

type SelectionCallback = (tenants: Tenant[]) => TenantId
type TenantSelection = TenantId | SelectionCallback | null

type SetTenant = (selection: TenantSelection) => void

interface Children {
  children: JSX.Element
}

const TenantSelectionContext = React.createContext<SetTenant>(() => {})

const tenantColors: Record<TenantId, string> = {
  1: '#ddd',
  2: '#FFD65A',
  3: '#A07BFF',
  4: '#8AC7FF',
  5: '#0716DE',
  6: '#E6880C',
}

export function getTenantColor(tenantId: TenantId): string {
  return tenantColors[tenantId]
}

const TenantSelectionProvider = ({ children }: Children): JSX.Element => {
  const tenants = useTenants()

  const initialTenant = useSelectedTenant()

  const lookupTenant = (tenantId: TenantId): Tenant | undefined =>
    tenants.find(t => t.id === tenantId)

  const [selectedTenant, setSelectedTenant] = useSessionStorage<Tenant>(
    'tenant',
    initialTenant,
  )

  useEffect(() => {
    const htmlStyle = document.documentElement.style
    const property = '--body-border'
    const previous = htmlStyle.getPropertyValue(property)
    const colorId =
      selectedTenant.id > Math.max(...TENANT_IDS) ? 1 : selectedTenant.id
    htmlStyle.setProperty(property, getTenantColor(colorId))
    return (): void => htmlStyle.setProperty(property, previous)
  }, [selectedTenant])

  const setTenant = (selection: TenantSelection): void => {
    if (selection === null) {
      return
    }
    const selectedId =
      typeof selection === 'number' ? selection : selection(tenants)
    const newTenant = lookupTenant(selectedId)
    newTenant && setSelectedTenant(newTenant)
  }

  return (
    <TenantSelectionContext.Provider value={setTenant}>
      <SelectedTenantContext.Provider value={selectedTenant}>
        {children}
      </SelectedTenantContext.Provider>
    </TenantSelectionContext.Provider>
  )
}

export const TenantProvider = ({ children }: Children): JSX.Element => (
  <TenantsContextProvider>
    <TenantSelectionProvider>{children}</TenantSelectionProvider>
  </TenantsContextProvider>
)

const useTenantSelection = (): SetTenant => useContext(TenantSelectionContext)

export default useTenantSelection
