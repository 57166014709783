import React from 'react'

import Big from 'big.js'
import keyBy from 'lodash/keyBy'
import { Form } from 'react-bootstrap'
import { UseFormMethods } from 'react-hook-form'

import Asset from 'components/Asset'
import {
  EditRowButtons,
  FlexButtonWrapper,
} from 'components/EditableTableFields'
import Quantity, { localeFormatter } from 'components/Quantity'
import { ExtendedColumnDescription } from 'components/StyledBoostrapTable'
import { getTenantColumn } from 'components/StyledBoostrapTable/columns'
import { CurrencyOption } from 'contexts/CurrencySelection'
import { useIsGlobalTenant } from 'contexts/SelectedTenant'
import { Quantity as QuantityType } from 'types/Quantity'
import { isExistent } from 'utils/guards'

import { rowStatus } from './rows'
import {
  DeltaLimitRow,
  DeltaLimitUsageRow,
  EditableLimitRow,
  ViewMode,
  isDeltaLimitUsage,
} from './types'

const tenantColumn = getTenantColumn<EditableLimitRow>()

const deltaPrecisions: { [k in CurrencyOption]?: number } = {
  BTC: 4,
  USD: 2,
  ETH: 3,
  USDT: 2,
}

const usageRowQuantityFormatter = (quantity: number, row: EditableLimitRow) =>
  Number.isFinite(quantity) && isDeltaLimitUsage(row) ? (
    <Quantity
      currency={row.asset_id}
      quantity={quantity}
      precision={3}
      convertedPrecision={deltaPrecisions}
    />
  ) : (
    ''
  )

const decimalFormatter = (precision: number) => (decimal: number | null) =>
  isExistent(decimal) ? decimal.toFixed(precision) : ''

const decimalBpsFormatter = (decimal: number) =>
  isExistent(decimal) ? (decimal * 1e4).toFixed(1) : ''

const limitFormatter =
  (
    name: 'delta_limit_lower' | 'delta_limit_upper',
    register: UseFormMethods['register'],
  ) =>
  (limit: number | null, row: EditableLimitRow) => {
    const limitBps = limit && Big(limit).times(1e4).toNumber()
    if (row.isEditing) {
      return (
        <Form.Control
          name={name}
          defaultValue={limitBps ?? undefined}
          type="number"
          required
          ref={register}
          step="any"
        />
      )
    }
    return limitBps
  }

const limitStyles = (_: unknown, row: EditableLimitRow): React.CSSProperties =>
  isDeltaLimitUsage(row) && row.delta_limit_asset_id === '*'
    ? { fontStyle: 'italic' }
    : {}

function handleNotesInput(event: React.KeyboardEvent<HTMLTextAreaElement>) {
  const key = event.key
  // Pressing enter submits the form, but users can use Shift+Enter to add
  // linebreaks if so desired
  if (key === 'Enter' && !event.shiftKey) {
    event.preventDefault()
    event.currentTarget.closest<HTMLFormElement>('form')?.requestSubmit()
  }
}

// Select notes text on focus
function handleNotesFocus(event: React.FocusEvent<HTMLTextAreaElement>) {
  event.target.select()
}

const notesFormatter =
  (register: UseFormMethods['register']) =>
  (note: string | null, row: EditableLimitRow) => {
    if (row.isEditing) {
      return (
        <Form.Control
          as="textarea"
          name="note"
          ref={register}
          defaultValue={note ?? undefined}
          rows={3}
          placeholder="Note"
          onFocus={handleNotesFocus}
          onKeyDown={handleNotesInput}
        />
      )
    }
    return note
  }

const mutedStyles: React.CSSProperties = {
  color: 'darkgray',
}

type UseColumnsProps = {
  viewMode: ViewMode
  register: UseFormMethods['register']
  handleDestroy: (row: DeltaLimitRow | DeltaLimitUsageRow) => Promise<void>
}

type DataFields =
  | keyof DeltaLimitRow
  | keyof DeltaLimitUsageRow
  | 'buttons'
  | 'asset_id_2'

export const useColumns = ({
  viewMode,
  register,
  handleDestroy,
}: UseColumnsProps): ExtendedColumnDescription<EditableLimitRow>[] => {
  const isGlobalTenant = useIsGlobalTenant()

  const limitHeaderStyles = {
    width: viewMode === 'edit' ? 100 : 75,
  }

  const ratioHeaderStyles = { width: 75 }

  const baseColumns: ExtendedColumnDescription<EditableLimitRow>[] = [
    tenantColumn,
    {
      dataField: 'asset_id',
      text: 'Asset',
      // We deliberately make this a dummy field because we'll copy it over to a
      // new column with datafield `asset_id_2`, which means we can't rely on
      // the first argument to the formatter being the value of `row.asset_id`.
      isDummyField: true,
      formatter: (_: unknown, row: EditableLimitRow) =>
        row.asset_id === null ? (
          'all assets'
        ) : (
          <>
            <Asset asset={row.asset_id} />
            {isDeltaLimitUsage(row) && row.delta_limit_asset_id === '*' && (
              <span style={{ color: 'initial' }}>*</span>
            )}
          </>
        ),
      sort: true,
      // Strings are sorted by Unicode code points, so this is roughly
      // equivalent to the use of 'Infinity' when doing numeric sort comparisons
      sortValue: (_, row) =>
        isDeltaLimitUsage(row) ? row.asset : (row.asset?.ticker ?? '\uFFFF'),
      headerStyle: { width: 70 },
    },
    {
      dataField: 'delta',
      text: 'Delta',
      formatter: usageRowQuantityFormatter,
      headerAlign: 'right',
      align: 'right',
      sort: true,
      sortValue: (_, row) =>
        isDeltaLimitUsage(row) ? Math.abs(row.delta_btc) : -Infinity,
    },
    {
      dataField: 'delta_to_target',
      text: 'DeltaT',
      formatter: usageRowQuantityFormatter,
      headerAlign: 'right',
      align: 'right',
    },
    {
      dataField: 'target_delta',
      text: 'Target Delta',
      formatter: (targetDelta: QuantityType | undefined) =>
        targetDelta?.quantity ? (
          <Quantity
            quantity={targetDelta.quantity}
            currency={targetDelta.asset_id}
            nativeFormatter={localeFormatter}
            precision={3}
            convertedPrecision={deltaPrecisions}
          />
        ) : (
          <></>
        ),
      headerAlign: 'right',
      align: 'right',
      style: mutedStyles,
    },
    {
      dataField: 'delta_limit_lower',
      text: 'Delta Limit (lower)',
      shortText: 'DLL*',
      headerAlign: 'right',
      align: 'right',
      formatter: limitFormatter('delta_limit_lower', register),
      sort: true,
      headerStyle: limitHeaderStyles,
      style: limitStyles,
    },
    {
      dataField: 'delta_limit_midpoint',
      text: 'Delta Limit (midpoint)',
      shortText: 'DLM*',
      headerAlign: 'right',
      align: 'right',
      formatter: limitFormatter('delta_limit_lower', register),
      sort: true,
      headerStyle: limitHeaderStyles,
      style: limitStyles,
    },
    {
      dataField: 'delta_limit_upper',
      text: 'Delta Limit (upper)',
      shortText: 'DLU*',
      headerAlign: 'right',
      align: 'right',
      formatter: limitFormatter('delta_limit_upper', register),
      sort: true,
      headerStyle: limitHeaderStyles,
      style: limitStyles,
    },

    {
      dataField: 'delta_ratio',
      text: 'Delta Ratio',
      shortText: 'DR',
      headerAlign: 'right',
      align: 'right',
      sort: true,
      sortValue: (_, row) => {
        if (isDeltaLimitUsage(row)) {
          return row.delta_ratio !== null ? Math.abs(row.delta_ratio) : Infinity
        }
        return 0
      },

      formatter: decimalBpsFormatter,
      style: { ...limitStyles, ...mutedStyles },
      headerStyle: ratioHeaderStyles,
    },
    {
      dataField: 'delta_to_target_ratio',
      text: 'Delta Ratio (w/Target)',
      shortText: 'DR*',
      headerAlign: 'right',
      align: 'right',
      sort: true,
      sortValue: (_, row) => {
        if (isDeltaLimitUsage(row)) {
          return row.delta_to_target_ratio !== null
            ? Math.abs(row.delta_to_target_ratio)
            : Infinity
        }
        return 0
      },

      formatter: decimalBpsFormatter,
      style: limitStyles,
      headerStyle: ratioHeaderStyles,
    },
    {
      dataField: 'delta_limit_proportion',
      text: 'Delta Limit Proportion',
      shortText: 'DLP',
      headerAlign: 'right',
      align: 'right',
      sort: true,
      sortValue: val => {
        if (val === null) {
          return -Infinity
        }
        return Math.abs(val)
      },
      formatter: decimalFormatter(3),
      style: (cell, row) => ({ fontWeight: 'bold', ...limitStyles(cell, row) }),
      headerStyle: ratioHeaderStyles,
    },
    {
      dataField: 'delta_from_closest_limit',
      text: 'Delta From Closest Limit',
      shortText: 'DFCL',
      align: 'right',
      sort: true,
      sortValue: val => {
        if (val === null) {
          return -Infinity
        }
        return Math.abs(val)
      },
      formatter: usageRowQuantityFormatter,
      style: (_, row) => {
        const status = rowStatus(row)
        if (status !== 'urgent-breach') {
          return {
            color: 'green',
          }
        }
        return {}
      },
    },
    {
      dataField: 'delta_from_limit_midpoint',
      text: 'Delta From Limit Midpoint',
      shortText: 'DFLM',
      headerAlign: 'right',
      align: 'right',
      sort: true,
      sortValue: val => {
        if (val === null) {
          return -Infinity
        }
        return Math.abs(val)
      },
      formatter: usageRowQuantityFormatter,
      style: (_, row) => {
        const status = rowStatus(row)
        if (status !== 'urgent-breach') {
          return {
            color: 'green',
          }
        }
        return {}
      },
    },
    {
      dataField: 'note',
      text: 'Note',
      formatter: notesFormatter(register),
    },
    {
      dataField: 'updated_by_name',
      text: 'Updated by',
    },
    {
      dataField: 'buttons',
      text: '',
      isDummyField: true,
      formatter: (_, row) => {
        return (
          <FlexButtonWrapper>
            <EditRowButtons
              size="sm"
              isEditing={row.isEditing}
              onSetEditing={row.setEditing}
              onDestroy={() => handleDestroy(row)}
              canDestroy={
                !isDeltaLimitUsage(row) ||
                (row.delta_limit_asset_id !== null &&
                  row.delta_limit_asset_id !== '*')
              }
            />
          </FlexButtonWrapper>
        )
      },
      headerStyle: {
        width: 75,
      },
      style: { padding: 0, height: 0 },
    },
  ]

  const baseColumnsKeyed: {
    [x in DataFields]?: ExtendedColumnDescription<EditableLimitRow>
  } = keyBy(baseColumns, 'dataField')
  // We want to duplicate the asset_id column, but if do so just by adding a
  // second instance, we get lots of console errors because the dataField is
  // also used to key the column. So, we create a copy of the field, and give it
  // a different dataField. This is a bit jank, but it keeps React happy.
  const assetCol = baseColumnsKeyed.asset_id
  if (assetCol) {
    const assetId2: ExtendedColumnDescription<EditableLimitRow> = {
      ...assetCol,
      dataField: 'asset_id_2',
    }
    baseColumnsKeyed.asset_id_2 = assetId2
  }

  const columns: DataFields[] = []
  if (viewMode === 'view') {
    columns.push(
      'asset_id',
      'delta',
      'target_delta',
      'delta_from_limit_midpoint',
      'delta_limit_lower',
      'delta_limit_upper',
      'delta_to_target_ratio',
      'delta_ratio',
      'delta_from_closest_limit',
      'asset_id_2',
    )
  } else {
    columns.push(
      'asset_id',
      'delta',
      'delta_limit_lower',
      'delta_limit_upper',
      'delta_to_target_ratio',
      'delta_ratio',
      'delta_limit_proportion',
      'note',
      'updated_by_name',
      'buttons',
    )
  }

  if (isGlobalTenant) {
    columns.unshift('tenant_id')
  }

  return columns.map(col => baseColumnsKeyed[col]).filter(isExistent)
}
