export type Config = {
  version: string
  apiUrl: URL
  wstUrl: URL
  reportToSentry: boolean
  launchDarklyID: string | undefined
}

// Used for local builds that talk to a local API
// Note we still talk to production WST. This is a bit odd but it's usually what
// we want.
const devLocal: Config = {
  version: import.meta.env.VITE_VERSION ?? '',
  apiUrl: new URL('http://localhost:3002'),
  wstUrl: new URL('wss://wst.firefly.lanternventures.com'),
  reportToSentry: false,
  launchDarklyID: undefined,
}

// Should match the value of TAILSCALE_HOSTNAME provided to your local instance
// of `firefly_api`'s Tailscale-authenticated PostgREST gateway. In most cases
// this should be something like `test-api-<USER>`, e.g. `test-api-sam`.
const tsHostname = import.meta.env.VITE_TAILSCALE_HOSTNAME

const devTailscale: Config = {
  ...devLocal,
  // localhost:9 is sort of the equivalent of /dev/null. We include something
  // here so that we don't get type or value errors if we're not running in this
  // mode, and don't specify the required env var
  // https://stackoverflow.com/a/45757321/7114675
  apiUrl: new URL(
    tsHostname ? `https://${tsHostname}.tail6f62.ts.net` : 'http://localhost:9',
  ),
}

export const DEFAULT_PRODUCTION_API_URL = new URL(
  'https://api1.tail6f62.ts.net',
)

// Used for local builds that talk to the production API
const dev: Config = {
  ...devLocal,
  apiUrl: DEFAULT_PRODUCTION_API_URL,
  // LaunchDarkly is meant to control access to the database, so you should use
  // this whenever you use an apiUrl that connects to the production database
  launchDarklyID: '5e4e4f228eaa7c0870969c44',
}
// Used for dev.firefly.lanternventures.com and AWS Amplify previews
const staging: Config = {
  ...dev,
  reportToSentry: true,
}
// Used for firefly.lanternventures.com
const prod: Config = staging

const environment = import.meta.env.MODE as Environment

const DevEnvironments = ['development', 'dev-local', 'dev-tailscale'] as const
type DevEnvironment = (typeof DevEnvironments)[number]
const ProdEnvironments = ['staging', 'production'] as const
type ProdEnvironment = (typeof ProdEnvironments)[number]

const Environments = [...DevEnvironments, ...ProdEnvironments] as const

if (!Environments.includes(environment)) {
  throw new Error(`Unrecognised value for import.meta.env.MODE: ${environment}`)
}
if (environment === 'dev-tailscale' && !tsHostname) {
  throw new Error(
    `Running in dev-tailscale mode requires a valid VITE_TAILSCALE_HOSTNAME env var`,
  )
}

// I actually don't really like this function, because it means some of the
// mode-varying parameters are specified in this file, but some are in arbitrary
// places that call this function. In general it seems better to me to (a)
// eliminate the distinction between different modes and/or (b) bring those
// decisions into this module so we can see them all at a glance.
export function isDevEnvironment(): boolean {
  return DevEnvironments.includes(environment as DevEnvironment)
}
export function isProdEnvironment(): boolean {
  return ProdEnvironments.includes(environment as ProdEnvironment)
}

export type Environment = (typeof Environments)[number]

const configs: Record<Environment, Config> = {
  development: dev,
  'dev-local': devLocal,
  'dev-tailscale': devTailscale,
  staging: staging,
  production: prod,
}

const config: Config & { environment: Environment; isLocalhost: boolean } = {
  environment,
  // Note that this is done dynamically so that it's straightforward for a
  // developer to vary config.apiUrl without having to remember to change other
  // config params
  isLocalhost: configs[environment].apiUrl.hostname.includes('localhost'),
  ...configs[environment],
}

export default config
