import { useCallback } from 'react'

import useApi from 'contexts/Api'
import useHandleApiError from 'hooks/useHandleApiError'

import { CapitalConcentrationLimitRow, FormValues } from './types'

type UseActionsMethods = {
  create: (values: FormValues) => void
  update: (values: FormValues) => void
  destroy: (values: CapitalConcentrationLimitRow) => void
}

export function useActions(): UseActionsMethods {
  const api = useApi()
  const handleApiError = useHandleApiError()

  const create: UseActionsMethods['create'] = useCallback(
    async ({ exchange_id, concentration_limit, tenant_id }) => {
      try {
        await api.capitalConcentrationLimits.capitalConcentrationLimitsCreate({
          exchange_id: +exchange_id,
          concentration_limit: +concentration_limit,
          tenant_id: +tenant_id,
        })
      } catch (err) {
        handleApiError(err)
      }
    },
    [api.capitalConcentrationLimits, handleApiError],
  )

  const update: UseActionsMethods['update'] = useCallback(
    async ({ exchange_id, concentration_limit, tenant_id }) => {
      try {
        await api.capitalConcentrationLimits.capitalConcentrationLimitsPartialUpdate(
          { concentration_limit: +concentration_limit },
          {
            exchange_id: `eq.${exchange_id}`,
            tenant_id: `eq.${tenant_id}`,
          },
        )
      } catch (err) {
        handleApiError(err)
      }
    },
    [api.capitalConcentrationLimits, handleApiError],
  )

  const destroy: UseActionsMethods['destroy'] = useCallback(
    async ({ exchange_id, tenant_id }) => {
      try {
        await api.capitalConcentrationLimits.capitalConcentrationLimitsDelete({
          exchange_id: `eq.${exchange_id}`,
          tenant_id: `eq.${tenant_id}`,
        })
      } catch (err) {
        handleApiError(err)
      }
    },
    [api.capitalConcentrationLimits, handleApiError],
  )

  return { create, update, destroy }
}
