import React, { useContext } from 'react'

import { z } from 'zod'

import { usePollApi } from 'hooks/usePollApi'

export interface Tenant {
  id: TenantId
  name: string
  parent_id: TenantId | null
  children: TenantId[]
}

type TenantCollections = {
  tenants: Tenant[]
  tenantsById: TenantMap
}

type TenantMap = { [k in TenantId]?: Tenant }

export const TenantIdParser = z.union([
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
  z.literal(5),
  z.literal(6),
])
export const TENANT_IDS = TenantIdParser.options.map(n => n._def.value).sort()
export type TenantId = z.infer<typeof TenantIdParser>

export function isTenantId(tenantId: number): tenantId is TenantId {
  return TenantIdParser.safeParse(tenantId).success
}

const TenantsContext = React.createContext<TenantCollections>({
  tenants: [],
  tenantsById: {},
})

export function TenantsContextProvider({
  children,
}: {
  children: JSX.Element
}): JSX.Element {
  const params = new URLSearchParams()
  params.set('select', 'id,name,parent_id,children')
  params.set('order', 'parent_id.asc.nullsfirst')

  const tenants = usePollApi<Tenant>({ path: 'tenants', params }, 60000)

  if (!tenants) {
    return <>Loading tenants...</>
  }

  const tenantsById = tenants.reduce(
    (tm, t): TenantMap => Object.assign(tm, { [t.id]: t }),
    {},
  )

  const tenantsContext = { tenants, tenantsById }

  return (
    <TenantsContext.Provider value={tenantsContext}>
      {children}
    </TenantsContext.Provider>
  )
}

export const useTenants = (): Tenant[] => useContext(TenantsContext).tenants

export const useTenant = (id: TenantId): Tenant => {
  const tenantsById = useContext(TenantsContext).tenantsById
  const tenant = tenantsById[id]
  if (typeof tenant === 'undefined')
    throw new Error(`Cannot select tenant with ID ${id}`)
  return tenant
}
