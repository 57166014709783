import React, { useCallback, useMemo } from 'react'

import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import { useHistory } from 'react-router-dom'
import usePrevious from 'react-use/lib/usePrevious'

import { LimitRoutes } from 'components/Limits'
import {
  CurrencyOption,
  useCurrencySelection,
} from 'contexts/CurrencySelection'
import { useSelectedCurrency } from 'contexts/SelectedCurrency'
import useSelectedTenant from 'contexts/SelectedTenant'
import useTenantSelection from 'contexts/TenantSelection'
import useEMP from 'hooks/useEMP'
import useRefreshCoordinator from 'hooks/useRefreshCoordinator'
import cycleOptions from 'utils/cycleOptions'

import HotKeysWithModal from './HotKeysWithModal'

const limitHotkeyHandlerNames = keyBy(
  LimitRoutes,
  ({ label }) => `goTo${label.replace(/\s/g, '')}Limits`,
)
const limitHotKeys = mapValues(
  limitHotkeyHandlerNames,
  ({ hotkey }) => `g l ${hotkey}`,
)

const keyMap = {
  goToCoinDeck: 'g c',
  goToData: 'g d',
  goToFills: 'g f',
  goToIndices: 'g i',
  goToOrders: 'g o',
  goToOrderHedgeStatus: 'g s',

  goToBalances: 'g b b',
  goToPositions: 'g b p',
  goToBanks: 'g b c',
  goToUpnl: 'g b u',
  goToBinanceMarginBalances: 'g b m',
  goToNewTransfer: 'g b x',
  goToHistoricalNAVs: 'g b h',
  goToFireblocks: 'g b f',

  goToPredictions: 'g p',
  goToRisk: 'g r',
  goToTWAPs: 'g t t',
  goToFundingRates: 'g t f',
  goToDerivatives: 'g t d',
  goToTransfers: 'g x',
  goToLimitsDashboard: 'g l h',
  ...limitHotKeys,
  goHome: 'g h',
  goToPerps: 'g g p',
  goToETHUSD: 'g g e',
  showAssetNav: 'g a',
  showMarketNav: 'g m',

  viewNextTenant: 'v n',
  viewPrevTenant: 'v p',
  viewParentTenant: ['v up', 'v u'],
  viewLastTenant: 'v v',

  viewPSP: 'v 1',
  viewBTC: 'v 2',
  viewUSD: 'v 3',
  viewETH: 'v 4',
  viewEDM: 'v 5',
  viewLantern: 'v l',

  refreshAll: 'R R',
  newTab: 'N N',
  submitForm: 'ctrl+enter',

  selectNextCurrency: 'c',
  selectPrevCurrency: 'C',

  triggerEMP: 'E M P',

  help: ['?', 'ctrl+shift+/'],
}

type RightNav = 'currency' | 'asset' | 'market'

type Props = {
  children: React.ReactNode
  setRightNav: (rn: RightNav) => void
}

function AppHotKeys({ children, setRightNav }: Props): React.ReactElement {
  const history = useHistory()

  const refresh = useRefreshCoordinator()
  const triggerEMP = useEMP()
  const selectedCurrency = useSelectedCurrency()
  const { updateCurrency, currencyOptions } = useCurrencySelection()

  const selectedTenant = useSelectedTenant()
  const selectedTenantId = selectedTenant.id
  const lastSelectedTenantId = usePrevious(selectedTenantId) ?? null
  const selectTenant = useTenantSelection()

  const shiftCurrency = useCallback(
    shift => {
      const shifted = cycleOptions(currencyOptions)(
        selectedCurrency as CurrencyOption,
        shift,
      )
      updateCurrency(shifted)
      setRightNav('currency')
    },
    [currencyOptions, selectedCurrency, setRightNav, updateCurrency],
  )

  const shiftTenant = useCallback(
    shift => {
      selectTenant(tenants => {
        const ids = tenants.map(t => t.id)
        return cycleOptions(ids)(selectedTenantId, shift)
      })
    },
    [selectTenant, selectedTenantId],
  )

  /**
   * Invoke history.push() with an event.preventDefault, so that keystrokes that
   * are part of navigation aren't sent to the UI
   */
  const historyPush = useCallback(
    (path: string) =>
      (e: KeyboardEvent): void => {
        e.preventDefault()
        history.push(path)
      },
    [history],
  )

  const handlers = useMemo(
    () => ({
      goToBalances: (): void => history.push('/balances'),
      goToBanks: (): void => history.push('/banks'),
      goToCoinDeck: (): void => history.push('/coin_deck'),
      goToData: (): void => history.push('/data'),
      goToFills: (): void => history.push('/fills'),
      goToHistoricalNAVs: (): void => history.push('/historical_navs'),
      goToFireblocks: (): void => history.push('/fireblocks'),
      goToIndices: (): void => history.push('/indices'),
      goToBinanceMarginBalances: (): void =>
        history.push('/binance_margin_balances'),
      goToNewTransfer: (): void => history.push('/transfers/new'),
      goToOrders: (): void => history.push('/orders'),
      goToOrderHedgeStatus: (): void => history.push('/order_hedge_status'),
      goToPositions: (): void => history.push('/positions'),
      goToUpnl: (): void => history.push('/upnl'),
      goToPredictions: (): void => history.push('/predictions'),
      goToRisk: (): void => history.push('/risk'),
      goToTWAPs: (): void => history.push('/twaps'),
      goToFundingRates: historyPush('/derivatives'),
      goToDerivatives: historyPush('/derivatives'),
      goToTransfers: (): void => history.push('/transfers'),
      goToLimitsDashboard: (): void => history.push('/limits/dashboard'),
      // Add handlers for all Limits routes
      ...mapValues(
        limitHotkeyHandlerNames,
        ({ slug }) =>
          () =>
            history.push(`/limits/${slug}`),
      ),
      goHome: (): void => history.push('/'),
      goToPerps: (): void => history.push('/markets/binance/BTCUSDT'),
      goToETHUSD: (): void => history.push('/markets/binance/ETHUSDT'),
      showAssetNav: (): void => {
        setRightNav('asset')
        const el = document.querySelector('#assetNavAsset')
        ;(el as HTMLSelectElement)?.focus()
      },
      showMarketNav: (e: Event): void => {
        e.preventDefault()
        setRightNav('market')
        const el = document.querySelector('#marketNav')
        ;(el as HTMLInputElement)?.select()
      },

      viewNextTenant: (): void => shiftTenant(1),
      viewPrevTenant: (): void => shiftTenant(-1),
      viewParentTenant: (): void => selectTenant(selectedTenant.parent_id),
      viewLastTenant: (): void => selectTenant(lastSelectedTenantId),

      viewPSP: (): void => selectTenant(2),
      viewBTC: (): void => selectTenant(3),
      viewUSD: (): void => selectTenant(4),
      viewETH: (): void => selectTenant(5),
      viewEDM: (): void => selectTenant(6),
      viewLantern: (): void => selectTenant(1),

      refreshAll: refresh,
      newTab: (): Window | null => window.open(window.location.origin),
      submitForm: (e: Event): void => {
        e.preventDefault()
        ;(document?.activeElement as HTMLInputElement)?.form?.requestSubmit()
      },

      selectNextCurrency: (): void => shiftCurrency(1),
      selectPrevCurrency: (): void => shiftCurrency(-1),

      triggerEMP,

      // Mousetrap sequence non-interference doesn't work across nested
      // HotKeys components
      'B C': (): void => {},
      'S C': (): void => {},
    }),
    [
      historyPush,
      refresh,
      triggerEMP,
      history,
      setRightNav,
      shiftTenant,
      selectTenant,
      selectedTenant.parent_id,
      lastSelectedTenantId,
      shiftCurrency,
    ],
  )

  return (
    <HotKeysWithModal
      keyMap={keyMap}
      handlers={handlers}
      focused
      attach={window}
      title="app"
    >
      {children}
    </HotKeysWithModal>
  )
}

export default React.memo(AppHotKeys)
