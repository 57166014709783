import { useCallback } from 'react'

import useBroadcastChannel from './useBroadcastChannel'

function useEMP(handler?: (m: MessageEvent) => void): () => void {
  handler = handler ?? ((): void => {})
  const postMessage = useBroadcastChannel('websocket_emp', handler)
  const trigger = useCallback(() => postMessage('EMP'), [postMessage])
  return trigger
}

export default useEMP
