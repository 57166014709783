import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'
import sum from 'lodash/sum'

import { WalletUPnL } from 'components/UpnlPage/types'
import { usePollApi } from 'hooks/usePollApi'

import { addIdToRow, rowStatus } from './rows'
import { DeltaLimitUsage, DeltaLimitUsageRow } from './types'

export const USD_EQUIVALENTS = [
  'USD',
  'USDT',
  'BUSD',
  'USDC',
  'UST',
  'USC',
  'USDP',
  'TUSD',
  'FDUSD',
  'USDL',
]

export function useDeltaLimitsUsage(
  params: URLSearchParams,
  excludeInverseUpnl: boolean,
): DeltaLimitUsageRow[] | undefined {
  // Add a default sort to rows, so that if we're sorting by e.g. tenant
  // (default on the Lantern view), we'll see rows in a sensible order
  const withDefaultParams = new URLSearchParams({
    order: 'tenant_id,delta_limit_proportion.desc.nullsfirst',
  })
  // We use this pattern instead of an object spread inside new URLSearchParams
  // because this means we can preserve multiple same-valued keys (in
  // particular, we expect multiple `or` params from our filters)
  for (const [k, v] of params.entries()) {
    withDefaultParams.append(k, v)
  }
  const deltaLimitsUsage = usePollApi<DeltaLimitUsage>({
    path: 'delta_limit_usage',
    addTenantId: true,
    params: withDefaultParams,
  })

  const inverseWalletUPnL = usePollApi<WalletUPnL>(
    {
      path: 'wallet_upnl',
      params: new URLSearchParams({
        wallet_name: `not.match.^(${USD_EQUIVALENTS.join('|')})(\\s|$)`,
      }),
    },
    10000,
  )

  if (!deltaLimitsUsage) return

  // This is too complex to express with query parameters. Plausibly it'd be
  // better to implement by adding a `proportion_delta_upnl` column to the
  // `delta_limit_usage` view, but I want to test out the concept with Trading
  // first, and this will be much easier to iterate on
  if (excludeInverseUpnl) {
    if (!inverseWalletUPnL) return
    // Sums over UPnL, grouped by tenant and asset
    const upnlByTenantAndAsset = mapValues(
      groupBy(inverseWalletUPnL, 'tenant_id'),
      r =>
        mapValues(groupBy(r, 'asset_id'), s =>
          sum(s.map(row => row.derived_upnl ?? row.exchange_upnl)),
        ),
    )

    const hasInverseUpnl = (row: DeltaLimitUsage) => {
      const upnl = upnlByTenantAndAsset[row.tenant_id]?.[row.asset_id] ?? 0
      return Math.abs(upnl) > 0
    }

    return deltaLimitsUsage
      .filter(row => !hasInverseUpnl(row) && rowStatus(row) === 'normal')
      .map(addIdToRow)
  }

  return deltaLimitsUsage.map(addIdToRow)
}
