import React, { useEffect, useState } from 'react'

import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import StyledBootstrapTable from 'components/StyledBoostrapTable'
import { EditableRow } from 'components/StyledBoostrapTable/EditableRow'
import useAlerts from 'contexts/Alerts'
import { useIsGlobalTenant } from 'contexts/SelectedTenant'
import { usePollApi } from 'hooks/usePollApi'

import { FormValues, useActions } from './actions'
import { addIdToRow, rowStatus } from './rows'
import {
  DeltaLimit,
  DeltaLimitRow,
  DeltaLimitUsageRow,
  EditableLimitRow,
  ViewMode,
  isDeltaLimitUsage,
} from './types'
import { useColumns } from './useColumns'

const rowClasses = (row: EditableLimitRow): string => {
  const status = rowStatus(row)
  switch (status) {
    case 'unknown':
    case 'data-error':
    case 'breach':
      return 'text-warning'
    case 'near-breach':
      return 'text-notice'
    case 'urgent-breach':
      return 'text-danger'
    default:
      return ''
  }
}

const rowStyle = (row: EditableLimitRow): React.CSSProperties => {
  return isDeltaLimitUsage(row) ? {} : { fontStyle: 'italic' }
}

type DeltaLimitsTableProps = {
  rows: DeltaLimitUsageRow[]
  viewMode: ViewMode
}

/**
 * Get asset ticker for sort comparison from either a DeltaLimitUsageRow or
 * DeltaLimitRow (and sort rows without an asset/'all assets' last)
 */
const getAssetSortValue = (row: DeltaLimitRow | DeltaLimitUsageRow) =>
  isDeltaLimitUsage(row) ? row.asset : (row.asset?.ticker ?? '\uFFFF')

export const DeltaLimitsTable: React.FC<DeltaLimitsTableProps> = ({
  rows,
  viewMode,
}) => {
  const [editingRow, setEditingRow] = useState<
    DeltaLimitRow | DeltaLimitUsageRow
  >()
  const { register, handleSubmit } = useForm<FormValues>()
  const { upsert, destroy } = useActions()
  const showAlert = useAlerts()
  const columns = useColumns({ viewMode, register, handleDestroy })
  const isGlobalTenant = useIsGlobalTenant()

  /**
   * The delta_limits_usage view (whence the 'rows' parameter) excludes rows
   * where there is currently a zero delta (unless zero delta is a breach). So,
   * in order to edit limits for assets that don't currently have a delta (or
   * 'all assets' rows which aren't attached to a specific delta), we need to
   * pull in the delta limits that don't show up in the main view.
   *
   * We will then merge these with the DeltaLimitUsage rows, deduplicating them
   * against anything already represented there.
   */
  const params = new URLSearchParams({
    // TODO: use new spread syntax to push asset ticker to top level
    // See https://postgrest.org/en/stable/references/api/resource_embedding.html#spread-embed
    select: '*,asset:asset_info(ticker)',
  })

  const deltaLimitsWithoutDelta: DeltaLimitRow[] = (
    usePollApi<DeltaLimit>({
      path: 'delta_limits',
      params,
      addTenantId: true,
    }) ?? []
  ).map(addIdToRow)

  useEffect(() => {
    if (viewMode !== 'edit') {
      setEditingRow(undefined)
    }
  }, [viewMode])

  async function onSubmit(values: FormValues) {
    if (!editingRow) {
      return
    }
    const { succeeded } = await upsert(values, editingRow)
    if (succeeded) {
      showAlert({
        message: 'Successfully updated delta limit',
        style: 'success',
      })
      setEditingRow(undefined)
    }
  }

  async function handleDestroy(row: DeltaLimitRow | DeltaLimitUsageRow) {
    const { succeeded } = await destroy(row)
    if (succeeded) {
      showAlert({
        message: 'Deleted delta limit',
        style: 'warning',
      })
      setEditingRow(undefined)
    }
  }

  function makeEditableRow<T extends DeltaLimitRow | DeltaLimitUsageRow>(
    row: T,
  ): EditableRow<T> {
    return {
      ...row,
      isEditing: row.id === editingRow?.id,
      setEditing: () => setEditingRow(row),
    }
  }

  const data: EditableLimitRow[] = rows.map(makeEditableRow)
  if (viewMode === 'edit') {
    const existingIds = new Set(data.map(row => row.id))
    data.push(
      ...deltaLimitsWithoutDelta
        .filter(row => !existingIds.has(row.id))
        .map(makeEditableRow),
    )
  }
  // Sort so that if we're on the all-tenants view and sorting by tenant, the
  // secondary sort will still be by asset ticker
  data.sort((a, b) => getAssetSortValue(a).localeCompare(getAssetSortValue(b)))

  return (
    <>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        onReset={() => setEditingRow(undefined)}
      >
        <StyledBootstrapTable
          bootstrap4
          striped
          condensed
          columns={columns}
          data={data}
          rowClasses={rowClasses}
          rowStyle={rowStyle}
          defaultSorted={
            isGlobalTenant
              ? [
                  {
                    dataField: 'tenant_id',
                    order: 'asc',
                  },
                ]
              : [{ dataField: 'delta_limit_proportion', order: 'desc' }]
          }
          defaultSortDirection="desc"
          keyField="id"
        />
      </Form>
    </>
  )
}
