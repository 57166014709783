import React from 'react'

import Tenant from 'components/Tenant'
import { getTenantColor } from 'contexts/TenantSelection'
import { TenantId } from 'contexts/Tenants'

import { ExtendedColumnDescription } from '.'

/**
 * Generic column for displaying a tenant in a StyledBootstrapTable, assuming
 * that the row contains a `tenant_id` property.
 */
// It's kind of dumb that this is a function rather than a plain object.
// However, if we leave it as an object, we have to type it as
// ExtendedColumnDescription<any> to get it to fit in with a list of other
// columns that have a more specific type.
export function getTenantColumn<
  T extends { tenant_id: TenantId },
>(): ExtendedColumnDescription<T> {
  return {
    dataField: 'tenant_id',
    text: 'Tenant',
    sort: true,
    formatter: tenantId => <Tenant id={tenantId} />,
    headerStyle: { width: 80 },
    // We have to access the tenant_id through the row rather than the first
    // argument because RBT gets the typings for the first argument wrong
    style: (_, row: T) => ({
      borderLeft: `4px solid ${getTenantColor(row.tenant_id)}`,
    }),
  }
}
