import { useCallback, useEffect, useRef, useState } from 'react'

import { isEqual, pick } from 'lodash'

import useBroadcastChannel from 'hooks/useBroadcastChannel'

const hookId =
  Date.now().toString(36) + Math.random().toString(36).substring(2, 6)

type Subscription = number
type ForeignSubscription = { actives: Subscription[]; ts: number }

function dedupForeignSubs(subs: { [k: string]: ForeignSubscription }) {
  return [
    ...new Set([
      ...Object.values(subs ?? {})
        .map(h => h.actives)
        .flat(),
    ]),
  ].sort()
}

export default function useSubscriptions(
  key: string,
): [Subscription[], (...ids: number[]) => void, (...ids: number[]) => void] {
  const [subscriptions, setSubscriptions] = useState(
    {} as {
      [k: string]: number
    },
  )
  const foreignSubscriptions = useRef(
    {} as {
      [k: string]: ForeignSubscription
    },
  )
  const [foreignActives, setForeignActives] = useState([] as Subscription[])

  const pruneForeignSubscriptions = useCallback(() => {
    const oldSubs = foreignSubscriptions.current
    const liveKeys = Object.keys(oldSubs).filter(
      k => oldSubs[k].ts > Date.now() - 5000,
    )
    foreignSubscriptions.current = pick(oldSubs, liveKeys)
  }, [])

  const updateForeignSubscriptions = useCallback(({ hookId, actives }) => {
    foreignSubscriptions.current[hookId] = { actives, ts: Date.now() }
  }, [])

  const refreshForeignActives = useCallback(() => {
    const newActives = dedupForeignSubs(foreignSubscriptions.current)
    setForeignActives(oldActives =>
      !isEqual(oldActives, newActives) ? newActives : oldActives,
    )
  }, [])

  const onmessage = useCallback(
    ({ data }) => {
      pruneForeignSubscriptions()
      updateForeignSubscriptions(data)
      refreshForeignActives()
    },
    [
      pruneForeignSubscriptions,
      updateForeignSubscriptions,
      refreshForeignActives,
    ],
  )

  const channelKey = `subscriptions:${key}`
  const postMessage = useBroadcastChannel(channelKey, onmessage)

  const subscribe = useCallback((...ids: number[]): void => {
    if (ids.length === 0) {
      return
    }
    setSubscriptions(subs => {
      const newSubs = Object.assign({}, subs)
      ids.forEach(id => (newSubs[id] ? (newSubs[id] += 1) : (newSubs[id] = 1)))
      return newSubs
    })
  }, [])
  const unsubscribe = useCallback((...ids: number[]): void => {
    if (ids.length === 0) {
      return
    }
    setSubscriptions(subs => {
      const newSubs = Object.assign({}, subs)
      ids.forEach(id =>
        newSubs[id] > 1 ? (newSubs[id] -= 1) : delete newSubs[id],
      )
      return newSubs
    })
  }, [])

  const actives = Object.keys(subscriptions)
    .filter(k => subscriptions[k] > 0)
    .map(Number)
    .sort()

  const comparableActives = JSON.stringify(actives)
  const heartbeat = useCallback(() => {
    postMessage({ hookId, actives: JSON.parse(comparableActives) })
    pruneForeignSubscriptions()
    refreshForeignActives()
  }, [
    comparableActives,
    postMessage,
    pruneForeignSubscriptions,
    refreshForeignActives,
  ])
  useEffect(() => {
    heartbeat()
    const id = setInterval(heartbeat, 1000)
    return () => clearInterval(id)
  }, [heartbeat])

  const allActives = [...new Set([...actives, ...foreignActives])].sort()

  return [allActives, subscribe, unsubscribe]
}
