import React, { useContext } from 'react'

import { usePollApi } from 'hooks/usePollApi'

import { TenantId } from './Tenants'

export type Wallet = {
  id: WalletId
  tenant_id: TenantId
  exchange_id: number
  asset_id: number
  name: string | null
  wallet_type: WalletType
  disabled: boolean
}

type WalletId = number

type WalletMap = { [k: number]: { [k: WalletId]: Wallet } }

type WalletType =
  | 'transfer'
  | 'spot'
  | 'margin'
  | 'futures'
  | 'swaps'
  | 'lending'

type WalletsContext = {
  wallets: Wallet[]
  walletsByIdByTenant: WalletMap
}

const initialContext: WalletsContext = {
  wallets: [],
  walletsByIdByTenant: {},
}

const WalletsContext = React.createContext<WalletsContext>(initialContext)

export function WalletsProvider({
  children,
}: {
  children: JSX.Element
}): JSX.Element {
  const wallets = usePollApi<Wallet>('hot_wallets', 60000)

  if (!wallets) {
    return <>Loading wallets...</>
  }

  const walletsByIdByTenant = {} as WalletMap

  wallets.forEach(w => {
    const tenantWallets = walletsByIdByTenant[w.tenant_id]
    if (!tenantWallets) {
      walletsByIdByTenant[w.tenant_id] = { [w.id]: w }
    } else if (!(w.id in tenantWallets)) {
      tenantWallets[w.id] = w
    }
  })

  const value = {
    wallets,
    walletsByIdByTenant,
  }

  return (
    <WalletsContext.Provider value={value}>{children}</WalletsContext.Provider>
  )
}

export const useWallet = (
  tenantId: TenantId,
  walletId: WalletId,
): Wallet | null => {
  const { walletsByIdByTenant } = useContext(WalletsContext)
  return walletsByIdByTenant[tenantId]?.[walletId]
}

export const useWallets = (includeDisabled = false): Wallet[] => {
  const { wallets } = useContext(WalletsContext)
  return includeDisabled ? wallets : wallets.filter(w => !w.disabled)
}
