import React from 'react'

import { Form } from 'react-bootstrap'
import { UseFormMethods } from 'react-hook-form'
import styled from 'styled-components'

import Asset from 'components/Asset'
import {
  EditRowButtons,
  FlexButtonWrapper,
  LastUpdatedInfo,
} from 'components/EditableTableFields'
import Exchange from 'components/Exchange'
import Quantity from 'components/Quantity'
import { ExtendedColumnDescription } from 'components/StyledBoostrapTable'
import { getTenantColumn } from 'components/StyledBoostrapTable/columns'
import Wallet from 'components/Wallet'
import { Quantity as QuantityType } from 'types/Quantity'

import { useActions } from './actions'
import { LIMIT_FIELD_PRECISION, QUANTITY_FIELD_PRECISION } from './precision'
import { rowStatus } from './rows'
import { Row, isUncollateralizedPosition } from './types'
import { MarginLimitKey } from './types'

const NotesWrapper = styled.div`
  p {
    margin-bottom: 0;
    margin-top: 0.5rem;
    &:first-child {
      margin-top: 0;
    }
  }
`

const quantityFormatter = (q: QuantityType | null | undefined): JSX.Element => {
  return q ? (
    <Quantity
      quantity={q.quantity}
      currency={{ id: q.asset_id }}
      precision={QUANTITY_FIELD_PRECISION}
    />
  ) : (
    <></>
  )
}

const limitFieldFormatter =
  (
    name: MarginLimitKey,
    register: UseFormMethods['register'],
    setLimitFieldsDirty: React.Dispatch<
      React.SetStateAction<Record<MarginLimitKey, boolean>>
    >,
  ) =>
  (cell: number | null, row: Row) => {
    if (isUncollateralizedPosition(row)) {
      return ''
    }
    if (row.isEditing) {
      return (
        <Form.Control
          type="number"
          min={0}
          step={0.01}
          required
          name={name}
          ref={register}
          defaultValue={cell ?? undefined}
          onInput={() =>
            setLimitFieldsDirty(state => ({ ...state, [name]: true }))
          }
          onFocus={() => {
            setLimitFieldsDirty(state => ({ ...state, [name]: true }))
          }}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            e.target.value === '' &&
              setLimitFieldsDirty(state => ({ ...state, [name]: false }))
          }}
        />
      )
    } else {
      return cell === null ? '-' : cell.toFixed(LIMIT_FIELD_PRECISION)
    }
  }

const marginLimitNumberFormatter = (num: number | null, row: Row) => {
  if (isUncollateralizedPosition(row)) {
    return ''
  }
  return typeof num === 'number' ? num.toFixed(3) : ''
}

function getDirectionMultiplier(row: Row): -1 | 1 | null {
  if (isUncollateralizedPosition(row) || row.worst_limit_direction === null) {
    return null
  }
  return row.worst_limit_direction === 'long' ? 1 : -1
}

function excessBalance(row: Row): QuantityType | null {
  if (isUncollateralizedPosition(row)) {
    return null
  }

  const exposure = row.worst_exposure
  const lower_limit = row.worst_lower_limit
  if (exposure === null || exposure.quantity === 0) {
    return row.balance
  }

  if (lower_limit === null) {
    return null
  }
  const requiredBalance = Math.abs(exposure.quantity) * lower_limit
  return {
    asset_id: exposure.asset_id,
    quantity: (row.balance?.quantity ?? 0) - requiredBalance,
  }
}

function excessExposure(row: Row): QuantityType | null {
  if (isUncollateralizedPosition(row)) {
    return null
  }

  const exposure = row.worst_exposure
  const lower_limit = row.worst_lower_limit
  const multiplier = getDirectionMultiplier(row)
  if (row.balance === null || row.balance.quantity === 0) {
    return exposure
  }

  if (lower_limit === null || multiplier === null) {
    return null
  }

  const maximumExposure = (row.balance.quantity / lower_limit) * multiplier

  return {
    asset_id: row.balance.asset_id,
    quantity: (exposure?.quantity ?? 0) - maximumExposure,
  }
}

export const ViewModes = ['exposures', 'excess', 'edit', 'directional'] as const
export type ViewMode = (typeof ViewModes)[number]

type UseColumnsProps = {
  register: UseFormMethods['register']
  setLimitFieldsDirty: React.Dispatch<
    React.SetStateAction<Record<MarginLimitKey, boolean>>
  >
  viewMode: ViewMode
}

export function useColumns({
  register,
  setLimitFieldsDirty,
  viewMode = 'excess',
}: UseColumnsProps): ExtendedColumnDescription<Row>[] {
  const { destroy } = useActions({})

  const limitWidth = 70

  const tenantIdCol = getTenantColumn<Row>()

  const walletCol: ExtendedColumnDescription<Row> = {
    dataField: 'wallet',
    isDummyField: true,
    text: 'Wallet',
    formatter: (_, row) => {
      if (isUncollateralizedPosition(row)) {
        return (
          <>
            <Exchange id={row.exchange_id} colored />{' '}
            <Asset asset={{ id: row.asset_id }} colored link={false} />{' '}
            (no&nbsp;wallet)
          </>
        )
      }
      return (
        <>
          <Wallet
            walletId={row.wallet_id}
            tenantId={row.tenant_id}
            showExchange
            colored
          />
        </>
      )
    },
    sort: true,
    headerStyle: {
      width: 165,
    },
    sortValue: (_, row) => {
      if (isUncollateralizedPosition(row)) {
        return `${row.exchange_id}`
      }
      return `${row.exchange_id}-${row.wallet_name}-${row.wallet_type}`
    },
  }

  const lowerLimitShortCol: ExtendedColumnDescription<Row> = {
    dataField: 'lower_limit_short',
    text: 'Lower limit short',
    shortText: '- >',
    formatter: limitFieldFormatter(
      'lower_limit_short',
      register,
      setLimitFieldsDirty,
    ),
    sort: true,
    align: 'right',
    headerStyle: {
      width: limitWidth,
    },
  }

  const upperLimitShortCol: ExtendedColumnDescription<Row> = {
    dataField: 'upper_limit_short',
    text: 'Upper limit short',
    shortText: '- <',
    formatter: limitFieldFormatter(
      'upper_limit_short',
      register,
      setLimitFieldsDirty,
    ),
    sort: true,
    align: 'right',
    headerStyle: {
      width: limitWidth,
    },
  }

  const lowerLimitLongCol: ExtendedColumnDescription<Row> = {
    dataField: 'lower_limit_long',
    text: 'Lower limit long',
    shortText: '+ >',
    formatter: limitFieldFormatter(
      'lower_limit_long',
      register,
      setLimitFieldsDirty,
    ),
    sort: true,
    align: 'right',
    headerStyle: {
      width: limitWidth,
    },
  }

  const upperLimitLongCol: ExtendedColumnDescription<Row> = {
    dataField: 'upper_limit_long',
    text: 'Upper limit long',
    shortText: '+ <',
    formatter: limitFieldFormatter(
      'upper_limit_long',
      register,
      setLimitFieldsDirty,
    ),
    sort: true,
    align: 'right',
    headerStyle: {
      width: limitWidth,
    },
  }

  const balanceCol: ExtendedColumnDescription<Row> = {
    dataField: 'balance',
    text: 'Balance',
    formatter: balance => quantityFormatter(balance),
    align: 'right',
    sort: true,
    headerStyle: {
      width: 110,
    },
    sortValue: (_, row) => {
      if (isUncollateralizedPosition(row)) {
        return 0
      }
      return row.balance?.quantity ?? 0
    },
  }

  const excessBalanceCol: ExtendedColumnDescription<Row> = {
    dataField: 'excess_balance',
    isDummyField: true,
    text: 'Excess Balance',
    formatter: (_, row) => quantityFormatter(excessBalance(row)),
    align: 'right',
    headerStyle: {
      width: 110,
    },
  }

  const exposureCol: ExtendedColumnDescription<Row> = {
    dataField: 'exposure',
    text: 'Net Exposure',
    formatter: (exposure, row) => {
      if (isUncollateralizedPosition(row)) {
        return quantityFormatter({
          asset_id: row.asset_id,
          quantity: row.position,
        })
      }
      return quantityFormatter(exposure)
    },
    align: 'right',
    sort: true,
    headerStyle: {
      width: 110,
    },
    sortValue: (_, row) => {
      if (isUncollateralizedPosition(row)) {
        return row.position
      }
      // TODO: we probably want this abs to be toggleable
      return Math.abs(row.exposure?.quantity ?? 0)
    },
  }

  const longExposureCol: ExtendedColumnDescription<Row> = {
    dataField: 'long_exposure',
    text: 'Long Exposure',
    formatter: exposure => quantityFormatter(exposure),
    align: 'right',
    sort: true,
    headerStyle: {
      width: 110,
    },
    sortValue: (_, row) => {
      // Keep uncollaterlized positions on top
      if (isUncollateralizedPosition(row)) {
        return Infinity
      }
      // Long exposures are always positive numbers, so we don't need to take an
      // abs value
      return row.exposure?.quantity ?? -Infinity
    },
  }

  const shortExposureCol: ExtendedColumnDescription<Row> = {
    dataField: 'short_exposure',
    text: 'Short Exposure',
    formatter: exposure => quantityFormatter(exposure),
    align: 'right',
    sort: true,
    headerStyle: {
      width: 110,
    },
    sortValue: (_, row) => {
      // Keep uncollaterlized positions on top
      if (isUncollateralizedPosition(row)) {
        return Infinity
      }
      // Short exposures are always negative numbers, so we want to sort them
      // 'smallest' (i.e. largest absolute exposure) to largest, with nulls
      // last
      return -(row.exposure?.quantity ?? Infinity)
    },
  }

  const worstExposureCol: ExtendedColumnDescription<Row> = {
    ...exposureCol,
    dataField: 'worst_exposure',
    text: 'Worst Exposure',
    formatter: exposure => quantityFormatter(exposure),
  }

  const excessExposureCol: ExtendedColumnDescription<Row> = {
    dataField: 'excess_exposure',
    isDummyField: true,
    text: 'Excess Exposure',
    formatter: (_, row) => quantityFormatter(excessExposure(row)),
    align: 'right',
    headerStyle: {
      width: 110,
    },
  }

  const marginRatioCol: ExtendedColumnDescription<Row> = {
    dataField: 'margin_ratio',
    text: 'Margin ratio',
    shortText: 'MR',
    formatter: marginLimitNumberFormatter,
    align: 'right',
    sort: true,
    headerStyle: {
      width: 55,
    },
  }

  const marginRatioLongCol: ExtendedColumnDescription<Row> = {
    ...marginRatioCol,
    dataField: 'margin_ratio_long',
    text: 'Margin ratio (long)',
    shortText: 'MR-L',
  }

  const marginRatioShortCol: ExtendedColumnDescription<Row> = {
    ...marginRatioCol,
    dataField: 'margin_ratio_short',
    text: 'Margin ratio (short)',
    shortText: 'MR-S',
  }

  const marginRatioWorstCol: ExtendedColumnDescription<Row> = {
    ...marginRatioCol,
    dataField: 'worst_margin_ratio',
    text: 'Margin ratio (worst)',
    shortText: 'MR-W',
    formatter: marginLimitNumberFormatter,
  }

  const marginLimitProportionCol: ExtendedColumnDescription<Row> = {
    dataField: 'margin_limit_proportion',
    text: `Margin Limit Proportion`,
    shortText: `MLP`,
    formatter: marginLimitNumberFormatter,
    align: 'right',
    sort: true,
    classes: (_, row) => (rowStatus(row) === 'info' ? 'alert-info' : ''),
    sortValue: (proportion, row) => {
      // Missing balances or limits count as breaches. Breaches are positive
      // values close to zero, so returning a sortValue of zero will put this at
      // the top of the list
      if (isUncollateralizedPosition(row) || row.lower_limit_long === null) {
        return 0
      }
      // no exposure means no possibility of breach
      if (row.exposure === null) {
        return Infinity
      }
      // exposure and no balance is always a breach
      if (row.balance === null) {
        return 0
      }
      // exposure, balance and limits => this is a regular row
      return proportion
    },
    headerStyle: {
      width: 55,
    },
    style: {
      fontWeight: 'bold',
    },
  }

  const marginLimitProportionLongCol: ExtendedColumnDescription<Row> = {
    ...marginLimitProportionCol,
    dataField: 'margin_limit_proportion_long',
    text: 'Margin Limit Proportion (Long)',
    shortText: 'MLP-L',
  }

  const marginLimitProportionShortCol: ExtendedColumnDescription<Row> = {
    ...marginLimitProportionCol,
    dataField: 'margin_limit_proportion_short',
    text: 'Margin Limit Proportion (Short)',
    shortText: 'MLP-S',
  }

  const marginLimitProportionWorstCol: ExtendedColumnDescription<Row> = {
    ...marginLimitProportionCol,
    dataField: 'worst_margin_limit_proportion',
    text: 'Margin Limit Proportion (Worst)',
    shortText: 'MLP-W',
    formatter: marginLimitNumberFormatter,
  }

  function handleNotesInput(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    const key = event.key
    // Pressing enter submits the form, but users can use Shift+Enter to add
    // linebreaks if so desired
    if (key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      event.currentTarget.closest<HTMLFormElement>('form')?.requestSubmit()
    }
  }

  // Select notes text on focus
  function handleNotesFocus(event: React.FocusEvent<HTMLTextAreaElement>) {
    event.target.select()
  }

  const notesCol: ExtendedColumnDescription<Row> = {
    dataField: 'note',
    text: 'Note',
    shortText: 'Note',
    formatter: (note, row) => {
      if (isUncollateralizedPosition(row)) {
        return <></>
      }
      if (row.isEditing) {
        return (
          <Form.Control
            as="textarea"
            name="note"
            ref={register}
            defaultValue={note ?? undefined}
            rows={3}
            placeholder="Note"
            onFocus={handleNotesFocus}
            onKeyDown={handleNotesInput}
          />
        )
      }
      if (note === null) {
        return ''
      }

      return (
        <NotesWrapper>
          {row.note
            ?.split('\n')
            .filter(a => a.trim())
            .map((line, i) => <p key={`${line}-${i}`}>{line}</p>)}
        </NotesWrapper>
      )
    },
    sort: true,
    headerStyle: { width: 180 },
  }

  const buttonsCol: ExtendedColumnDescription<Row> = {
    dataField: 'buttons',
    isDummyField: true,
    text: '',
    headerStyle: { width: 85 },
    style: { padding: 0, height: 0 },
    formatter: (_, row) => {
      if (isUncollateralizedPosition(row)) {
        return <></>
      }
      return (
        <FlexButtonWrapper>
          <EditRowButtons
            isEditing={row.isEditing}
            onSetEditing={row.setEditing}
            onDestroy={() => destroy(row)}
            canDestroy={!!row.lower_limit_long}
            size="sm"
          />
          {row.updated_at && row.updated_by && (
            <LastUpdatedInfo
              updated_at={new Date(row.updated_at)}
              updated_by={row.updated_by.name}
            />
          )}
        </FlexButtonWrapper>
      )
    },
  }

  switch (viewMode) {
    case 'exposures':
      return [
        tenantIdCol,
        walletCol,
        marginRatioWorstCol,
        marginLimitProportionWorstCol,
        balanceCol,
        worstExposureCol,
        longExposureCol,
        shortExposureCol,
      ]
    case 'excess':
      return [
        tenantIdCol,
        walletCol,
        marginRatioWorstCol,
        marginLimitProportionWorstCol,
        balanceCol,
        excessBalanceCol,
        worstExposureCol,
        excessExposureCol,
      ]
    case 'edit':
      return [
        tenantIdCol,
        walletCol,
        marginRatioWorstCol,
        marginLimitProportionWorstCol,
        lowerLimitShortCol,
        upperLimitShortCol,
        lowerLimitLongCol,
        upperLimitLongCol,
        balanceCol,
        worstExposureCol,
        notesCol,
        buttonsCol,
      ]
    case 'directional':
      return [
        tenantIdCol,
        walletCol,
        balanceCol,
        exposureCol,
        marginRatioCol,
        marginLimitProportionCol,
        longExposureCol,
        marginRatioLongCol,
        marginLimitProportionLongCol,
        shortExposureCol,
        marginRatioShortCol,
        marginLimitProportionShortCol,
      ]
  }
}
