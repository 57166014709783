import React from 'react'

import styled from 'styled-components'

const CloseablePillWrapper = styled.span`
  border-radius: 9px;
  background: lightgray;
  padding: 2px 9px;
  display: inline-block;
  margin: 0 5px 5px 0;
`

const CloseablePillContent = styled.span`
  border-radius: 10px 0 0 10px;
  border-right: 1px solid darkgray;
  padding-right: 5px;
`
const CloseablePillCloseButton = styled.button`
  border-width: 0;
  border: none;
  width: auto;
  overflow: visible;
  background: transparent;
  margin-right: -9px;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
`

type CloseablePillProps = {
  onClick: () => void
  buttonProps?: Omit<React.HTMLProps<HTMLButtonElement>, 'type' | 'ref' | 'as'>
}

const CloseablePill: React.FC<CloseablePillProps> = ({
  onClick,
  buttonProps = {},
  children,
}) => {
  return (
    <CloseablePillWrapper>
      <CloseablePillContent>{children}</CloseablePillContent>
      <CloseablePillCloseButton {...buttonProps} onClick={onClick}>
        ⨉
      </CloseablePillCloseButton>
    </CloseablePillWrapper>
  )
}

export default CloseablePill
