import React, { useContext } from 'react'

import { usePollApi } from 'hooks/usePollApi'
import { Asset, AssetId } from 'types/Asset'

export type AssetsById = { [k: number]: Asset }
export type AssetsByTicker = { [k: string]: Asset }

export type AssetsContext = {
  assets: Asset[]
  assetsById: AssetsById
  assetsByTicker: AssetsByTicker
}

const initialContext: AssetsContext = {
  assets: [],
  assetsById: {},
  assetsByTicker: {},
}

const AssetsContext = React.createContext(initialContext)

function AssetsProviderInner({
  children,
}: {
  children: React.ReactNode
}): React.ReactElement {
  const assets = usePollApi<Asset>('assets', 60000)

  if (!assets) {
    return <>Loading assets...</>
  }

  assets.sort((a, b) => a.ticker.localeCompare(b.ticker))

  const assetsById = assets.reduce(
    (acc, a) => Object.assign(acc, { [a.id]: a }),
    {},
  )
  const assetsByTicker = assets.reduce(
    (acc, a) => Object.assign(acc, { [a.ticker]: a }),
    {},
  )
  const activeAssets = assets.filter(x => !x.expired)

  return (
    <AssetsContext.Provider
      value={{ assets: activeAssets, assetsById, assetsByTicker }}
    >
      {children}
    </AssetsContext.Provider>
  )
}

export const AssetsProvider = React.memo(AssetsProviderInner)

// `asset` is one of:
//   - A number id
//   - A string ticker
//   - An object { id: number }
//   - An object { ticker: string }

export function useAsset(asset: AssetId | undefined | null): Asset | undefined {
  const { assetsById, assetsByTicker } = useContext(AssetsContext)
  if (asset === null || asset === undefined) {
    return undefined
  } else if (typeof asset === 'number') {
    return assetsById[asset]
  } else if (typeof asset === 'string') {
    return assetsByTicker[asset]
  } else if ('id' in asset) {
    return assetsById[asset.id]
  } else {
    return assetsByTicker[asset.ticker]
  }
}

export function useAssets(assets?: AssetId[]): Asset[] {
  const {
    assets: allAssets,
    assetsById,
    assetsByTicker,
  } = useContext(AssetsContext)
  if (!assets) {
    return allAssets
  } else {
    return assets
      .map(asset => {
        if (typeof asset === 'number') {
          return assetsById[asset]
        } else if (typeof asset === 'string') {
          return assetsByTicker[asset]
        } else if ('id' in asset) {
          return assetsById[asset.id]
        } else {
          return assetsByTicker[asset.ticker]
        }
      })
      .sort((a, b) => a.ticker.localeCompare(b.ticker))
      .filter(a => a)
  }
}

export function useAssetsById(): AssetsById {
  const { assetsById } = useContext(AssetsContext)
  return assetsById
}

export function assetIdToString(id: AssetId): string {
  switch (typeof id) {
    case 'number':
    case 'string':
      return id.toString()
    default:
      return 'ticker' in id ? id.ticker : id.id.toString()
  }
}
