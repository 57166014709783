import React from 'react'

import xorBy from 'lodash/xorBy'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'

import CloseablePill from 'components/CloseablePill'
import { useForwardedRef } from 'hooks/useForwardedRef'

import AutocompleteBase from './base'
import { AutocompleteItem, AutocompletePropsBase } from './types'

const SelectedItemsWrapper = styled.div`
  padding: 5px 0;
`

export type AutocompleteMultipleProps<T extends AutocompleteItem> =
  AutocompletePropsBase<T> & {
    onSelect: (item: T[]) => void
    selectedItems: T[]
  }

export function AutocompleteMultiple<T extends AutocompleteItem>({
  onSelect,
  selectedItems,
  ...props
}: AutocompleteMultipleProps<T>): JSX.Element {
  const inputRef = useForwardedRef(props.inputProps?.ref)

  /** Given an item, toggle its presence in the selectedItems array */
  function toggleItemSelection(item: T | null | undefined) {
    if (!item) {
      return
    }
    // toggle the item in the array using XOR
    const newSelectedItems = xorBy(selectedItems, [item])
    onSelect(newSelectedItems)
  }

  function focusInput() {
    const el = inputRef.current
    el?.focus()
  }

  function handleClearItem(item: T) {
    toggleItemSelection(item)
    focusInput()
  }

  function handleClear() {
    onSelect([])
    focusInput()
  }

  return (
    <>
      <AutocompleteBase
        {...props}
        isMultiple={true}
        inputProps={{
          ...props.inputProps,
          ref: inputRef,
        }}
        onSelect={item => toggleItemSelection(item)}
        selectedItems={selectedItems}
      />
      {!!selectedItems.length && (
        <SelectedItemsWrapper>
          {selectedItems.map(selectedItem => (
            <CloseablePill
              key={selectedItem[props.keyBy] as string | number}
              onClick={() => handleClearItem(selectedItem)}
              buttonProps={{ tabIndex: -1 }}
            >
              {props.formatItem(selectedItem)}
            </CloseablePill>
          ))}
          {selectedItems.length > 1 && (
            <Button variant="link" onClick={handleClear} tabIndex={-1}>
              Clear all
            </Button>
          )}
        </SelectedItemsWrapper>
      )}
    </>
  )
}

export default AutocompleteMultiple
