import React from 'react'

import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { Glyphicon } from 'components/BootstrapShim'

import { LimitNavRoute, LimitRoutes } from '../'
import { RowStatus } from '../types'

export const LimitsDashboard: React.FC = () => {
  return (
    <>
      {LimitRoutes.map(route => (
        <LimitSummary key={route.slug} {...route} />
      ))}
    </>
  )
}

const statusClassMap: Record<RowStatus, string> = {
  unknown: 'border-danger',
  'data-error': 'border-danger',
  'urgent-breach': 'border-danger',
  breach: 'border-warning',
  'near-breach': 'border-notice',
  info: 'border-info',
  normal: 'border-success',
}

const LimitSummary: React.FC<LimitNavRoute> = ({
  slug,
  label,
  useDashboardComponent,
}) => {
  const dashboard = useDashboardComponent()
  const Component = dashboard.loaded ? dashboard.Component : undefined

  return (
    <Container>
      <div
        style={{
          border: '1px solid',
          borderLeft: '6px solid',
          padding: '1rem',
          paddingLeft: 'calc(1rem - 6px)',
          margin: '1rem calc(-1 * (1rem + 15px))',
          borderRadius: '0.5em',
        }}
        className={
          dashboard.loaded
            ? statusClassMap[dashboard.status]
            : 'border-secondary'
        }
      >
        <Container>
          <h3
            style={{
              marginBottom: Component ? '1rem' : 0,
              fontSize: Component ? undefined : '1rem',
            }}
          >
            <Link to={`/limits/${slug}`}>{label}</Link>
            {!dashboard.loaded ? (
              <>
                {' '}
                <em>Loading...</em>
              </>
            ) : (
              dashboard.status === 'normal' && (
                <span className="text-success">
                  {' '}
                  <Glyphicon glyph="check-circle" />
                </span>
              )
            )}
          </h3>
          {Component}
        </Container>
      </div>
    </Container>
  )
}
