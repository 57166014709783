import { useCallback } from 'react'

import SharedWorker from 'hooks/usePollApi/worker?sharedworker'

import useBroadcastChannel from './useBroadcastChannel'

const usePollApiWorker = new SharedWorker()

type RefreshCoordinator = () => void

const reload = window.location.reload.bind(window.location)

const refresh = (instant = false): void => {
  // we want the usePollApi worker to be destroyed when we refresh all windows,
  // so that it starts with brand new state
  usePollApiWorker.port.postMessage('destroy')

  if (instant) {
    reload()
    return
  }
  const timeout = Math.floor(Math.random() * 5000)
  setTimeout(reload, timeout)
}

export default function useRefreshCoordinator(): RefreshCoordinator {
  const postMessage = useBroadcastChannel('refresh_coordination', () =>
    refresh(),
  )
  const triggerRefresh = useCallback(() => {
    postMessage('refresh')
    refresh(true)
  }, [postMessage])
  return triggerRefresh
}
