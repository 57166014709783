import config from 'config'

import useSelectedTenant from 'contexts/SelectedTenant'
import { Tenant } from 'contexts/Tenants'

export type QueryValue = string | number | number[] | string[]
export type QueryField<T extends QueryValue = QueryValue> = {
  value: T
  invert?: boolean
  /** PostgREST query operator */
  operator?: Operator
}

export const BinaryOperators = [
  /** = / equals */
  'eq',
  'gt',
  'gte',
  'lt',
  'lte',
  'neq',
  'like',
  'ilike',
  'match',
  'imatch',
  'is',
  'isdistinct',
  'fts',
  'plfts',
  'phfts',
  'wfts',
] as const
export type BinaryOperator = (typeof BinaryOperators)[number]

export const SetOperators = ['in'] as const
export type SetOperator = (typeof SetOperators)[number]

export const ArrayOperators = [
  'cs',
  'cd',
  'ov',
  'sl',
  'sr',
  'nxr',
  'nxl',
  'adj',
] as const
export type ArrayOperator = (typeof ArrayOperators)[number]

export type Operator = BinaryOperator | SetOperator | ArrayOperator

export type QueryFields = Map<string, QueryValue | QueryField>

export type StringQuery = string
export type ObjectQuery = {
  path: string
  baseUrl?: URL
  fields?: QueryFields
  params?: URLSearchParams
  addTenantId?: boolean
}

export type Query = StringQuery | ObjectQuery

// TODO: There appears to still be an issue with PostgREST where encoding
//       spaces as + rather than %20 causes problems. This was meant to be
//       resolved in https://github.com/PostgREST/postgrest/issues/1348, but
//       perhaps it was only fixed for eq, not for like?
//       We can do string substitution to resolve this, or talk to the
//       PostgREST team about it again.

function queryValueToParam(
  value: QueryValue | QueryField<QueryValue>,
  operator?: Operator,
): string | undefined {
  // array value
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return
    }
    const op = operator ?? 'in'
    if (SetOperators.includes(op as SetOperator)) {
      return `${op}.(${value})`
    } else if (ArrayOperators.includes(operator as ArrayOperator)) {
      return `${op}.{${value}}`
    } else {
      throw new Error(`Invalid operator for array: ${op}`)
    }
    // QueryField value
  } else if (typeof value === 'object') {
    const param = queryValueToParam(value.value, value.operator)
    if (!param) return
    if (value.invert) {
      return `not.${param}`
    }
    return param
    // Scalar value (string or number)
  } else {
    const op = operator ?? 'eq'
    if (BinaryOperators.includes(op as BinaryOperator)) {
      return `${op}.${value}`
    } else {
      throw new Error(`Invalid operator for singleton: ${op}`)
    }
  }
}

export function parseQuery(query: Query, selectedTenant?: Tenant): URL {
  if (typeof query === 'string') {
    return new URL(query, config.apiUrl)
  }

  const baseUrl = query.baseUrl ?? config.apiUrl

  const url = new URL(query.path, baseUrl)

  // Optionally add a `tenant_id` qualifier if requested
  if (query.addTenantId && selectedTenant && !selectedTenant.children.length) {
    url.searchParams.set('tenant_id', `eq.${selectedTenant.id}`)
  }

  if (query.params) {
    query.params.forEach((v, k) => url.searchParams.append(k, v))
  }

  if (query.fields) {
    for (const [k, v] of query.fields) {
      const param = queryValueToParam(v)
      if (param) {
        url.searchParams.append(k, param)
      }
    }
  }
  return url
}

export function useParsedQuery(query: Query | undefined): URL | undefined {
  const selectedTenant = useSelectedTenant()
  return typeof query === 'undefined'
    ? undefined
    : parseQuery(query, selectedTenant)
}

export function getUrlKey(url: URL): string {
  // url.search returns an empty string if no params are set
  return `${url.pathname}${url.search}`
}

type SerialisableQuery = Omit<ObjectQuery, 'fields' | 'params'> & {
  fields?: [string, QueryValue | QueryField][]
  params?: [string, string][]
}

export function serializeQuery(query: Query): string {
  if (typeof query === 'string') {
    return JSON.stringify({ path: query })
  }
  const { fields, params, ...rest } = query
  const serialisableQuery: SerialisableQuery = { ...rest }
  if (fields) {
    serialisableQuery.fields = Array.from(fields.entries())
  }
  if (params) {
    serialisableQuery.params = Array.from(params.entries())
  }

  return JSON.stringify(serialisableQuery)
}

export function deserializeQuery(
  serialisedQuery: string,
): Exclude<Query, string> {
  const queryParsed: SerialisableQuery = JSON.parse(serialisedQuery)
  const { fields, params, ...rest } = queryParsed
  return {
    ...rest,
    params: new URLSearchParams(params),
    fields: new Map(fields),
  }
}
