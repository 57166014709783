import { Temporal } from '@js-temporal/polyfill'
import { format, formatDistanceToNow } from 'date-fns'

import { TimeZone } from 'types/TimeZone'

export type RelativeDay = 'today' | 'yesterday' | 'tomorrow'

export function getClientTimeZone(): TimeZone {
  return Intl.DateTimeFormat().resolvedOptions().timeZone as TimeZone
}

export function getPlainDateAtTzFromRelativeDay(
  relativeDay: RelativeDay,
  timeZone: TimeZone,
): Temporal.PlainDate {
  const today = Temporal.Now.zonedDateTimeISO(timeZone).toPlainDate()
  switch (relativeDay) {
    case 'today':
      return today
    case 'tomorrow':
      return today.add({ days: 1 })
    case 'yesterday':
      return today.subtract({ days: 1 })
  }
}

/** Get a PlainDateTime representing the current time in the specified time zone */
export function getNowAtTz(timeZone: TimeZone): Temporal.PlainDateTime {
  return Temporal.Now.zonedDateTimeISO(timeZone).toPlainDateTime()
}

/** Format a `PlainDateTime` for use with an `<input type="datetime-local" /> */
export function formatDTLString(ts: Temporal.PlainDateTime): string {
  /* If you enter a date before the year 1000 (as might happen if you highlight
     the year field and then start entering numbers), roundtripping through
     Temporal.PlainDateTime will prepend +00 (so will look something like
     +000123-01-01T01:01). Chrome's datetime-local input doesn't understand this
     format, and will reset the input value.

     The spec does suggest that dates in this format should be OK (see e.g.
     https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local#the_y10k_problem_often_client-side),
     but empirically the Chrome format at the time of writing is:
     - no leading plus sign
     - any four digits are fine
     - if more than four digits, no leading zeroes
     - not more than six digits
  */
  return ts
    .toString({ smallestUnit: 'minutes' })
    .replace(/^\+0*(\d{4,6}-)/, '$1')
}

/**
 * Parse the value from an `<input type="datetime-local">` into a
 * `PlainDateTime`.
 */
export function parseDTLString(value: string): Temporal.PlainDateTime {
  /* Although Temporal produces strings like +000123-01-01T01:01 for years
     before 1000, it also accepts the 0123-01-01T01:01 format, so we don't need
     to do any conversion on that. The only conversion necessary is for years
     past 9999 (which are obviously not usually desirable but it's still a
     better UX to handle them). In this case, Temporal requires a + prefix and
     to pad the string to length 6 with zeroes on the left. */
  const temporalStyle = value.replace(
    /^([0-9]{5,})-/,
    (_, p1) => `+${p1.padStart(6, '0')}-`,
  )
  return Temporal.PlainDateTime.from(temporalStyle)
}

type RoundingOptions = Temporal.RoundTo<
  | 'day'
  | 'hour'
  | 'minute'
  | 'second'
  | 'millisecond'
  | 'microsecond'
  | 'nanosecond'
>

export function compareDurationRounded(
  comparison: Temporal.PlainDateTime,
  base: Temporal.PlainDateTime,
  roundingOptions: RoundingOptions = 'nanosecond',
): -1 | 0 | 1 {
  const comparisonRounded = comparison.round(roundingOptions)
  const baseRounded = base.round(roundingOptions)
  return Temporal.PlainDateTime.compare(comparisonRounded, baseRounded)
}

type ComparisonArgs = Parameters<typeof compareDurationRounded>

export function isBefore(...args: ComparisonArgs): boolean {
  return compareDurationRounded(...args) === -1
}

export function isAfter(...args: ComparisonArgs): boolean {
  return compareDurationRounded(...args) === 1
}

export function isSame(...args: ComparisonArgs): boolean {
  return compareDurationRounded(...args) === 0
}

/**
 * Formats a `Date` as a string indicating the distance to the current time
 * (e.g. '<1m ago', '6m ago' etc)
 */
export function shortDistanceToNow(date: Date): string {
  // this function is to modify the regex match modifying 'month' into 'mo' and
  // all other periods shortened into just the first letter.
  function replacer(
    _match: string,
    duration: number,
    period: string,
    periodInitial: string,
  ) {
    const shortPeriod = period.startsWith('month') ? 'mo' : periodInitial
    return `${duration}${shortPeriod}`
  }

  return formatDistanceToNow(date, {
    addSuffix: true,
  })
    .replace(/less than an? /, '<1 ')
    .replace(/about /, '~')
    .replace(/almost /, '~')
    .replace(/over /, '>')
    .replace(/(\d+) ((.)\w+)/, replacer)
}

export const ShortTsPrecisions = [
  'minutes',
  'seconds',
  'tenths',
  'millis',
] as const
export type ShortTsPrecision = (typeof ShortTsPrecisions)[number]
/**
 * Formats a date in a standardised short form.
 *
 * Takes an ISO-8601 date string, epoch timestamp, or a JS Date object. Returns
 * a string of the form `1 Jan 10:00:00`.
 */
export function shortTs(
  ts: string | Date,
  precision: ShortTsPrecision = 'seconds',
): string {
  const baseFormatStr = 'd MMM HH:mm'
  // Patterns are additive, so should account for previous tokens
  const formats: Record<ShortTsPrecision, string> = {
    minutes: '',
    seconds: ':ss',
    tenths: '.S',
    millis: 'SS',
  }

  const formatStr = ShortTsPrecisions.reduce((acc, curr, i) => {
    return ShortTsPrecisions.indexOf(precision) >= i
      ? `${acc}${formats[curr]}`
      : acc
  }, baseFormatStr)

  return format(new Date(ts), formatStr)
}
