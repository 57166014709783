import { HttpResponse } from 'api/FireflyApi'

type PostgrestError = {
  code: string
  details: string | null
  hint: string | null
  message: string
}

export type ResponseFormat = HttpResponse<unknown, PostgrestError>

const defaultErrorMessages: Record<number, string> = {
  404: 'The requested resource could not be found',
}

/**
 * Error messages that are generated by events we don't want to expose to the
 * user (e.g. we got a 404 and there's no JSON content to parse), and that we'd
 * prefer to use a fallback error message for.
 */
const emptyErrorMessages = ['Unexpected end of JSON input']

export default class APIError extends Error {
  constructor(response: Error | ResponseFormat) {
    let message = ''
    if (response instanceof Error) {
      // network error or other custom userland error
      message = response.message
    } else {
      if (
        response.error?.message &&
        !emptyErrorMessages.includes(response.error.message)
      ) {
        message = `Error (${response.status}): ${response.error.message}`
      } else {
        // non-PostgREST error
        // this is most likely to happen if there's not a JSON-compatible response
        // e.g. an empty response body because of a 404
        message =
          defaultErrorMessages[response.status] ??
          `Error status ${response.status}`
      }
    }
    super(message)
    // We need to set a prototype if we extend the native Error class
    Object.setPrototypeOf(this, APIError.prototype)

    this.name = 'APIError'
  }
}
