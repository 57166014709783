import React, { useEffect } from 'react'

import HotKeysWithModal from 'components/HotKeysWithModal'

type HotKeyHandlers = {
  cycleViewMode: (event: KeyboardEvent) => void
}
type MarginLimitsHotKeysProps = {
  cycleViewMode: () => void
}

export const MarginLimitsHotKeys: React.FC<MarginLimitsHotKeysProps> = ({
  cycleViewMode,
  children,
}) => {
  const handlers: HotKeyHandlers = {
    cycleViewMode,
  }

  const keyMap: Record<keyof HotKeyHandlers, string | string[]> = {
    cycleViewMode: 'm',
  }

  useEffect(() => {
    setTimeout(
      () =>
        (
          document.querySelector('#margin-limits-container') as HTMLElement
        ).parentElement?.focus(),
      0,
    )
  }, [])

  return (
    <HotKeysWithModal keyMap={keyMap} handlers={handlers} title="Margin Limits">
      {children}
    </HotKeysWithModal>
  )
}
