import React from 'react'

import groupBy from 'lodash/groupBy'
import { Alert, AlertProps } from 'react-bootstrap'

import { RowStatus } from './types'

export function getBreaches<T extends Record<string, unknown>>(
  rows: T[],
  rowStatus: (row: T) => RowStatus,
): { [k in RowStatus]?: T[] } {
  return groupBy(rows, rowStatus)
}

type BreachAlertProps<T> = {
  /** List of rows that are currently in breach */
  breaches?: T[]
  /** Function that formats each limit in breach into something human-readable */
  formatter: (breach: T) => JSX.Element
  /**
   * Text that should appear before each individual breach.
   *
   * You can use the following formatting tokens:
   *
   * - `%n`: number of limits currently in breach
   * - `%{<singular>|<plural>}`: if the number of breaches is 1, then
   *   `<singular>`, otherwise `<plural>`
   */
  preamble?: string
  /** Bootstrap `<Alert />` variant. Defaults to `danger` */
  variant?: AlertProps['variant']
  /** Unique key in row object */
  keyBy: keyof T
}

export function BreachAlert<T>({
  breaches,
  formatter,
  variant = 'danger',
  preamble = '%n limit%{|s} currently in breach',
  keyBy,
}: BreachAlertProps<T>): JSX.Element {
  if (!breaches?.length) {
    return <></>
  }

  const formattedPreamble = preamble
    .replace(/%n/g, `${breaches.length}`)
    // replace tokens of the form ${<singular>|<plural>} with <singular> if
    // there is one breach, or <plural> if there is more than one breach
    .replace(/%\{([^}]*)\|([^}]*)\}/g, breaches.length > 1 ? '$2' : '$1')

  return (
    <Alert variant={variant}>
      {formattedPreamble}:{' '}
      {breaches.map((breach, i) => (
        <React.Fragment key={`${breach[keyBy]}`}>
          {i > 0 && <>, </>}
          {formatter(breach)}
        </React.Fragment>
      ))}
    </Alert>
  )
}

export const NoBreachAlert: React.FC = () => (
  <Alert variant="success">No limits currently in breach</Alert>
)
