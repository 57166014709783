import React from 'react'

import {
  AutocompleteSingle,
  AutocompleteSingleProps,
} from 'components/Autocomplete'
import { useAssets } from 'contexts/Assets'
import { Asset } from 'types/Asset'

// Top 50 coins on coinmarketcap.com as at 2022-10-17, ordered by market cap
const bigCoins = [
  'BTC',
  'ETH',
  'USDT',
  'USDC',
  'BNB',
  'XRP',
  'BUSD',
  'ADA',
  'SOL',
  'DOGE',
  'MATIC',
  'DOT',
  'DAI',
  'TRX',
  'SHIB',
  'WBTC',
  'AVAX',
  'UNI',
  'LEO',
  'LTC',
  'LINK',
  'ATOM',
  'FTT',
  'ETC',
  'XLM',
  'CRO',
  'XMR',
  'QNT',
  'NEAR',
  'ALGO',
  'BCH',
  'VET',
  'LUNC',
  'FLOW',
  'FIL',
  'TON',
  'HBAR',
  'APE',
  'ICP',
  'EGLD',
  'XTZ',
  'MANA',
  'SAND',
  'HT',
  'CHZ',
  'AAVE',
  'EOS',
  'MKR',
  'THETA',
  'OKB',
].map(a => a.toLowerCase())

/**
 * Substring filtering with sorting biased by market cap, full-word match, and
 * start-of-string match
 */
export function filterItems<A extends Asset>(
  inputValue: string,
  items: A[],
): A[] {
  const scored: [A, number][] = items.map(asset => {
    const tickerLower = asset.ticker.toLowerCase()
    const inputLower = inputValue.toLowerCase()
    let score = 0
    if (tickerLower === inputLower) {
      // make sure that exact matches always sort above big coins
      score = 40
    } else if (
      bigCoins.includes(tickerLower) &&
      tickerLower.startsWith(inputLower)
    ) {
      // ... then prioritise big coins
      score =
        30 - bigCoins.findIndex(coin => coin === tickerLower) / bigCoins.length
    } else if (tickerLower.startsWith(inputLower)) {
      // ... then prioritise matches at the start of the ticker
      score = 20 + inputLower.length / tickerLower.length
    } else if (tickerLower.includes(inputLower)) {
      // ... finally prioritise substring matches
      score = 10 + inputLower.length / tickerLower.length
    }
    return [asset, score]
  })
  return (
    scored
      .filter(([, score]) => score > 0)
      // Assets are pre-sorted by ticker, so matches with the same score will
      // be sorted alphabetically
      .sort(([, aScore], [, bScore]) => bScore - aScore)
      .map(([asset]) => asset)
  )
}

type PropsToOmit =
  | 'items'
  | 'keyBy'
  | 'filterItems'
  | 'formatItem'
  | 'searchKey'

export type AssetAutocompleteProps = Omit<
  AutocompleteSingleProps<Asset>,
  PropsToOmit
> & {
  assetClasses?: Asset['asset_class'][]
  assets?: Asset[]
  filter?: (asset: Asset) => boolean
}

export function AssetAutocomplete({
  assetClasses,
  assets,
  filter,
  ...props
}: AssetAutocompleteProps): JSX.Element {
  const allAssets = useAssets()
  let assetsInUse = assets ?? allAssets
  if (assetClasses) {
    assetsInUse = assetsInUse.filter(({ asset_class }) =>
      assetClasses.includes(asset_class),
    )
  }
  if (filter) {
    assetsInUse = assetsInUse.filter(filter)
  }

  return (
    <AutocompleteSingle
      items={assetsInUse}
      filterItems={filterItems}
      keyBy="id"
      formatItem={item => item.ticker}
      {...props}
    />
  )
}
