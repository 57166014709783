import React, { useEffect, useState } from 'react'

import { Button, ButtonProps } from 'react-bootstrap'

type Props = Omit<ButtonProps, 'onClick' | 'variant'> & {
  variant?: 'warning' | 'danger'
  onClick: NonNullable<ButtonProps['onClick']>
}

export default ({
  variant = 'danger',
  ...props
}: React.PropsWithChildren<Props>): React.ReactElement => {
  const [clickedOnce, setClicked] = useState(false)

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (clickedOnce) {
      setClicked(false)
      props.onClick(event)
    } else {
      setClicked(true)
    }
  }

  // Get rid of the active state after some timeout
  useEffect(() => {
    if (clickedOnce) {
      const t = setTimeout(() => {
        setClicked(false)
      }, 5000)
      return () => clearTimeout(t)
    }
  }, [clickedOnce])

  return (
    <Button
      variant={clickedOnce ? variant : `outline-${variant}`}
      {...props}
      onClick={handleClick}
      onBlur={event => {
        setClicked(false)
        if (props.onBlur) {
          props.onBlur(event)
        }
      }}
    >
      {props.children}
    </Button>
  )
}
