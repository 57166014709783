export function toSignificantFigures(n: Big, precision: number): string {
  if (n.eq(0)) {
    return n.toString()
  }
  // Replicates the logic of the round_sigfig() database function
  return (
    n
      .toFixed(
        Math.max(0, precision - Math.floor(Math.log10(n.abs().toNumber())) - 1),
      )
      // Strip trailing zeroes after the decimal point, effectively
      // 'trim_scale()'
      .replace(/(\.)(\d*?)0+$/, '$1$2')
      .replace(/\.$/, '')
  )
}
