import React, { useContext, useMemo } from 'react'

import { usePollApi } from 'hooks/usePollApi'
import { Market } from 'types/Market'

type MarketsContextType = {
  markets: Market[]
  marketsById: MarketsById
  activeMarkets: Market[]
}
const MarketsContext = React.createContext<MarketsContextType>({
  markets: [],
  marketsById: {},
  activeMarkets: [],
})

function assignName(rawMarket: Omit<Market, 'name'>): Market {
  const market: Market = { ...rawMarket, name: '' }
  market.name = `${market.exchange} ${market.symbol}`
  return market
}

const marketsQuery = {
  path: 'markets',
  params: new URLSearchParams({ select: '*,symbol' }),
}

type MarketsById = { [k: number]: Market }

export function MarketsProvider({
  children,
}: {
  children: React.ReactNode
}): React.ReactElement {
  // We see lots of warnings about this endpoint, because /markets contains a
  // lot of data that can take time to parse. We should speed it up, but in the
  // meantime, having stale markets usually isn't an issue and we shouldn't warn
  // about it.
  const subscriptionCheckThreshold = Infinity
  const marketsRaw = usePollApi<Market>(
    marketsQuery,
    60000,
    subscriptionCheckThreshold,
  )

  const markets = useMemo(() => {
    return marketsRaw?.map(assignName).sort((a, b) => {
      const volSort = (b.volume ?? 0) - (a.volume ?? 0)
      return volSort !== 0 ? volSort : a.name.localeCompare(b.name)
    })
  }, [marketsRaw])

  const activeMarkets = useMemo(
    () => markets?.filter(m => !m.disabled && !m.expired),
    [markets],
  )

  const marketsById = useMemo<MarketsById>(
    () =>
      markets
        ? Object.fromEntries(markets.map(m => [m.id, assignName(m)]))
        : {},
    [markets],
  )

  if (!markets || !activeMarkets || !marketsById) {
    return <>Loading markets...</>
  }

  return (
    <MarketsContext.Provider value={{ markets, activeMarkets, marketsById }}>
      {children}
    </MarketsContext.Provider>
  )
}

export function useMarket(id: number | undefined): Market | undefined {
  const markets = useMarketsById()
  if (!id) return undefined
  const market = markets[id]
  return market
}

export function useMarketByName(
  exchange: string,
  asset: string,
  currency: string | undefined,
): Market | undefined {
  const markets = useMarkets()
  const sym = currency ? `${asset}/${currency}` : asset
  return markets.find(m => m.exchange === exchange && m.symbol === sym)
}

export const useMarkets = (): Market[] => useContext(MarketsContext).markets
export const useMarketsById = (): MarketsById =>
  useContext(MarketsContext).marketsById
export const useActiveMarkets = (): Market[] =>
  useContext(MarketsContext).activeMarkets
