import React, { useEffect } from 'react'

import { configureScope } from '@sentry/browser'
import config from 'config'

import { useTailnetUser } from 'contexts/TailnetUser'
import { useCurrentUserId } from 'hooks/useCurrentUserId'

export const AddSentryUser: React.FC = ({ children }) => {
  const user = useTailnetUser()
  const currentUserId = useCurrentUserId()

  useEffect(() => {
    if (config.reportToSentry && user.username && currentUserId) {
      configureScope(scope => {
        scope.setUser({
          id: currentUserId.toString(),
          username: user.username,
        })
      })
    }
  }, [currentUserId, user.username])

  return <>{children}</>
}
