import { Temporal } from '@js-temporal/polyfill'

import { TimeZone } from 'types/TimeZone'
import { isBefore } from 'utils/datesAndTimes'

/**
 * Tuple of [`start`, `end`], where `start` and `end` are either ISO 8601
 * timestamp strings, or `undefined`
 */
export type ISODateStringRange = [string | undefined, string | undefined]
/**
 * Tuple of [`start`, `end`], where `start` and `end` are ISO 8601 timestamp
 * strings.
 */
export type BoundedISODateStringRange = [string, string]

export type PlainDateTimeRangeValidationCriteria = {
  /** Does the range have both a `start` and `end` date */
  bounded?: boolean
  /** Does the `start` date precede the `end` date */
  wellOrdered?: boolean
}

/**
 * Object for containing a date range, with checks to ensure that it's valid
 *
 * The lower and upper bounds (`start` and `end`) can be `undefined`, which
 * indicates unboundedness in that direction.
 *
 * The object is immutable, and setter methods return a new `DateRange`
 * instance.
 */
export class PlainDateTimeRange {
  public start: Temporal.PlainDateTime | undefined
  public end: Temporal.PlainDateTime | undefined

  constructor(
    start: Temporal.PlainDateTime | undefined,
    end: Temporal.PlainDateTime | undefined,
  ) {
    this.start = start
    this.end = end
  }

  /** Return a new `PlainDateTimeRange` instance with the specified `start` date. */
  public withStart = (
    newStart: Temporal.PlainDateTime | undefined,
  ): PlainDateTimeRange => {
    return new PlainDateTimeRange(newStart, this.end)
  }
  /** Return a new `PlainDateTimeRange` instance with the specified `end` date */
  public withEnd = (
    newEnd: Temporal.PlainDateTime | undefined,
  ): PlainDateTimeRange => {
    return new PlainDateTimeRange(this.start, newEnd)
  }

  public getDuration = (timeZone: TimeZone): Temporal.Duration | undefined =>
    this.start && this.end
      ? this.end
          .toZonedDateTime(timeZone)
          .since(this.start.toZonedDateTime(timeZone))
      : undefined

  /**
   * Returns the range as an `ISODateStringRange`, casting to an instant at the
   * specified `timeZone`.
   */
  public getDateRange = (timeZone: TimeZone): ISODateStringRange => {
    const start = this.start
      ? this.start.toZonedDateTime(timeZone).toInstant().toString()
      : undefined
    const end = this.end
      ? this.end.toZonedDateTime(timeZone).toInstant().toString()
      : undefined

    return [start, end]
  }

  /**
   * Returns a `boolean` indicating whether the range is well-ordered (i.e. that
   * `start` precedes `end). Unbounded ranges (where one or both of
   * `start`or`end` is not set) are always treated as valid
   */
  public isWellOrdered = (): boolean => {
    if (!this.start || !this.end) {
      return true
    }
    return isBefore(this.start, this.end)
  }

  /**
   * Returns a `boolean` indicating whether the range is bounded (i.e. that both
   * `start` and `end` are set)
   */
  public isBounded = (): boolean => {
    if (!this.start || !this.end) {
      return false
    }
    return true
  }

  public getBounded = (): BoundedPlainDateTimeRange | undefined => {
    if (!this.start || !this.end) {
      return
    }
    return new BoundedPlainDateTimeRange(this.start, this.end)
  }

  /**
   * Returns a `boolean` indicating whether the range is valid for the given
   * validation criteria.
   *
   * By default checks that the range is both bounded and well-ordered.
   */
  public isValid = (
    criteria: PlainDateTimeRangeValidationCriteria = {
      bounded: true,
      wellOrdered: true,
    },
  ): boolean => {
    const { bounded, wellOrdered } = criteria
    const checkBounded = bounded ? this.isBounded() : true
    const checkWellOrdered = wellOrdered ? this.isWellOrdered() : true

    return checkBounded && checkWellOrdered
  }

  /** Returns a `boolean` indicating if this range is equal to the supplied range */
  public equals = (
    testRange: PlainDateTimeRange,
    thisTz: TimeZone,
    testTz: TimeZone,
  ): boolean => {
    const [thisStart, thisEnd] = this.getDateRange(thisTz)
    const [testStart, testEnd] = testRange.getDateRange(testTz)
    return thisStart === testStart && thisEnd === testEnd
  }
}

/**
 * Object for containing a bounded date range. Structurally and behaviourally
 * similar to the `PlainDateTimeRange` class, but lower and upper bounds
 * (`start` and `end`) are guaranteed to be defined.
 *
 * The object is immutable, and setter methods return a new `BoundedDateRange`
 * instance.
 */
export class BoundedPlainDateTimeRange {
  public start: Temporal.PlainDateTime
  public end: Temporal.PlainDateTime

  constructor(start: Temporal.PlainDateTime, end: Temporal.PlainDateTime) {
    this.start = start
    this.end = end
  }

  /**
   * Return a new `BoundedPlainDateTimeRange` instance with the specified
   * `start` date.
   */
  public withStart = (
    newStart: Temporal.PlainDateTime,
  ): BoundedPlainDateTimeRange => {
    return new BoundedPlainDateTimeRange(newStart, this.end)
  }
  /**
   * Return a new `BoundedPlainDateTimeRange` instance with the specified `end`
   * date
   */
  public withEnd = (
    newEnd: Temporal.PlainDateTime,
  ): BoundedPlainDateTimeRange => {
    return new BoundedPlainDateTimeRange(this.start, newEnd)
  }

  public getDuration = (timeZone: TimeZone): Temporal.Duration =>
    this.end
      .toZonedDateTime(timeZone)
      .since(this.start.toZonedDateTime(timeZone))

  /**
   * Returns the range as a `BoundedISODateStringRange`, casting to an instant
   * at the specified `timeZone`.
   */
  public getDateRange = (timeZone: TimeZone): BoundedISODateStringRange => {
    const start = this.start.toZonedDateTime(timeZone).toInstant().toString()
    const end = this.end.toZonedDateTime(timeZone).toInstant().toString()
    return [start, end]
  }

  /**
   * Returns a `boolean` indicating whether the range is well-ordered (i.e. that
   * `start` precedes `end). Unbounded ranges (where one or both of
   * `start`or`end` is not set) are always treated as valid
   */
  public isWellOrdered = (): boolean => {
    return isBefore(this.start, this.end)
  }
}
