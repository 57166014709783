import React from 'react'

import { Col, Container, Row } from 'react-bootstrap'
import { useLocalStorage } from 'react-use'

import ModeSelector from 'components/ModeSelector'
import { ReportExportButton } from 'components/ReportExport'

import { LimitsDashboardHook, RowStatus, maxRowStatus } from '../types'

import { MarginLimitBreaches } from './Breaches'
import { MarginLimitsHotKeys } from './HotKeys'
import { MarginLimitsTable } from './Table'
import { useMarginLimitsUsage } from './query'
import { rowStatus } from './rows'
import { ViewMode, ViewModes } from './useColumns'

export const MarginLimits: React.FC = () => {
  const rows = useMarginLimitsUsage() ?? []
  const [viewModeRaw, setViewMode] = useLocalStorage<ViewMode>(
    'firefly:margin_limits:viewMode',
    'excess',
  )

  const viewMode = viewModeRaw || 'excess'

  function cycleViewMode() {
    const currentModeIndex = ViewModes.findIndex(m => m === viewMode)
    const nextModeIndex = (currentModeIndex + 1) % ViewModes.length
    const nextMode = ViewModes[nextModeIndex]
    setViewMode(nextMode)
  }

  return (
    <>
      <MarginLimitsHotKeys cycleViewMode={cycleViewMode}>
        <div id="margin-limits-container">
          <Container>
            <MarginLimitBreaches rows={rows} />
            <Row style={{ flexDirection: 'row-reverse', marginBottom: '1rem' }}>
              <Col md="auto">
                <ModeSelector
                  modes={[...ViewModes]}
                  setMode={setViewMode}
                  selectedMode={viewMode}
                />
              </Col>
              <ReportExportButton
                title="Margin Limits"
                rpcName="margin_limits_report"
              />
            </Row>
            <MarginLimitsTable rows={rows} viewMode={viewMode} />
          </Container>
        </div>
      </MarginLimitsHotKeys>
    </>
  )
}

const DashboardStatuses: RowStatus[] = [
  'unknown',
  'data-error',
  'urgent-breach',
  'breach',
  'near-breach',
]

export const useMarginLimitsDashboard: LimitsDashboardHook = () => {
  const marginUsage = useMarginLimitsUsage()

  if (!marginUsage) {
    return { loaded: false }
  }

  const rows = marginUsage.filter(row =>
    DashboardStatuses.includes(rowStatus(row)),
  )

  const Component = rows.length ? (
    <MarginLimitsTable rows={rows} viewMode="excess" />
  ) : undefined

  const status = maxRowStatus(rows, rowStatus)

  return {
    loaded: true,
    Component,
    status,
  }
}
