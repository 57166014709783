import { EditableRow } from 'components/StyledBoostrapTable/EditableRow'
import { TenantId } from 'contexts/Tenants'
import { Quantity } from 'types/Quantity'

export type DeltaLimit = {
  tenant_id: TenantId
  asset_id: number | null
  delta_limit_lower: number
  delta_limit_upper: number
  note: string | null
  updated_at: string
  updated_by_id: bigint
  // added via resource embedding
  asset: {
    ticker: string
  } | null
}

export type DeltaLimitRow = DeltaLimit & { id: string }

export type DeltaLimitUsage = {
  tenant_id: TenantId
  asset_id: number
  asset: string
  delta_limit_asset_id: string | null
  target_delta: Quantity
  delta: number
  delta_to_target: number
  delta_to_target_btc: number
  delta_btc: number
  delta_limit_lower: number | null
  delta_limit_midpoint: number | null
  delta_limit_upper: number | null
  note: string | null
  aum_btc: number
  delta_ratio: number | null
  delta_to_target_ratio: number | null
  delta_limit_proportion: number | null
  delta_limit_aum_proportion: number | null
  delta_from_closest_limit: number | null
  delta_from_limit_midpoint: number | null
  breach: boolean
  updated_at: string | null
  updated_by_id: bigint | null
  updated_by_name: string | null
}

export type DeltaLimitUsageRow = DeltaLimitUsage & {
  id: string
}

export type EditableLimitRow =
  | EditableRow<DeltaLimitUsageRow>
  | EditableRow<DeltaLimitRow>

export function isDeltaLimitUsage(
  d: DeltaLimit | DeltaLimitUsage,
): d is DeltaLimitUsage {
  return 'delta_limit_asset_id' in d
}

export const ViewModes = ['view', 'edit'] as const
export type ViewMode = (typeof ViewModes)[number]
