import { RowStatus } from '../types'

import { Row, TableDatum, isUncollateralizedPosition } from './types'

export const rowStatus = (row: Row | TableDatum): RowStatus => {
  if (isUncollateralizedPosition(row)) {
    return 'data-error'
  }
  if (row.lower_limit_long === null) {
    // limit not defined
    return 'unknown'
  }
  if (row.exposure === null) {
    // no exposure
    if (row.balance === null || row.balance.quantity === 0) {
      // empty wallet, this is fine
      return 'normal'
    } else {
      // collaterialising nothing, counts as overcollateralised
      return 'info'
    }
  }
  if (
    row.worst_margin_limit_proportion === null ||
    row.worst_margin_limit_proportion < 1
  ) {
    return 'urgent-breach'
  }
  if (
    row.upper_limit_long &&
    row.upper_limit_short &&
    row.balance &&
    row.balance.quantity > 0 &&
    (row.margin_ratio_long === null ||
      row.margin_ratio_long > row.upper_limit_long) &&
    (row.margin_ratio_short === null ||
      row.margin_ratio_short > row.upper_limit_short)
  ) {
    return 'info'
  }
  return 'normal'
}
