import React, { useCallback } from 'react'

import Big from 'big.js'
import { isEqual } from 'lodash'

import { useAsset } from 'contexts/Assets'
import { CurrencyOption } from 'contexts/CurrencySelection'
import { useSelectedCurrency } from 'contexts/SelectedCurrency'
import useConversion from 'hooks/useConversion'
import { Asset, AssetId } from 'types/Asset'

import { toSignificantFigures } from './helpers'

export type ConvertedPrecision =
  | number
  | { [currency in Exclude<CurrencyOption, 'Native'>]?: number }

export type QuantityProps = {
  quantity: number | string | Big | null | undefined
  // If we don't know the currency, we can still display correctly in Native
  // mode at least
  currency: AssetId | undefined
  nativeFormatter?: (
    val: Big,
    currency: Asset | undefined,
    precision?: number,
  ) => string
  precision?: number
  nativeSelectedCurrency?: Exclude<CurrencyOption, 'Native'>
  /**
   * What precision should converted quantities use. If not specified (either
   * for all conversions, or for a particular currency), defaults to
   * DEFAULT_PRECISION.
   */
  convertedPrecision?: ConvertedPrecision
  multiplier?: number
  significantFigures?: boolean
}

const DEFAULT_PRECISION = 8

export const localeFormatter = (
  val: Big,
  currency: Asset | undefined,
  precision?: number,
  significantFigures: boolean = false,
): string => {
  const currencyTicker = currency?.ticker || 'XXX'
  const useLocaleForCurrency =
    // toLocaleString appears to be hostile to currencies that are not three
    // letters. Probably all fiat assets will have length 3 tickers anyway,
    // but that's not guaranteed
    currency?.asset_class === 'fiat' && currencyTicker.length === 3
  const currencyOptions: Partial<Intl.NumberFormatOptions> =
    useLocaleForCurrency
      ? {
          style: 'currency',
          currency: currencyTicker,
        }
      : {}
  const precisionOptions: Partial<Intl.NumberFormatOptions> =
    precision !== undefined
      ? { minimumFractionDigits: precision, maximumFractionDigits: precision }
      : {}
  const sigFigOptions: Partial<Intl.NumberFormatOptions> =
    precision !== undefined && significantFigures
      ? {
          minimumSignificantDigits: precision,
          maximumSignificantDigits: precision,
        }
      : {}
  // en-GB represents USD as US$ rather than just $, so we use en-US
  const localeString = val.toNumber().toLocaleString('en-US', {
    ...currencyOptions,
    ...precisionOptions,
    ...sigFigOptions,
  })
  if (useLocaleForCurrency) {
    return localeString
  } else {
    return `${localeString} ${currencyTicker}`
  }
}

const Quantity: React.FC<QuantityProps> = ({
  quantity: rawQuantity,
  currency: currencyId,
  nativeFormatter,
  precision: rawPrecision = DEFAULT_PRECISION,
  nativeSelectedCurrency,
  convertedPrecision,
  multiplier = 1,
  significantFigures = false,
}) => {
  const selectedCurrencyRaw = useSelectedCurrency()
  const selectedCurrency =
    selectedCurrencyRaw === 'Native' && nativeSelectedCurrency
      ? nativeSelectedCurrency
      : selectedCurrencyRaw

  const currency = useAsset(currencyId)

  const contractSize = currency?.contract_size?.quantity ?? 1
  const isNative =
    selectedCurrency === 'Native' ||
    (currency !== undefined &&
      (selectedCurrency === currency.ticker ||
        selectedCurrency === currency.contract_size?.ticker) &&
      multiplier === 1 &&
      contractSize === 1)

  const precision = Number.isSafeInteger(rawPrecision)
    ? Math.max(rawPrecision, 0)
    : DEFAULT_PRECISION

  const defaultNativeFormatter = useCallback(
    (value: Big) => {
      if (significantFigures) {
        return toSignificantFigures(value, precision)
      }
      return value.toFixed(precision)
    },
    [precision, significantFigures],
  )

  // Handle cases that `Big` would crash on
  if (typeof rawQuantity === 'undefined' || rawQuantity === null) {
    return <></>
  }
  switch (+rawQuantity) {
    case Infinity:
      return <>&infin;</>
    case -Infinity:
      return <>-&infin;</>
    default:
      if (Number.isNaN(+rawQuantity)) {
        return <>NaN</>
      }
  }
  const quantity = Big(rawQuantity)

  if (isNative) {
    return (
      <NativeQuantity
        format={nativeFormatter ?? defaultNativeFormatter}
        quantity={quantity}
        currency={currency}
        precision={precision}
      />
    )
  } else {
    return (
      <ConvertedQuantity
        quantity={quantity}
        currency={currency}
        selectedCurrency={selectedCurrency}
        multiplier={multiplier}
        nativePrecision={precision}
        convertedPrecision={convertedPrecision}
        significantFigures={significantFigures}
      />
    )
  }
}

export default React.memo(Quantity, (a, b) => {
  return (
    a.quantity === b.quantity &&
    isEqual(a.currency, b.currency) &&
    a.nativeFormatter === b.nativeFormatter &&
    a.precision === b.precision &&
    a.multiplier === b.multiplier
  )
})

type NativeQuantityProps = {
  format: (val: Big, currency: Asset | undefined, precision: number) => string
  quantity: Big
  currency: Asset | undefined
  precision: number
}
const NativeQuantityBase: React.FC<NativeQuantityProps> = ({
  format,
  quantity,
  currency,
  precision,
}) => {
  return <>{format(quantity, currency, precision)}</>
}
const NativeQuantity = React.memo(
  NativeQuantityBase,
  (a, b) =>
    a.format === b.format &&
    a.quantity === b.quantity &&
    isEqual(a.currency, b.currency) &&
    a.precision === b.precision,
)

type ConvertedQuantityProps = {
  quantity: Big
  currency: Asset | undefined
  selectedCurrency: Exclude<CurrencyOption, 'Native'>
  multiplier: number
  nativePrecision: number
  convertedPrecision: ConvertedPrecision | undefined
  significantFigures: boolean
}

const ConvertedQuantityBase: React.FC<ConvertedQuantityProps> = props => {
  const conversion = useConversion(props.currency, props.selectedCurrency)

  if (!conversion) {
    return <>Loading...</>
  }

  const converted = props.quantity.times(conversion).times(props.multiplier)

  const requestedPrecision =
    typeof props.convertedPrecision === 'number'
      ? props.convertedPrecision
      : typeof props.convertedPrecision === 'object'
        ? props.convertedPrecision[props.selectedCurrency]
        : undefined

  const precision =
    typeof requestedPrecision === 'number'
      ? requestedPrecision
      : Number.isInteger(conversion * props.multiplier)
        ? props.nativePrecision
        : DEFAULT_PRECISION

  if (props.significantFigures) {
    return <>{toSignificantFigures(converted, precision)}</>
  }
  return <>{converted.toFixed(precision)}</>
}
const ConvertedQuantity = React.memo(ConvertedQuantityBase)
