import React from 'react'

import PropTypes from 'prop-types'
import { FormControl } from 'react-bootstrap'
import { UseFormMethods } from 'react-hook-form'

import { AssetAutocompleteUncontrolled } from 'components/Autocomplete/Asset/AssetAutocompleteUncontrolled'
import Quantity, { localeFormatter } from 'components/Quantity'
import { Quantity as QuantityType } from 'types/Quantity'

import { EditRowButtons, FlexButtonWrapper } from '../EditableTableFields'

import useActions from './actions'
import { TargetDelta } from './types'

export function assetField(
  control: UseFormMethods['control'],
  existingAssets: number[],
): JSX.Element {
  return (
    <AssetAutocompleteUncontrolled
      control={control}
      inputProps={{ name: 'asset_id', required: true, autoFocus: true }}
      assetClasses={['crypto', 'fiat']}
      filter={a => !existingAssets.includes(a.id)}
    />
  )
}

export function deltaField(
  register?: UseFormMethods['register'],
  delta?: QuantityType,
): JSX.Element {
  return register ? (
    <FormControl
      id="targetDeltaFormDelta"
      name="delta"
      type="number"
      step="any"
      style={{ width: '100%' }}
      required
      ref={register}
      defaultValue={delta?.quantity}
    />
  ) : delta ? (
    <Quantity
      quantity={delta.quantity}
      currency={delta.asset_id}
      nativeFormatter={localeFormatter}
      precision={4}
    />
  ) : (
    <></>
  )
}

export function buttonsField(
  isEditing: boolean,
  setEditing: () => void,
  row: TargetDelta,
): JSX.Element {
  return <Buttons isEditing={isEditing} setEditing={setEditing} row={row} />
}

function Buttons({
  isEditing,
  setEditing,
  row,
}: {
  isEditing: boolean
  setEditing: () => void
  row: TargetDelta
}) {
  const { destroy } = useActions()

  const setEditingAndFocus = () => {
    setEditing()
    setTimeout(
      () =>
        document
          .querySelector<HTMLInputElement>('#targetDeltaFormDelta')
          ?.select(),
      0,
    )
  }
  return (
    <FlexButtonWrapper>
      <EditRowButtons
        isEditing={isEditing}
        onSetEditing={setEditingAndFocus}
        onDestroy={() => destroy(row)}
        canDestroy={!!row.asset_id}
        size="sm"
      />
    </FlexButtonWrapper>
  )
}

Buttons.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  setEditing: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
}
