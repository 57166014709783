import React, { useCallback, useEffect, useState } from 'react'

import { Temporal } from '@js-temporal/polyfill'
import { Form, FormControlProps, InputGroup } from 'react-bootstrap'

import HotkeysWithModal, { HotKeyHandlerMap } from 'components/HotKeysWithModal'
import { useForwardedRef } from 'hooks/useForwardedRef'
import { TimeZone } from 'types/TimeZone'
import {
  RelativeDay,
  formatDTLString,
  getClientTimeZone,
  getPlainDateAtTzFromRelativeDay,
  parseDTLString,
} from 'utils/datesAndTimes'

const hotKeyMap = {
  setToday: 't',
  setYesterday: 'y',
  setTomorrow: 'T',
  setMidnight: 'z',
  setMidday: 'Z',
  add1Hour: 'h',
  subtract1Hour: 'H',
  add1Day: 'd',
  subtract1Day: 'D',
  add1Week: 'w',
  subtract1Week: 'W',
  add1Month: 'm',
  subtract1Month: 'M',
  // datetime-local fields don't blur on Escape key press by default, which can
  // be annoying if your field is autofocused, but you want to navigate to
  // another page using a hotkey
  blurField: 'esc',
}

type PlainDateTime = Temporal.PlainDateTime
export type DateTimePickerValue = PlainDateTime | undefined

type DateTimePickerPropsBase = Omit<
  FormControlProps,
  'type' | 'onChange' | 'defaultValue' | 'value'
> &
  Omit<
    React.HTMLProps<HTMLInputElement>,
    keyof FormControlProps | 'max' | 'min'
  > & {
    /** Callback called when date value changes */
    onChange: (date: DateTimePickerValue) => void
    /**
     * Denote which time zone is displayed at the end of the input if
     * `showTimeZone` is `true`. Also used for calculating periods when using
     * the hotkeys. Defaults to the client time zone.
     */
    timeZone?: TimeZone
    /** Show the picker time zone at the end of the input */
    showTimeZone?: boolean
    /** Current value of the date */
    value: DateTimePickerValue
    /** The minimum date/time value that can be selected in the picker */
    min?: Temporal.PlainDateTime
    /** The maximum date/time value that can be selected in the picker */
    max?: Temporal.PlainDateTime
  }

type DateTimePickerProps = DateTimePickerPropsBase &
  (
    | {
        hotKeyMap: Record<string, string | string[]>
        hotKeyHandlers: Record<string, (event: KeyboardEvent) => void>
      }
    | {
        hotKeyHandlers?: undefined
        hotKeyMap?: undefined
      }
  ) & { hotKeyTitle?: string }

/**
 * Timezone-aware date picker component.
 *
 * Returns a date/time value at the specified `timeZone`.
 */
export const DateTimePicker = React.forwardRef<
  HTMLInputElement,
  DateTimePickerProps
>((props, ref) => {
  const {
    onChange,
    value,
    timeZone = getClientTimeZone(),
    showTimeZone = true,
    hotKeyMap: suppliedHotKeyMap,
    hotKeyHandlers: suppliedHotKeyHandlers,
    hotKeyTitle: suppliedHotKeyTitle,
    max,
    min,
    ...fcProps
  } = props
  const inputRef = useForwardedRef(ref)
  // date value formatted for the benefit of the date picker element
  const dateAsString = value ? formatDTLString(value) : ''

  /* Store some props in local state to control when they are updated.

     The datetime-local input has internal state that stores partial progress
     entering a value -- it will only return full valid dates to the JS API, but
     internally knows when a user has entered e.g. a month but not a year. As
     far as I know, this state is totally inaccessible to JS. While the input is
     in an invalid state, the value property just contains the empty string.
     Moreover, if you assign to the value attribute of the element from JS, the
     internal state is dropped. In particular, `input.value = input.value` on a
     partially-entered date field is not a no-op: it will clear the partial
     entry, and your user will be sad.

     The only way we can preserve this partial entry information for the user is
     by ensuring that while the input is in a partial entry state:

     - the input element is not recreated by React,
     - the value property is not assigned,

     Setting the min and max props seem to cause the first of these to happen,
     which actually causes problems even if the input is valid, so we only
     update them in an onBlur handler. Meanwhile, we make sure to only set the
     value property when there's a valid date, or when the date first becomes
     invalid. For reasons I don't fully understand, this seems to work, while
     all the sensible things I tried with useMemo to ensure the element was
     preserved did not seem to work. Sorry :( */
  const [stableValue, setStableValue] = useState<string>(dateAsString)
  const [stableMin, setStableMin] = useState<
    Temporal.PlainDateTime | undefined
  >(min)
  const [stableMax, setStableMax] = useState<
    Temporal.PlainDateTime | undefined
  >(max)

  const syncExtremes = useCallback(() => {
    setStableMin(min)
    setStableMax(max)
  }, [max, min])

  useEffect(() => {
    if (dateAsString !== '') {
      setStableValue(dateAsString)
    }
  }, [dateAsString])

  function handleDateTimeChange(event: React.ChangeEvent<HTMLInputElement>) {
    const dateStr = event.target.value
    /* It might be surprising that this is OK here, but in fact it's necessary.
       If we don't let stableValue become an empty string, the input refuses to
       enter an invalid state, so you can't delete any of the fields. Note that
       we don't get any change events while the user is updating the field from
       one invalid state to another, so we won't continue to update the stable
       value while the input is invalid. */
    setStableValue(dateStr)
    if (dateStr !== '') {
      onChange(parseDTLString(dateStr))
    } else {
      onChange(undefined)
    }
  }

  function makeSetRelativeDay(relativeDay: RelativeDay) {
    return function (event: KeyboardEvent) {
      event.preventDefault()
      const date = getPlainDateAtTzFromRelativeDay(relativeDay, timeZone)
      onChange(date.toPlainDateTime(value))
    }
  }

  function makeSetTime(time: Temporal.PlainTime) {
    return function (event: KeyboardEvent) {
      event.preventDefault()
      if (!value) {
        return
      }
      onChange(value.withPlainTime(time))
    }
  }

  function makeSetPeriod(
    operation: 'add' | 'subtract',
    period: Temporal.DurationLike,
  ) {
    return function (event: KeyboardEvent) {
      event.preventDefault()
      if (!value) {
        return
      }

      const newValue = setPeriodBase(value, operation, period, timeZone)
      onChange(newValue)
    }
  }

  const hotKeyHandlers: HotKeyHandlerMap<typeof hotKeyMap> = {
    // relative day handlers
    setToday: makeSetRelativeDay('today'),
    setYesterday: makeSetRelativeDay('yesterday'),
    setTomorrow: makeSetRelativeDay('tomorrow'),
    // relative time handlers
    setMidnight: makeSetTime(Temporal.PlainTime.from('00:00')),
    setMidday: makeSetTime(Temporal.PlainTime.from('12:00')),
    // arithmetic handlers
    add1Hour: makeSetPeriod('add', { hours: 1 }),
    subtract1Hour: makeSetPeriod('subtract', { hours: 1 }),
    add1Day: makeSetPeriod('add', { days: 1 }),
    subtract1Day: makeSetPeriod('subtract', { days: 1 }),
    add1Week: makeSetPeriod('add', { weeks: 1 }),
    subtract1Week: makeSetPeriod('subtract', { weeks: 1 }),
    add1Month: makeSetPeriod('add', { months: 1 }),
    subtract1Month: makeSetPeriod('subtract', { months: 1 }),
    // focus management
    blurField: () => {
      inputRef.current?.blur()
    },
  }

  return (
    <HotkeysWithModal
      title={suppliedHotKeyTitle ?? 'Date/Time Picker'}
      keyMap={{ ...hotKeyMap, ...suppliedHotKeyMap }}
      handlers={{ ...hotKeyHandlers, ...suppliedHotKeyHandlers }}
    >
      <InputGroup>
        <Form.Control
          type="datetime-local"
          ref={inputRef}
          onChange={handleDateTimeChange}
          onBlur={syncExtremes}
          value={stableValue}
          min={stableMin && formatDTLString(stableMin)}
          max={stableMax && formatDTLString(stableMax)}
          {...fcProps}
        />
        {showTimeZone && (
          <InputGroup.Append>
            <InputGroup.Text>{timeZone}</InputGroup.Text>
          </InputGroup.Append>
        )}
      </InputGroup>
    </HotkeysWithModal>
  )
})

// This logic is separated out from the `makeSetPeriod` function so we can run
// unit tests on the internal logic outside of the component
export function setPeriodBase(
  value: PlainDateTime,
  operation: 'add' | 'subtract',
  period: Temporal.DurationLike,
  timeZone?: TimeZone,
): Temporal.PlainDateTime {
  return timeZone
    ? value.toZonedDateTime(timeZone)[operation](period).toPlainDateTime()
    : value[operation](period)
}
