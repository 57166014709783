import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'

import Quantity from 'components/Quantity'
import Tenant from 'components/Tenant'
import { useAsset } from 'contexts/Assets'
import useSelectedTenant, { useIsGlobalTenant } from 'contexts/SelectedTenant'
import { getTenantColor } from 'contexts/TenantSelection'
import { TenantId } from 'contexts/Tenants'
import { usePollApi } from 'hooks/usePollApi'

export const CurrentNAVs: React.FC = () => {
  const isGlobalTenant = useIsGlobalTenant()
  const selectedTenant = useSelectedTenant()
  const btc = useAsset('BTC')
  const exchangeBalanceTotals = usePollApi(
    {
      path: 'exchange_balances',
      params: new URLSearchParams({
        select: 'tenant_id,balance',
        exchange: 'eq.total',
        // PSP, BTC+, USD+
        tenant_id: 'in.(2,3,4)',
      }),
    },
    5000,
  )

  if (!btc) {
    return <>Loading...</>
  }

  const navs: { [k in TenantId]?: number } = mapValues(
    keyBy(exchangeBalanceTotals, 'tenant_id'),
    'balance',
  )

  return (
    <ul className="list-inline" style={{ marginBottom: 0 }}>
      <li className="list-inline-item">
        <strong>NAV:</strong>
      </li>
      {Object.entries(navs)
        .filter(
          ([tenantId]) =>
            isGlobalTenant || (+tenantId as TenantId) === selectedTenant.id,
        )
        .map(([tenantId, nav]) => (
          <li
            key={tenantId}
            className="list-inline-item"
            style={{
              paddingLeft: '0.5rem',
              marginRight: '1rem',
              borderLeft: isGlobalTenant
                ? `4px solid ${getTenantColor(+tenantId as TenantId)}`
                : undefined,
            }}
          >
            {isGlobalTenant && (
              <>
                <Tenant id={+tenantId as TenantId} />:{' '}
              </>
            )}
            {Number.isFinite(nav) ? (
              <Quantity
                quantity={nav}
                currency={btc.id}
                precision={1}
                convertedPrecision={1}
              />
            ) : (
              '-'
            )}
          </li>
        ))}
    </ul>
  )
}
