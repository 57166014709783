import { useEffect, useState } from 'react'

import mapValues from 'lodash/mapValues'
import { UseFormMethods } from 'react-hook-form'

import { LIMIT_FIELD_PRECISION } from './precision'
import { FormValues, MarginLimitKey } from './types'

export type LimitFieldsDirty = Record<MarginLimitKey, boolean>

export const limitFieldsDirtyDefaults: LimitFieldsDirty = {
  lower_limit_short: false,
  upper_limit_short: false,
  lower_limit_long: false,
  upper_limit_long: false,
}

export const limitFieldsAllDirty = mapValues(
  limitFieldsDirtyDefaults,
  _ => true,
)

type UseLimitsFieldWatcherProps = {
  watch: UseFormMethods<FormValues>['watch']
  setValue: UseFormMethods<FormValues>['setValue']
}
export function useLimitsFieldWatcher({
  watch,
  setValue,
}: UseLimitsFieldWatcherProps): React.Dispatch<
  React.SetStateAction<LimitFieldsDirty>
> {
  const [limitFieldsDirty, setLimitFieldsDirty] = useState<LimitFieldsDirty>(
    limitFieldsDirtyDefaults,
  )

  const lls = watch('lower_limit_short')
  const lll = watch('lower_limit_long')

  useEffect(() => {
    // short limits
    if (!limitFieldsDirty.upper_limit_short) {
      const llsVal = +lls
      setValue(
        'upper_limit_short',
        llsVal > 0 ? +(llsVal * 1.25).toFixed(LIMIT_FIELD_PRECISION) : '',
      )
    }
    // long limits
    if (!limitFieldsDirty.upper_limit_long) {
      const lllVal = +lll
      setValue(
        'upper_limit_long',
        lllVal > 0 ? +(lllVal * 1.25).toFixed(LIMIT_FIELD_PRECISION) : '',
      )
    }
  }, [
    limitFieldsDirty.upper_limit_long,
    limitFieldsDirty.upper_limit_short,
    lll,
    lls,
    setValue,
  ])

  return setLimitFieldsDirty
}
