import React from 'react'

import Asset from 'components/Asset'
import Tenant from 'components/Tenant'

import { BreachAlert, NoBreachAlert, getBreaches } from '../BreachAlert'

import { addIdToRow, rowStatus } from './rows'
import { DeltaLimitUsage } from './types'

export const breachFormatter = (row: DeltaLimitUsage): JSX.Element => (
  <span>
    <Tenant id={row.tenant_id} />/<Asset asset={row.asset_id} link={false} />
  </span>
)

type DeltaLimitBreachesProps = {
  rows: DeltaLimitUsage[]
  showNoBreaches?: boolean
}

export const DeltaLimitBreaches: React.FC<DeltaLimitBreachesProps> = ({
  rows: rowsRaw,
  showNoBreaches,
}) => {
  const rows = rowsRaw.map(addIdToRow)
  const {
    unknown,
    'urgent-breach': urgentBreach,
    'data-error': dataError,
  } = getBreaches(rows, rowStatus)
  if ([unknown, urgentBreach].every(l => !l?.length) && showNoBreaches) {
    return <NoBreachAlert />
  }

  return (
    <>
      <BreachAlert
        breaches={dataError}
        formatter={breachFormatter}
        keyBy="id"
        variant="warning"
        preamble="%n asset%{|s} %{does|do} not have a defined limit"
      />
      <BreachAlert
        breaches={unknown}
        formatter={breachFormatter}
        keyBy="id"
        variant="warning"
        preamble="Delta ratio%{|s} could not be calculated for %n asset%{|s} (fair%{|s} may be out of date)"
      />
      <BreachAlert
        breaches={urgentBreach}
        formatter={breachFormatter}
        keyBy="id"
      />
    </>
  )
}
