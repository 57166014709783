import React from 'react'

import 'bootstrap/dist/css/bootstrap.css'
import config from 'config'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import ReactDOM from 'react-dom'

import App from './App'
import './animations.css'
import './index.css'

// import * as serviceWorker from './serviceWorker';

if (config.reportToSentry) {
  import('@sentry/browser').then(Sentry => {
    Sentry.init({
      dsn: 'https://e200881b776f45e8a99553d69faf03aa@sentry.io/1814758',
      environment: config.environment,
      release: import.meta.env.VITE_VERSION,
    })
  })
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
