export default <T>(options: T[]) =>
  (current: T, shift: number): T => {
    const i = options.indexOf(current)

    // The javascript % operator is remainder, not modulus
    const l = options.length
    const j = (((i + shift) % l) + l) % l

    return options[j]
  }
