import React from 'react'

import AutocompleteBase from './base'
import { AutocompleteItem, AutocompletePropsBase } from './types'

export type AutocompleteSingleProps<T extends AutocompleteItem> =
  AutocompletePropsBase<T> & {
    onSelect: (item: T | null) => void
    selectedItem: T | null
  }

export function AutocompleteSingle<T extends AutocompleteItem>({
  ...props
}: AutocompleteSingleProps<T>): JSX.Element {
  return <AutocompleteBase isMultiple={false} {...props} />
}
