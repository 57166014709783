import React, { useCallback, useEffect, useState } from 'react'

import { Control, Controller, ControllerRenderProps } from 'react-hook-form'
import { usePrevious } from 'react-use'

import { useAsset } from 'contexts/Assets'
import { Asset } from 'types/Asset'

import { AssetAutocomplete, AssetAutocompleteProps } from './AssetAutocomplete'

type AssetAutocompleteUncontrolledProps = Omit<
  AssetAutocompleteProps,
  'onSelect' | 'selectedItem'
> & {
  inputProps: Omit<AssetAutocompleteProps['inputProps'], 'name'> & {
    name: string
  }
  control: Control
  dataField?: 'id' | 'ticker'
}

type AssetAutocompleteUncontrolledInnerProps = ControllerRenderProps & {
  autocompleteProps: Omit<AssetAutocompleteUncontrolledProps, 'control'>
}

const AssetAutocompleteUncontrolledInner = React.forwardRef<
  HTMLInputElement,
  AssetAutocompleteUncontrolledInnerProps
>(({ onChange, onBlur, autocompleteProps: props, value }, ref) => {
  const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null)
  const { dataField = 'id' } = props
  // JSON stringify means we can do string comparisons in effect dependencies
  const impliedAsset = JSON.stringify(
    useAsset(dataField === 'id' ? +value : value) ?? null,
  )
  const prevImpliedAsset = usePrevious(impliedAsset)

  const handleSelect = useCallback(
    (asset: Asset | null) => {
      setSelectedAsset(asset)
      onChange(asset?.[dataField] ?? '')
    },
    [dataField, onChange, setSelectedAsset],
  )

  // Load in a default value if there is one
  useEffect(() => {
    if (impliedAsset !== prevImpliedAsset) {
      handleSelect(JSON.parse(impliedAsset))
    }
  }, [handleSelect, impliedAsset, prevImpliedAsset, selectedAsset])

  return (
    <AssetAutocomplete
      {...props}
      onSelect={handleSelect}
      selectedItem={selectedAsset}
      inputProps={{ ...props.inputProps, onBlur, ref }}
    />
  )
})

export const AssetAutocompleteUncontrolled: React.FC<
  AssetAutocompleteUncontrolledProps
> = ({ control, ...props }) => {
  return (
    <Controller
      control={control}
      name={props.inputProps.name}
      rules={{ required: props.inputProps.required }}
      defaultValue={props.inputProps.defaultValue}
      render={controlProps => (
        <AssetAutocompleteUncontrolledInner
          autocompleteProps={props}
          {...controlProps}
        />
      )}
    />
  )
}
