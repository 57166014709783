/**
 * Component for exporting time-based reports.
 *
 * As currently written, the component expects a report with the following
 * properties:
 *
 * - Is an RPC (i.e. is defined as a function not a view)
 * - Takes `first_logged_at` and `last_logged_at` parameters to define the time
 *   range that it covers
 * - Has an optional `tenant_id` parameter for filtering to a specific tenant
 */
import React, { useState } from 'react'

import { Temporal } from '@js-temporal/polyfill'
import { formatDuration } from 'date-fns'
import { Button, Col, Form, OverlayTrigger, Popover } from 'react-bootstrap'

import {
  DateTimeRangePicker,
  PlainDateTimeRange,
} from 'components/DateTimeRangePicker'
import { UseExportButtonProps, useExportButton } from 'components/ExportButton'
import useSelectedTenant from 'contexts/SelectedTenant'
import { Tenant } from 'contexts/Tenants'
import { ObjectQuery } from 'hooks/usePollApi'
import { getPlainDateAtTzFromRelativeDay } from 'utils/datesAndTimes'

const timeZone = 'UTC'
const MAX_DURATION = Temporal.Duration.from({ months: 3 })

type ReportParamsProps = {
  tenant: Tenant
  range: PlainDateTimeRange
  filenameBase: string
  rpcName: string
  params?: URLSearchParams
}

type ReportExportProps = {
  title: string
  filenameBase?: string
  /**
   * The name of the RPC call that exports the report. E.g. if the report is
   * accessible at `/rpc/margin_limits_report`, this prop should be set to
   * `margin_limits_report`
   */
  rpcName: string
  params?: URLSearchParams
}

function reportParams({
  tenant,
  range,
  filenameBase,
  rpcName,
  params: inputParams,
}: ReportParamsProps): UseExportButtonProps {
  const params = new URLSearchParams(inputParams)
  const rangeBounded = range.getBounded()

  // Rules-of-hooks mean we need to return something here for use in
  // useExportButton, and this is cleaner than modifying useExportButton to
  // support not supplying props. This ensures that the button will be disabled
  // in these cases
  if (!rangeBounded) {
    return {
      query: { path: '' },
      filename: '',
      buttonProps: { disabled: true },
    }
  }
  const startTs = rangeBounded.start
    .toZonedDateTime(timeZone)
    .toInstant()
    .toString({
      smallestUnit: 'minutes',
    })
  const endTs = rangeBounded.end
    .toZonedDateTime(timeZone)
    .toInstant()
    .toString({
      smallestUnit: 'minutes',
    })

  const globalTenant = tenant.children.length
  const filename = `${filenameBase}_report_${
    !globalTenant ? `${tenant.name}_` : ''
  }${startTs}--${endTs}`

  // Note the condensed report is an RPC, not a view, so the way we pass these
  // is a little different (no eq.)
  if (!globalTenant) {
    params.append('tenant_id', tenant.id.toString())
  }
  params.append('first_logged_at', startTs)
  params.append('last_logged_at', endTs)

  const query: ObjectQuery = {
    path: `/rpc/${rpcName}`,
    params,
  }

  return { query, filename }
}

const ReportExportForm: React.FC<ReportExportProps> = ({
  title,
  filenameBase,
  ...rest
}) => {
  const defaultValues = new PlainDateTimeRange(
    getPlainDateAtTzFromRelativeDay('today', timeZone)
      .toPlainDateTime('00:00')
      .subtract({ weeks: 1 }),
    getPlainDateAtTzFromRelativeDay('today', timeZone).toPlainDateTime('00:00'),
  )

  const tenant = useSelectedTenant()

  const [range, setRange] = useState<PlainDateTimeRange>(defaultValues)

  const duration = range.getDuration(timeZone)
  const maxDurationExceeded =
    range.start && duration
      ? Temporal.Duration.compare(duration, MAX_DURATION, {
          relativeTo: range.start,
        }) > 0
      : false

  const disabled = !range.isValid() || maxDurationExceeded
  const { ExportButton, handleSubmit } = useExportButton(
    reportParams({
      tenant,
      range,
      filenameBase: filenameBase ?? title.toLowerCase().replace(/\s/g, '_'),
      ...rest,
    }),
  )

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Form.Group as={Col} md="auto">
          <DateTimeRangePicker
            range={range}
            onChange={setRange}
            timeZone={timeZone}
            startRequired
            endRequired
            autoFocus
          />
          {maxDurationExceeded && (
            <Form.Text className="text-danger">
              Maximum duration is {formatDuration(MAX_DURATION)}
            </Form.Text>
          )}
          {!range.isValid() && (
            <Form.Text className="text-danger">Invalid date range</Form.Text>
          )}
        </Form.Group>
        <Form.Group as={Col} md="auto">
          <div style={{ marginTop: '1.75rem' }}>
            <ExportButton disabled={disabled} />
          </div>
        </Form.Group>
      </Form.Row>
    </Form>
  )
}

export const ReportExportButton: React.FC<ReportExportProps> = props => {
  return (
    <>
      <OverlayTrigger
        trigger="click"
        overlay={
          <Popover
            id={`export-${props.title.toLowerCase()}-report-popover`}
            style={{ maxWidth: 770 }}
          >
            <Popover.Title as="h4">Export {props.title} Report</Popover.Title>
            <Popover.Content>
              <ReportExportForm {...props} />
            </Popover.Content>
          </Popover>
        }
        placement="auto"
        rootClose
      >
        <Button>Export…</Button>
      </OverlayTrigger>
    </>
  )
}
