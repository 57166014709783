import React from 'react'

import { Container } from 'react-bootstrap'

import { Glyphicon } from 'components/BootstrapShim'
import { CurrentNAVs } from 'components/CurrentNAVs'
import TabbedNavigation from 'components/TabbedNavigation'
import { NavRoute } from 'components/TabbedNavigation'

import {
  CapitalConcentrationLimits,
  useCapitalConcentrationLimitsDashboard,
} from './CapitalConcentration'
import { LimitsDashboard } from './Dashboard'
import { DeltaLimits, useDeltaLimitsDashboard } from './Delta'
import { MarginLimits, useMarginLimitsDashboard } from './Margin'
import { LimitsDashboardHook, RowStatus } from './types'

export type LimitNavRoute = NavRoute & {
  useDashboardComponent: LimitsDashboardHook
}

// It's important for the loop at the beginning of the LimitsIndex component
// below that this list is created once and is never modified, otherwise we'll
// violate rules-of-hooks
export const LimitRoutes: readonly LimitNavRoute[] = [
  {
    slug: 'margin',
    label: 'Margin',
    component: MarginLimits,
    useDashboardComponent: useMarginLimitsDashboard,
    hotkey: 'm',
  },
  {
    slug: 'capital-concentration',
    label: 'Capital Concentration',
    component: CapitalConcentrationLimits,
    useDashboardComponent: useCapitalConcentrationLimitsDashboard,
    hotkey: 'c',
  },
  {
    slug: 'delta',
    label: 'Delta',
    component: DeltaLimits,
    useDashboardComponent: useDeltaLimitsDashboard,
    hotkey: 'd',
  },
] as const

const TabStatusMarker: React.FC<{ glyph: string; severity: string }> = ({
  glyph,
  severity,
}) => {
  return (
    <span className={`text-${severity}`}>
      <Glyphicon glyph={glyph} />
    </span>
  )
}

function labelSuffixFromStatus(
  status?: RowStatus,
): React.ReactNode | undefined {
  switch (status) {
    case 'normal':
    case 'info':
    case 'near-breach':
      return <TabStatusMarker severity="success" glyph="check-circle" />
    case 'breach':
      return <TabStatusMarker severity="warning" glyph="exclamation-triangle" />
    case 'urgent-breach':
    case 'data-error':
    case 'unknown':
      return <TabStatusMarker severity="danger" glyph="times" />
    default:
      return <TabStatusMarker severity="secondary" glyph="spinner" />
  }
}

const LimitsIndex: React.FC = () => {
  const LimitRoutesWithDashboard: NavRoute[] = [
    {
      slug: 'dashboard',
      label: 'Dashboard',
      component: LimitsDashboard,
      hotkey: 'h',
    },
  ]
  // Calling the hook in a loop like this works fine, and is deterministic, but
  // it relies on us never changing the LimitRoutes array at runtime. There's
  // not really any reason for us to do this, but flagging in case some future
  // use-case makes us want to dynamically modify the order/length/members of
  // the array.
  for (const route of LimitRoutes) {
    const res = route.useDashboardComponent()
    const labelSuffix = labelSuffixFromStatus(
      res.loaded ? res.status : undefined,
    )
    LimitRoutesWithDashboard.push({
      ...route,
      labelSuffix,
    })
  }

  return (
    <>
      <Container>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h1>Limits</h1>

          <CurrentNAVs />
        </div>
      </Container>
      <TabbedNavigation routes={LimitRoutesWithDashboard} />
    </>
  )
}

export default LimitsIndex
