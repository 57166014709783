import React, { useState } from 'react'

import {
  Button,
  Col,
  Container,
  Form,
  FormLabel,
  InputGroup,
  Row,
} from 'react-bootstrap'

import { Glyphicon } from 'components/BootstrapShim'
import ModeSelector from 'components/ModeSelector'
import { ReportExportButton } from 'components/ReportExport'
import TargetDeltas from 'components/TargetDeltas'
import useCheckbox from 'hooks/useCheckbox'

import { LimitsDashboardHook, RowStatus, maxRowStatus } from '../types'

import { DeltaLimitBreaches } from './Breaches'
import { NewDeltaLimit } from './New'
import { DeltaLimitsTable } from './Table'
import { USD_EQUIVALENTS, useDeltaLimitsUsage } from './query'
import { rowStatus } from './rows'
import { ViewMode, ViewModes } from './types'

const DEFAULT_DELTA_BTC_THRESHOLD = 2e-3

const REPORT_PARAMS = new URLSearchParams({
  estimated_duration: 'gte.00:15:00',
})

export const DeltaLimits: React.FC = () => {
  const [deltaBtcThreshold, setDeltaBtcThreshold] = useState<number | ''>(
    DEFAULT_DELTA_BTC_THRESHOLD,
  )
  const [excludeInverseUpnlCheckbox, excludeInverseUpnl] = useCheckbox(true)
  const [excludeUsdEquivalentsCheckbox, excludeUsdEquivalents] =
    useCheckbox(true)

  const params = new URLSearchParams()

  // We need to apply these criteria to our filter params, because we don't want
  // breaching rows (or rows without a correctly-set delta) to be filtered out,
  // even if the filters are applied. So, we include them along with the filter
  // criteria inside OR blocks. PostgREST can support multiple OR blocks (which
  // is why we use params.append() instead of .set()), which are combined with
  // AND. So, if both filters are applied, it looks like:
  // (outside threshold OR breach) AND (not USD(x) OR breach)
  const defaultOrFilterParams = 'delta_ratio.is.null,breach.is.true'

  if (
    typeof deltaBtcThreshold === 'number' &&
    Number.isFinite(deltaBtcThreshold) &&
    deltaBtcThreshold !== 0
  ) {
    params.append(
      'or',
      `(delta_btc.gte.${deltaBtcThreshold},delta_btc.lte.${
        deltaBtcThreshold * -1
      },${defaultOrFilterParams})`,
    )
  }
  if (excludeUsdEquivalents) {
    params.append(
      'or',
      `(asset.not.in.(${USD_EQUIVALENTS.join(',')}),${defaultOrFilterParams})`,
    )
  }
  const deltaUsage = useDeltaLimitsUsage(params, excludeInverseUpnl)

  const [viewMode, setViewMode] = useState<ViewMode>('view')

  if (!deltaUsage) {
    return <>Loading...</>
  }

  return (
    <Container>
      <DeltaLimitBreaches rows={deltaUsage} />
      <Row style={{ marginBottom: '1rem', alignItems: 'center' }}>
        <Col xs="auto" className={'ml-auto'}>
          <Row className="justify-content-end">
            <Col md="auto" style={{ textAlign: 'right' }}>
              <FormLabel
                style={{
                  marginBottom: 0,
                  marginLeft: '0.5rem',
                  whiteSpace: 'nowrap',
                }}
              >
                Exclude USD(X): {excludeUsdEquivalentsCheckbox}
              </FormLabel>
            </Col>

            <Col md="auto" style={{ textAlign: 'right' }}>
              <FormLabel
                style={{
                  marginBottom: 0,
                  marginLeft: '0.5rem',
                  whiteSpace: 'nowrap',
                }}
              >
                Exclude inv. UPnL: {excludeInverseUpnlCheckbox}
              </FormLabel>
            </Col>
          </Row>
        </Col>
        <Col lg="auto">
          <Form.Group
            style={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}
          >
            <FormLabel
              style={{
                marginBottom: 0,
                marginLeft: '0.5rem',
                whiteSpace: 'nowrap',
              }}
            >
              Min BTC 𝚫:
            </FormLabel>
            <InputGroup>
              <Form.Control
                value={deltaBtcThreshold}
                onChange={e =>
                  setDeltaBtcThreshold(
                    e.target.value === '' ? '' : Math.abs(+e.target.value),
                  )
                }
                type="number"
                step="0.001"
                min="0"
                style={{ width: '7rem' }}
              />
              <InputGroup.Append>
                <Button
                  variant="secondary"
                  onClick={() =>
                    setDeltaBtcThreshold(DEFAULT_DELTA_BTC_THRESHOLD)
                  }
                  disabled={deltaBtcThreshold === DEFAULT_DELTA_BTC_THRESHOLD}
                >
                  <Glyphicon glyph="refresh" />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col lg="auto">
          <ModeSelector
            modes={[...ViewModes]}
            selectedMode={viewMode}
            setMode={setViewMode}
          />
        </Col>
        <Col lg="auto">
          <ReportExportButton
            title="Delta Limits"
            rpcName="delta_limits_report"
            params={REPORT_PARAMS}
          />
        </Col>
      </Row>
      <DeltaLimitsTable rows={deltaUsage} viewMode={viewMode} />
      {viewMode === 'edit' && (
        <Row>
          <Col md="auto">
            <NewDeltaLimit />
          </Col>
        </Row>
      )}
      <div style={{ marginTop: 60 }}>
        <TargetDeltas />
      </div>
    </Container>
  )
}

const DashboardStatuses: RowStatus[] = [
  'unknown',
  'urgent-breach',
  'breach',
  'near-breach',
]

export const useDeltaLimitsDashboard: LimitsDashboardHook = () => {
  const params = new URLSearchParams({
    or: `(delta_btc.gte.${DEFAULT_DELTA_BTC_THRESHOLD},delta_btc.lte.-${DEFAULT_DELTA_BTC_THRESHOLD},delta_ratio.is.null,breach.is.true)`,
  })
  const deltaUsage = useDeltaLimitsUsage(params, true)

  if (!deltaUsage) {
    return { loaded: false }
  }

  const rows = deltaUsage.filter(row =>
    DashboardStatuses.includes(rowStatus(row)),
  )

  const Component = rows.length ? (
    <DeltaLimitsTable rows={rows} viewMode="view" />
  ) : undefined
  const maxStatus = maxRowStatus(rows, rowStatus)

  return {
    loaded: true,
    status: maxStatus,
    Component,
  }
}
