import React from 'react'

import Asset from 'components/Asset'
import Exchange from 'components/Exchange'
import Tenant from 'components/Tenant'

import { BreachAlert, NoBreachAlert, getBreaches } from '../BreachAlert'

import { rowStatus } from './rows'
import { MarginLimitUsage, TableDatum, UncollateralizedPosition } from './types'

export const breachFormatter = (row: MarginLimitUsage): JSX.Element => (
  <span>
    <Tenant id={row.tenant_id} />/<Exchange id={row.exchange_id} />/
    {row.wallet_name}/{row.wallet_type}
  </span>
)

export const uncollateralizedPositionFormatter = (
  row: UncollateralizedPositionWithKey,
): JSX.Element => (
  <span>
    <Tenant id={row.tenant_id} />/<Exchange id={row.exchange_id} />/
    <Asset asset={{ id: row.asset_id }} link={false} />
  </span>
)

type MarginLimitBreachesProps = {
  rows: TableDatum[]
  showNoBreaches?: boolean
}

type UncollateralizedPositionWithKey = UncollateralizedPosition & {
  key: string
}

export const MarginLimitBreaches: React.FC<MarginLimitBreachesProps> = ({
  rows,
  showNoBreaches,
}) => {
  // We're specifically not getting 'info'/overcollateralized rows here because
  // traders don't really need to be notified of them as aggressively
  const {
    unknown,
    'urgent-breach': urgentBreach,
    'data-error': dataError,
  } = getBreaches(rows, rowStatus)
  if ([unknown, urgentBreach].every(l => !l?.length) && showNoBreaches) {
    return <NoBreachAlert />
  }

  const dataErrorWithKey: UncollateralizedPositionWithKey[] | undefined = (
    dataError as UncollateralizedPosition[] | undefined
  )?.map(row => ({
    ...row,
    key: `${row.tenant_id}-${row.exchange_id}-${row.asset_id}`,
  }))

  return (
    <>
      <BreachAlert
        breaches={unknown as MarginLimitUsage[]}
        formatter={breachFormatter}
        keyBy="wallet_id"
        variant="warning"
        preamble="%n wallet%{|s} %{does|do} not have a defined limit"
      />
      <BreachAlert
        breaches={dataErrorWithKey}
        formatter={uncollateralizedPositionFormatter}
        keyBy="key"
        variant="warning"
        preamble="%n asset%{|s} %{has|have} a position, but %{does|do} not have a corresponding collateral wallet"
      />
      <BreachAlert
        breaches={urgentBreach as MarginLimitUsage[]}
        formatter={breachFormatter}
        keyBy="wallet_id"
      />
    </>
  )
}
