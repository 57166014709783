import React from 'react'

import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonProps,
} from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'

type ModeSelectorProps<T extends string> = {
  modes: T[]
  selectedMode: T
  setMode: (mode: T) => void
  formatMode?: (mode: T) => React.ReactNode
  buttonProps?: Partial<ToggleButtonProps>
}

export default function ModeSelector<T extends string>(
  props: ModeSelectorProps<T>,
): React.ReactElement {
  const {
    selectedMode,
    modes,
    setMode,
    formatMode = mode => mode,
    buttonProps,
  } = props

  return (
    <ToggleButtonGroup
      type="radio"
      name="mode"
      onChange={setMode}
      value={selectedMode}
    >
      {modes.map(mode => (
        <ToggleButton variant="light" key={mode} value={mode} {...buttonProps}>
          {formatMode(mode)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

type Control = ReturnType<typeof useForm>['control']
type UncontrolledProps<T extends string> = {
  modes: T[]
  formatMode?: (mode: T) => React.ReactNode
  name: string
  control: Control
  defaultValue: T
}

function Uncontrolled<T extends string>({
  modes,
  formatMode = mode => mode,
  name,
  control,
  defaultValue,
}: UncontrolledProps<T>): React.ReactElement {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ ref, onChange, onBlur, name, value }) => (
        <ToggleButtonGroup
          type="radio"
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
        >
          {modes.map(mode => (
            <ToggleButton
              type="radio"
              variant="light"
              key={mode}
              name={name}
              value={mode}
              inputRef={ref}
            >
              {formatMode(mode)}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    />
  )
}

ModeSelector.Uncontrolled = Uncontrolled
