import React, { useMemo, useState } from 'react'

import keyBy from 'lodash/keyBy'
import { Form } from 'react-bootstrap'
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next'
import { useForm } from 'react-hook-form'

import Tenant from 'components/Tenant'
import { usePollApi } from 'hooks/usePollApi'
import { shortDistanceToNow } from 'utils/datesAndTimes'
import { isExistent } from 'utils/guards'

import Asset from '../Asset'

import New from './New'
import useActions from './actions'
import { buttonsField, deltaField } from './fields'
import { TargetDelta } from './types'

function assetField(row: TargetDelta): JSX.Element {
  return row.asset_id ? <Asset asset={{ id: row.asset_id }} /> : <>{row.name}</>
}

type FormValues = {
  delta: number
}

function TargetDeltas() {
  const targets: Array<TargetDelta> =
    usePollApi<TargetDelta>({
      path: 'target_deltas',
      addTenantId: true,
      params: new URLSearchParams({
        select: '*,users(name)',
        order: 'asset_id.asc.nullsfirst',
      }),
    }) ?? []
  const [isEditing, setEditing] = useState<number | false>(false)

  const { update } = useActions()

  const { register, handleSubmit } = useForm<FormValues>()

  const onSubmit = async (values: FormValues) => {
    if (typeof isEditing !== 'number') {
      return
    }
    const targetsKeyed = keyBy(targets, 'id')
    const target = targetsKeyed[isEditing]
    await update({
      id: isEditing,
      delta: { quantity: +values.delta, asset_id: target.asset_id },
    })
    setEditing(false)
  }

  const columns: ColumnDescription<TargetDelta>[] = useMemo(
    () => [
      {
        dataField: 'asset',
        isDummyField: true,
        text: 'Asset',
        formatter: (_, row) => assetField(row),
      },
      {
        dataField: 'tenant_id',
        text: 'Tenant',
        headerStyle: { width: '120px' },
        formatter: id => <Tenant id={id} />,
      },
      {
        dataField: 'delta',
        text: 'Target Delta',
        headerStyle: { width: '150px' },
        align: 'right',
        formatter: (delta, row) =>
          deltaField(isEditing === row.id ? register : undefined, delta),
      },
      {
        dataField: 'updated',
        isDummyField: true,
        text: 'Last updated',
        headerStyle: { width: '200px' },
        formatter: (_, row) => {
          const ts = new Date(row.updated_at as string)
          const ago = shortDistanceToNow(ts)
          return `${ago} by ${row.users.name}`
        },
      },
      {
        dataField: 'buttons',
        isDummyField: true,
        text: '',
        headerStyle: { width: '75px' },
        style: { padding: 0, height: 0 },
        formatter: (_, row) =>
          buttonsField(
            isEditing === row.id,
            () => setEditing(row.id as number),
            row,
          ),
      },
    ],
    [isEditing, register],
  )

  return (
    <div style={{ width: '690px' }}>
      <h3>Target Deltas</h3>
      <Form
        inline
        onReset={() => setEditing(false)}
        onSubmit={handleSubmit(onSubmit)}
      >
        <BootstrapTable
          bootstrap4
          condensed
          striped
          key={typeof isEditing === 'number' ? isEditing : ''}
          keyField="id"
          columns={columns}
          data={targets}
        />
      </Form>
      <New existingAssets={targets.map(t => t.asset_id).filter(isExistent)} />
    </div>
  )
}
export default React.memo(TargetDeltas)

TargetDeltas.propTypes = {}
TargetDeltas.defaultProps = {}
