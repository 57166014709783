import React from 'react'

import { Exchange as T, useExchange, useExchanges } from 'contexts/Exchanges'

type ExchangeId = number

// In general trying to replicate exchange brand colours here, but if there are
// collisions (e.g. Binance/Bybit) then it's whatever the traders ask for
const EXCHANGE_COLORS: Record<string, React.CSSProperties> = {
  binance: { backgroundColor: '#F0B90B' },
  okex: { backgroundColor: '#000', color: '#FFF' },
  bybit: { backgroundColor: '#ba2511', color: '#FFF' },
}

type ExchangeProps = {
  id: ExchangeId
  colored?: boolean
}

const Exchange: React.FC<ExchangeProps> = ({ id, colored = false }) => {
  const exchange: T | null = useExchange(id)

  if (exchange) {
    return (
      <span
        style={
          colored && EXCHANGE_COLORS[exchange.name]
            ? {
                padding: '0 2px',
                color: '#000',
                ...EXCHANGE_COLORS[exchange.name],
              }
            : undefined
        }
      >
        {exchange.name}
      </span>
    )
  } else if (id) {
    throw new Error(`Could not identify exchange ${id}`)
  } else {
    return <span>Loading...</span>
  }
}

export default Exchange

export const ExchangeOptions: React.FC = () => {
  const exchanges = useExchanges()
  const exchangeOptions = exchanges
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(exchange => (
      <option key={exchange.id} value={exchange.id}>
        {exchange.name}
      </option>
    ))

  return <>{exchangeOptions}</>
}
