import React, { useState } from 'react'

import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import StyledBootstrapTable from 'components/StyledBoostrapTable'
import { EditableRow } from 'components/StyledBoostrapTable/EditableRow'

import { useActions } from './actions'
import { rowStatus } from './rows'
import { CapitalConcentrationLimitRow } from './types'
import { FormValues } from './types'
import { useColumns } from './useColumns'

const rowClasses = (row: CapitalConcentrationLimitRow): string => {
  const status = rowStatus(row)
  switch (status) {
    case 'unknown':
    case 'near-breach':
      return 'text-notice'
    case 'breach':
      return 'text-warning'
    case 'urgent-breach':
      return 'text-danger'
    default:
      return ''
  }
}

type CapitalConcentrationLimitRowEditable =
  EditableRow<CapitalConcentrationLimitRow>

export const CapitalConcentrationLimitsTable: React.FC<{
  rows: CapitalConcentrationLimitRow[]
  dashboard?: boolean
}> = ({ rows, dashboard = false }) => {
  const [editingRow, setEditingRow] = useState<CapitalConcentrationLimitRow>()
  const { register, handleSubmit } = useForm<FormValues>()
  const { update } = useActions()
  const columns = useColumns({
    register,
    showButtons: !dashboard,
  })

  async function onSubmit(values: FormValues) {
    await update(values)
    setEditingRow(undefined)
  }

  const data: CapitalConcentrationLimitRowEditable[] = rows.map(row => ({
    ...row,
    isEditing: row.id === editingRow?.id,
    setEditing: () => setEditingRow(row),
  }))

  return (
    <Form
      onReset={() => setEditingRow(undefined)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <StyledBootstrapTable
        bootstrap4
        keyField="id"
        columns={columns}
        data={data}
        rowClasses={rowClasses}
        condensed
        striped
        defaultSorted={[
          { dataField: 'concentration_proportion', order: 'desc' },
        ]}
      ></StyledBootstrapTable>
    </Form>
  )
}
