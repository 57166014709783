import * as React from 'react'

import { ButtonToolbar, ToggleButton, ToggleButtonGroup } from 'react-bootstrap'

import {
  CurrencySelectionContext,
  CurrencySelectionContextType,
} from 'contexts/CurrencySelection'
import { SelectedCurrencyContext } from 'contexts/SelectedCurrency'

export type CurrencySelectorProps = {
  className?: string
}

const CurrencySelector: React.FC<CurrencySelectorProps> = ({
  className = '',
}) => {
  const renderSelector = ({
    currencyOptions,
    selectedCurrency,
    updateCurrency,
  }: CurrencySelectionContextType & { selectedCurrency: string }) => {
    const makeButton = (option: string) => {
      return (
        <ToggleButton variant="light" key={option} value={option}>
          {option}
        </ToggleButton>
      )
    }
    return (
      <ButtonToolbar className={className}>
        <ToggleButtonGroup
          name="selectedCurrency"
          onChange={updateCurrency}
          value={selectedCurrency}
        >
          {currencyOptions.map(makeButton)}
        </ToggleButtonGroup>
      </ButtonToolbar>
    )
  }

  return (
    <SelectedCurrencyContext.Consumer>
      {selectedCurrency => (
        <CurrencySelectionContext.Consumer>
          {props => renderSelector({ ...props, selectedCurrency })}
        </CurrencySelectionContext.Consumer>
      )}
    </SelectedCurrencyContext.Consumer>
  )
}

export default CurrencySelector
