import React from 'react'

import useToggle from 'react-use/lib/useToggle'

export default function useCheckbox(
  initialValue: boolean,
  inputProps?: React.HTMLProps<HTMLInputElement>,
): [React.ReactElement, boolean, () => void] {
  const [value, toggleValue] = useToggle(initialValue)
  const checkbox = (
    <input
      type="checkbox"
      checked={value}
      onChange={toggleValue}
      {...inputProps}
    />
  )
  return [checkbox, value, toggleValue]
}
