import React, { useState } from 'react'

import { Button, Col, Form, FormControl } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import { ExchangeAutocomplete } from 'components/Autocomplete'
import { Exchange as ExchangeType } from 'contexts/Exchanges'
import useSelectedTenant from 'contexts/SelectedTenant'

import { useActions } from './actions'
import { FormValues } from './types'

export const NewCCL: React.FC = () => {
  const [show, setShow] = useState(false)
  const { register, handleSubmit } = useForm<FormValues>()
  const [exchange, setExchange] = useState<ExchangeType | null>(null)
  const { create } = useActions()

  const tenantId = useSelectedTenant().id

  const onSubmit = async (values: FormValues) => {
    create(values)
    handleReset()
  }

  function handleShow() {
    setShow(true)
  }

  function handleReset() {
    setShow(false)
    setExchange(null)
  }

  if (!show) {
    return (
      <Button variant="outline-secondary" onClick={handleShow}>
        New
      </Button>
    )
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} onReset={handleReset}>
      <input type="hidden" name="tenant_id" value={tenantId} ref={register} />
      <Form.Row>
        <Form.Group as={Col} md="auto">
          <Form.Label>Exchange</Form.Label>
          <ExchangeAutocomplete
            onSelect={exchange => setExchange(exchange)}
            selectedItem={exchange}
            inputProps={{ autoFocus: true }}
          />

          {exchange && (
            <input
              type="hidden"
              name="exchange_id"
              value={exchange.id}
              ref={register}
              required
            />
          )}
        </Form.Group>
        <Form.Group as={Col} md="auto">
          <Form.Label>Concentration limit</Form.Label>
          <FormControl
            ref={register}
            name="concentration_limit"
            type="number"
            min={0}
            max={1}
            step={0.00000002}
            required
          />
        </Form.Group>
        <Form.Group as={Col} md="auto" className="align-self-end">
          <Button
            type="submit"
            variant="success"
            style={{ verticalAlign: 'bottom' }}
            disabled={!exchange}
          >
            Create
          </Button>
        </Form.Group>
        <Form.Group as={Col} md="auto" className="align-self-end">
          <Button
            type="reset"
            variant="outline-secondary"
            style={{ verticalAlign: 'bottom' }}
          >
            Cancel
          </Button>
        </Form.Group>
      </Form.Row>
    </Form>
  )
}
