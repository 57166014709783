import React, { useRef } from 'react'

import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import { Glyphicon } from 'components/BootstrapShim'
import DoubleClickButton from 'components/DoubleClickButton'
import EditButton from 'components/EditButton'
import { shortDistanceToNow } from 'utils/datesAndTimes'

export type EditRowButtonsProps = {
  isEditing: boolean
  onSetEditing: () => void
  onDestroy: () => void
  canDestroy?: boolean
  size?: 'sm' | 'lg'
}

/**
 * Use to wrap buttons such that they only take up a single line's worth of
 * height in a table cell.
 *
 * You should set the `style` property of the column that contains these buttons
 * to:
 *
 * ```css
 * padding: 0;
 * height: 0;
 * ```
 */
export const FlexButtonWrapper = styled.div`
  --spacing: 0.75rem;
  padding: 0 var(--spacing);
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
`

export function EditRowButtons(props: EditRowButtonsProps): JSX.Element {
  const { isEditing, onSetEditing, onDestroy, canDestroy = true, size } = props

  return (
    <>
      <EditButton
        isEditing={isEditing}
        setEditing={onSetEditing}
        dirty={true}
        size={size}
      />
      {!isEditing && canDestroy && (
        <DoubleClickButton onClick={onDestroy} size={size}>
          <Glyphicon glyph="trash" />
        </DoubleClickButton>
      )}
    </>
  )
}

export default EditRowButtons

type LastUpdatedInfoProps = {
  updated_at: Date
  updated_by: string
}

export const LastUpdatedInfo: React.FC<LastUpdatedInfoProps> = ({
  updated_at,
  updated_by,
}) => {
  const id = useRef(uuid()).current
  const ago = shortDistanceToNow(updated_at)
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="auto-start"
      overlay={
        <Tooltip id={`updated-tooltip-${id}`}>
          Updated {ago} by {updated_by}
        </Tooltip>
      }
    >
      <Button variant="outline-secondary" size="sm">
        <Glyphicon glyph="user" />
      </Button>
    </OverlayTrigger>
  )
}
