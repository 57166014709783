import { usePollApi } from 'hooks/usePollApi'

import { MarginLimitUsage, TableDatum, UncollateralizedPosition } from './types'

export function useMarginLimitsUsage(): TableDatum[] | undefined {
  const marginLimitsUsage = usePollApi<MarginLimitUsage>({
    path: 'margin_limit_usage',
    params: new URLSearchParams({
      select: '*,updated_by:users!margin_limits_updated_by_id_fkey(name)',
    }),
    addTenantId: true,
  })

  const uncollateralizedPositions = usePollApi<UncollateralizedPosition>({
    path: 'uncollateralized_positions',
    addTenantId: true,
  })

  if (!marginLimitsUsage || !uncollateralizedPositions) {
    return
  }

  return [...uncollateralizedPositions, ...marginLimitsUsage]
}
