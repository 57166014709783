import { useCallback } from 'react'

import useAlerts from 'contexts/Alerts'
import useApi from 'contexts/Api'
import useSelectedTenant from 'contexts/SelectedTenant'
import useHandleApiError from 'hooks/useHandleApiError'
import { Quantity } from 'types/Quantity'
import { getHeadersForApiClient } from 'utils/fetch'

type UseActionsMethods = {
  create: ({
    asset_id,
    delta,
  }: {
    asset_id: number
    delta: Quantity
  }) => Promise<boolean>
  update: ({ id, delta }: { id: number; delta: Quantity }) => Promise<void>
  destroy: ({ id }: { id: number }) => Promise<void>
}

const headers = getHeadersForApiClient('returnRepresentation', 'singleObject')

const rewriteMsg = (msg: string) => {
  if (/multiple \(or no\) rows returned/.test(msg)) {
    return 'Cannot perform the requested action, do you have the correct permissions?'
  }
  return msg
}

export default function useActions(): UseActionsMethods {
  const api = useApi()
  const handleApiError = useHandleApiError(rewriteMsg)
  const showAlert = useAlerts()

  const tenant_id = useSelectedTenant().id

  const create: UseActionsMethods['create'] = useCallback(
    async ({ asset_id, delta }) => {
      try {
        await api.targetDeltas.targetDeltasCreate(
          {
            asset_id,
            // The API types say that `delta` is a string, but it's actually a
            // quantity, so we need to cast
            delta: delta as unknown as string,
            tenant_id,
          },
          undefined,
          { headers },
        )
        showAlert({ message: 'Created target delta', style: 'success' })
        return true
      } catch (err) {
        handleApiError(err)
        return false
      }
    },
    [api.targetDeltas, handleApiError, showAlert, tenant_id],
  )

  const update: UseActionsMethods['update'] = useCallback(
    async ({ id, delta }) => {
      try {
        await api.targetDeltas.targetDeltasPartialUpdate(
          // The API types say that `delta` is a string, but it's actually a
          // quantity, so we need to cast
          { delta: delta as unknown as string },
          {
            id: `eq.${id}`,
          },
          { headers },
        )
      } catch (err) {
        handleApiError(err)
      }
    },
    [api.targetDeltas, handleApiError],
  )

  const destroy: UseActionsMethods['destroy'] = useCallback(
    async ({ id }) => {
      try {
        await api.targetDeltas.targetDeltasDelete(
          {
            id: `eq.${id}`,
          },
          { headers },
        )
      } catch (err) {
        handleApiError(err)
      }
    },
    [api.targetDeltas, handleApiError],
  )

  return { create, update, destroy }
}
