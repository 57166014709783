import React from 'react'

import { TenantId, useTenant } from 'contexts/Tenants'

const Tenant = ({ id }: { id: TenantId }): JSX.Element => {
  const tenant = useTenant(id)

  if (tenant) {
    return <span>{tenant.name}</span>
  } else if (id) {
    throw new Error(`Could not identify tenant ${id}`)
  } else {
    return <span>Loading...</span>
  }
}

export default Tenant
