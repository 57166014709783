import React, { useState } from 'react'

import fileDownload from 'js-file-download'
import { Button, ButtonProps } from 'react-bootstrap'

import useHandleApiError from 'hooks/useHandleApiError'
import { Query, parseQuery } from 'hooks/usePollApi'

import { Glyphicon } from './BootstrapShim'

type ExportButtonDownloadProps = {
  /** A `usePollApi` query object */
  query: Query
  /**
   * Additional search parameters to be merged with the search parameters that
   * are implied by `query`. Useful if you don't want to modify the `query`
   * object to express different parameters to the local instance of
   * `usePollApi`
   */
  searchParams?: URLSearchParams
  /** Name for downloaded file. Does not require a `.csv` extension */
  filename?: string
  /**
   * How many records to include in the downloaded CSV file. Defaults to
   * `Infinity`
   */
  limit?: number
}

const fetchCSV = async (url: string): Promise<Blob> => {
  const res = await fetch(url, {
    credentials: 'include',
    headers: { Accept: 'text/csv' },
  })
  if (!res.ok) {
    const body: { message?: string } = await res.json()
    const message = `${res.status} ${body.message ?? res.statusText}`
    throw new Error(message)
  }
  return res.blob()
}

export const download = async ({
  query,
  searchParams,
  filename: rawFilename,
  limit = Infinity,
}: ExportButtonDownloadProps): Promise<void> => {
  const url = parseQuery(query)

  // If we're taking in data from a remote table (i.e. server-side pagination)
  // we want to get rid of the existing limit/offset params. Clients can
  // manually specify a limit if required.
  if (limit === Infinity) {
    url.searchParams.delete('limit')
  } else {
    url.searchParams.set('limit', limit.toString())
  }
  url.searchParams.delete('offset')

  // sometimes we might want to add additional restrictions on top of a base
  // query that are specific to the CSV export. In these cases, merge them in
  // with the existing query search params. Keys in searchParams will take
  // precedence over those in the main query
  if (searchParams) {
    for (const [k, v] of searchParams.entries()) {
      url.searchParams.set(k, v)
    }
  }

  const text = await fetchCSV(url.href)

  // Use the filename (stripping the file extension if needed). If a filename
  // is not supplied, use the last portion of the pathname (so
  // `/rpc/something` becomes `something.csv`)
  const filename = `${
    rawFilename?.replace(/\.csv$/, '') ?? url.pathname.split('/').pop()
  }-${new Date().toISOString()}.csv`

  fileDownload(text, filename, 'text/csv')
}

const style = {
  height: 'fit-content',
  alignSelf: 'flex-end',
  marginBottom: '10px',
}

type ExportButtonBaseProps = ButtonProps & { children?: never }

export const ExportButtonBase: React.FC<ExportButtonBaseProps> = ({
  ...buttonProps
}) => {
  return (
    <Button style={style} variant="info" {...buttonProps}>
      <Glyphicon glyph="save" /> Export
    </Button>
  )
}

function useHandleDownload(
  props: ExportButtonDownloadProps,
  setSubmitting: (submitting: boolean) => void,
): () => void {
  const handleApiError = useHandleApiError()
  return async function () {
    try {
      setSubmitting(true)
      await download({
        query: props.query,
        searchParams: props.searchParams,
        filename: props.filename,
        limit: props.limit,
      })
    } catch (err) {
      handleApiError(err)
    } finally {
      setSubmitting(false)
    }
  }
}

type ExportButtonProps = ExportButtonDownloadProps &
  Omit<ExportButtonBaseProps, 'onClick'>

export const ExportButton: React.FC<ExportButtonProps> = props => {
  const [submitting, setSubmitting] = useState(false)
  const { query, searchParams, filename, limit, ...buttonProps } = props
  const handleDownload = useHandleDownload(
    { query, searchParams, filename, limit },
    setSubmitting,
  )

  return (
    <ExportButtonBase
      {...buttonProps}
      disabled={submitting || (buttonProps.disabled ?? false)}
      onClick={handleDownload}
    />
  )
}

export type UseExportButtonProps = ExportButtonDownloadProps & {
  buttonProps?: ExportButtonBaseProps
}

/**
 * Hook for using ExportButton if you want to preserve HTML `<form />`
 * behaviour, e.g. because you want to run native browser field validations.
 *
 * The `<ExportButton />` component that is returned will submit the containing
 * form. Passing the `handleSubmit` function to the form's `onSubmit` handler
 * will only download the file once the form's validation logic passes.
 */
export function useExportButton({
  buttonProps,
  ...props
}: UseExportButtonProps): {
  ExportButton: React.FC<ExportButtonBaseProps>
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
} {
  const [submitting, setSubmitting] = useState(false)
  const handleDownload = useHandleDownload(props, setSubmitting)

  async function handleSubmit(
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> {
    event.preventDefault()
    handleDownload()
  }

  const ExportButton: React.FC<ExportButtonBaseProps> = props => (
    <ExportButtonBase
      {...buttonProps}
      {...props}
      disabled={
        submitting || (props.disabled ?? buttonProps?.disabled ?? false)
      }
      type="submit"
    />
  )
  return { handleSubmit, ExportButton }
}
