import React from 'react'

import Exchange from 'components/Exchange'
import Tenant from 'components/Tenant'

import { BreachAlert, NoBreachAlert, getBreaches } from '../BreachAlert'

import { rowStatus } from './rows'
import { CapitalConcentrationLimitRow } from './types'

const breachFormatter = (b: CapitalConcentrationLimitRow) => (
  <>
    <Tenant id={b.tenant_id} />/<Exchange id={b.exchange_id} />
  </>
)

type CapitalConcentrationLimitBreachesProps = {
  rows: CapitalConcentrationLimitRow[]
  showNoBreaches?: boolean
}

export const CapitalConcentrationLimitBreaches: React.FC<
  CapitalConcentrationLimitBreachesProps
> = ({ rows, showNoBreaches = false }) => {
  const { 'urgent-breach': urgentBreach = [], breach = [] } = getBreaches(
    rows,
    rowStatus,
  )
  const breaches = [...urgentBreach, ...breach]
  if (!breaches?.length && showNoBreaches) {
    return <NoBreachAlert />
  }
  return (
    <BreachAlert breaches={breaches} formatter={breachFormatter} keyBy="id" />
  )
}
