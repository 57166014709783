import React from 'react'

import { FormControl } from 'react-bootstrap'
import { UseFormMethods } from 'react-hook-form'

type Register = UseFormMethods['register'] | undefined

type FormControlProps = Omit<
  React.ComponentProps<typeof FormControl>,
  'ref' | 'name' | 'defaultValue'
> & {
  children?: React.ReactNode
}

type EditableFieldProps = {
  isEditing: boolean
  register: Register
  name: string
  value?: string
  render?: () => JSX.Element
  formControlProps?: FormControlProps
}

export function EditableField(props: EditableFieldProps): JSX.Element {
  const { isEditing, register, name, value, formControlProps } = props
  if (isEditing) {
    return (
      <FormControl
        ref={register}
        name={name}
        defaultValue={value}
        {...formControlProps}
      />
    )
  }

  const { render: Render = () => <>{value}</> } = props

  return (
    <>
      {register && typeof value !== 'undefined' && (
        <input type="hidden" ref={register} name={name} value={value} />
      )}
      <Render />
    </>
  )
}
