import React from 'react'

import { UseFormMethods } from 'react-hook-form'

import {
  EditRowButtons,
  EditableField,
  FlexButtonWrapper,
  LastUpdatedInfo,
} from 'components/EditableTableFields'
import Exchange from 'components/Exchange'
import Quantity from 'components/Quantity'
import { ExtendedColumnDescription } from 'components/StyledBoostrapTable'
import { getTenantColumn } from 'components/StyledBoostrapTable/columns'
import Tenant from 'components/Tenant'

import { useActions } from './actions'
import { rowStatus } from './rows'
import { CapitalConcentrationLimitRowEditable } from './types'

const tenantColumn = getTenantColumn<CapitalConcentrationLimitRowEditable>()

type UseColumnsProps = {
  register: UseFormMethods['register']
  showButtons?: boolean
}

const decimalFormatter = (val?: number) => {
  if (typeof val !== 'number') {
    return 'unknown'
  }
  if (val === 0) {
    return '0'
  }

  // Show at 3 significant figures, except for numbers that will round to 1.x,
  // for which let's show at least 3 decimal digits. These are equivalent for
  // numbers between 0.1 and 0.9, so it doesn't matter where exactly the
  // threshold is.
  if (val > 0.5) {
    return val.toLocaleString('en-US', {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    })
  } else {
    return val.toLocaleString('en-US', {
      minimumSignificantDigits: 3,
      maximumSignificantDigits: 3,
    })
  }
}

export const useColumns = ({
  register,
  showButtons = true,
}: UseColumnsProps): ExtendedColumnDescription<CapitalConcentrationLimitRowEditable>[] => {
  const { destroy } = useActions()

  const columns: ExtendedColumnDescription<CapitalConcentrationLimitRowEditable>[] =
    [
      {
        ...tenantColumn,
        formatter: (tenantId, row) => {
          return (
            <EditableField
              name="tenant_id"
              isEditing={false}
              register={row.isEditing ? register : undefined}
              value={tenantId}
              render={() => <Tenant id={tenantId} />}
            />
          )
        },
        headerStyle: undefined,
      },
      {
        dataField: 'exchange_id',
        text: 'Exchange',
        formatter: (exchangeId, row) => {
          return (
            <EditableField
              name="exchange_id"
              isEditing={false}
              register={row.isEditing ? register : undefined}
              value={exchangeId}
              render={() => <Exchange id={+exchangeId} />}
            />
          )
        },
        sort: true,
        sortValue: (_, row) => row.exchange.name,
      },
      {
        dataField: 'concentration_limit',
        text: 'Concentration Limit',
        shortText: 'Limit',
        formatter: (limit, row) => {
          return (
            <EditableField
              name="concentration_limit"
              isEditing={row.isEditing}
              register={row.isEditing ? register : undefined}
              value={limit}
              render={() => <>{decimalFormatter(+limit)}</>}
              formControlProps={{
                defaultValue: limit,
                min: 0,
                max: 1,
                // Equal to one 50 millionth of AUM, which is the smallest
                // non-zero limit we want to define. We might want to define a
                // limit this small if we want to express 'functionally we want no
                // exposure to this exchange, but we have a tiny but non-zero
                // balance that we're unable to withdraw because of fees or
                // minimum withdrawal limits etc, and we don't want it to show up
                // as a limit breach'.
                //
                // Note that this isn't ideal in that the form field will show
                // numbers this small in scientific notation, but this seems OK,
                // and not worth the complication of handling formatting
                // differently for these very small limits.
                step: 0.00000002,
                type: 'number',
              }}
            />
          )
        },
        sort: true,
      },
      {
        dataField: 'concentration_usage',
        text: 'Current Usage',
        shortText: 'Usage',
        formatter: decimalFormatter,
        sort: true,
      },
      {
        dataField: 'concentration_proportion',
        text: 'Concentration Proportion',
        shortText: 'Proportion',
        formatter: decimalFormatter,
        sort: true,
        style: (_, row) => {
          if (
            ['major-breach', 'minor-breach', 'near-breach'].includes(
              rowStatus(row),
            )
          ) {
            return { fontWeight: 'bold' }
          }
          return {}
        },
        sortValue: proportion => {
          // unknown / undefined values represent a possible bug or failure to
          // load so they might represent a monitoring failure and should be
          // treated as possible breaches; sort them above all normal values, but
          // below anything that we know for sure is a breach; i.e. below all > 1
          // values but above all < 1 values
          return proportion ?? 1
        },
      },
      {
        dataField: 'excess',
        text: 'Excess',
        formatter: (_, row) =>
          row.excess && (
            <Quantity
              currency={row.excess.asset_id}
              quantity={row.excess.quantity}
              // This applies to BTC, which is the actual native currency (which
              // we're overriding with nativeSelectedCurrency)
              precision={4}
              // This applies to all other currencies, including USD
              convertedPrecision={2}
              nativeSelectedCurrency="USD"
            />
          ),
        sort: true,
        // sorting by quantity alone should be OK because the numbers are
        // actually all in the same currency (i.e. BTC)
        sortValue: (_, row) => row.excess?.quantity ?? 0,
        headerAlign: 'right',
        align: 'right',
      },
    ]

  const buttonsColumn: ExtendedColumnDescription<CapitalConcentrationLimitRowEditable> =
    {
      dataField: 'buttons',
      isDummyField: true,
      text: '',
      headerStyle: { width: 100 },
      formatter: (_, row) => (
        <FlexButtonWrapper>
          <EditRowButtons
            isEditing={row.isEditing}
            onSetEditing={row.setEditing}
            onDestroy={() => destroy(row)}
            size="sm"
          />
          <LastUpdatedInfo
            updated_at={row.updated_at}
            updated_by={row.user?.name}
          />
        </FlexButtonWrapper>
      ),
      style: { padding: 0, height: 0 },
    }

  if (showButtons) {
    columns.push(buttonsColumn)
  }

  return columns
}
