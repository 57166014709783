import React, { useContext } from 'react'

import { CurrencyOption } from './CurrencySelection'

export const SelectedCurrencyContext =
  React.createContext<CurrencyOption>('Native')

export function useSelectedCurrency(): CurrencyOption {
  return useContext(SelectedCurrencyContext)
}
