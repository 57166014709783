import React, { useCallback, useEffect, useState } from 'react'

import { debounce } from 'lodash'
import PropTypes from 'prop-types'
// TODO: This library is no longer maintained, migrate to react-autosuggest
import Autocomplete from 'react-autocomplete'
import { HotKeys } from 'react-hotkeys'

// Vite doesn't expose a window.global object (at least in dev mode), but
// Webpack does, which has led to some libraries relying on it - case in point,
// react-autocomplete. This doesn't cause issues in production because Rollup
// (Vite's bundler for production builds) apparently polyfills this behaviour,
// but in dev this causes issues, and searching in the Autocomplete causes
// Firefly to crash. So, we just point it to the window object, and everything
// works.
// See: https://stackoverflow.com/questions/72114775/vite-global-is-not-defined
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.global ||= window

const keyMap = { clear: 'escape', submit: 'enter' }

const debounced = debounce(
  // It's not possible to make this take a nicer generic when wrapping with
  // the Lodash typings, which necessitates an any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (url: string, signal: AbortSignal, setItems: (json: any[]) => void) =>
    fetch(url, { signal, credentials: 'include' })
      .then(result => result.json())
      .then(json => {
        if (!signal.aborted) {
          setItems(json)
          return json
        }
      })
      .catch(e => {
        if (e.name !== 'AbortError') {
          throw e
        }
      }),
  200,
)

type Item = { name: string; [k: string]: unknown }

export type BaseAutocompleteProps<T extends Item> = {
  generateUrl: (value: string) => URL
  setValue: (value: string) => void
  value: string
  onSelect?: (value: string, item: T) => void
  inputProps?: Autocomplete.Props['inputProps']
}

function BaseAutocomplete<T extends Item>(
  props: BaseAutocompleteProps<T>,
): JSX.Element {
  const [items, setItems] = useState<T[]>([])

  const { generateUrl, setValue } = props

  const onChange = useCallback(
    e => {
      const value = e.target.value
      setValue(value)
      setItems([])
    },
    [setValue],
  )

  const param = props.value.trim()

  useEffect(() => {
    if (param === '') {
      return
    }

    const url = generateUrl(param)

    const abortController = new AbortController()
    const signal = abortController.signal

    const urlString = url.href.replace(/\+/g, '%20')

    debounced(urlString, signal, setItems)
    return () => {
      debounced.cancel()
      abortController.abort()
    }
  }, [param, generateUrl])

  const handlers = {
    clear: (e: KeyboardEvent | undefined) => {
      e?.stopPropagation()
      setValue('')
      setItems([])
    },
    submit: (e: KeyboardEvent | undefined) => {
      if (items.length === 0) {
        e?.stopPropagation()
        debounced.flush()?.then(json => {
          if (json.length === 0) {
            return
          }
          const item = json[0]
          setItems(json)
          props.onSelect && props.onSelect(item.name, item)
        })
      }
    },
  }

  return (
    <HotKeys keyMap={keyMap} handlers={handlers}>
      <Autocomplete
        inputProps={{ className: 'form-control', ...props.inputProps }}
        getItemValue={() => props.value}
        items={items}
        menuStyle={{
          // The DefinitelyTyped typings don't expose the defaultProps property
          // and it's not possible to add with TS module augmentation because
          // of how the typings have been set up. Lying to the compiler it is...
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...(Autocomplete as any).defaultProps.menuStyle,
          zIndex: 1,
        }}
        renderItem={(item, isHighlighted) => (
          <div
            style={{
              background: isHighlighted ? 'lightgray' : 'white',
              paddingLeft: '4px',
            }}
            key={item.id}
          >
            {item.name}
          </div>
        )}
        value={props.value}
        onChange={onChange}
        onSelect={(_, item) => {
          props.onSelect && props.onSelect(item.name, item)
        }}
      />
    </HotKeys>
  )
}

export default BaseAutocomplete

BaseAutocomplete.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  inputProps: PropTypes.object,
  onSelect: PropTypes.func,
  generateUrl: PropTypes.func.isRequired,
}

BaseAutocomplete.defaultProps = {
  inputProps: {},
}
