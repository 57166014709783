import React, { useCallback, useEffect, useRef } from 'react'

export default function useBroadcastChannel(
  name: string,
  handler: (m: MessageEvent) => void,
): (m: unknown) => void {
  const channel: React.MutableRefObject<BroadcastChannel | undefined> = useRef()
  const savedHandler: React.MutableRefObject<(m: MessageEvent) => void> =
    useRef(() => {})

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    channel.current = new BroadcastChannel(name)
    const onmessage = (m: MessageEvent): void => savedHandler.current(m)
    channel.current.onmessage = onmessage
    return (): void => {
      channel.current?.close()
      channel.current = undefined
    }
  }, [name])

  const postMessage = useCallback(m => channel.current?.postMessage(m), [])

  return postMessage
}
