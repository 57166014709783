import React, { Suspense, useEffect, useMemo, useState } from 'react'

import config, { DEFAULT_PRODUCTION_API_URL } from 'config'
import { useFlags, withLDProvider } from 'launchdarkly-react-client-sdk'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import type { RouteComponentProps } from 'react-router-dom'
import usePrevious from 'react-use/lib/usePrevious'

import { VersionWatcher } from 'components/VersionWatcher'
import { AddSentryUser } from 'contexts/Sentry'
import { TailnetUserProvider, useTailnetUser } from 'contexts/TailnetUser'

import AppHotKeys from './components/AppHotKeys'
import CurrencySelection from './components/CurrencySelection'
import { RightNavKey } from './components/FireflyNavbar'
import { AlertsProvider } from './contexts/Alerts'
import { ApiProvider } from './contexts/Api'
import { AssetsProvider } from './contexts/Assets'
import { ExchangesProvider } from './contexts/Exchanges'
import { FairsProvider } from './contexts/Fairs'
import { MarketsProvider } from './contexts/Markets'
import { TenantProvider } from './contexts/TenantSelection'
import { WalletsProvider } from './contexts/Wallets'
import useHoneycomb from './honeycomb'
import useRefreshCoordinator from './hooks/useRefreshCoordinator'

const AssetPage = React.lazy(() => import('./components/AssetPage'))
const Balances = React.lazy(() => import('./components/Balances'))
const Banks = React.lazy(() => import('./components/Banks'))
const CoinDeck = React.lazy(() => import('./components/CoinDeck'))
const Credentials = React.lazy(() => import('./components/Credentials'))
const DataPage = React.lazy(() => import('./components/DataPage'))
const Fills = React.lazy(() => import('./components/Fills'))
const Fireblocks = React.lazy(() => import('./components/Fireblocks'))
const FireflyNavbar = React.lazy(() => import('./components/FireflyNavbar'))
const Derivatives = React.lazy(() => import('./components/Derivatives'))
const FundingHistory = React.lazy(() => import('./components/FundingHistory'))
const HistoricalNAVs = React.lazy(() => import('./components/HistoricalNAVs'))
const LeverageLimits = React.lazy(() => import('./components/LeverageLimits'))
const Limits = React.lazy(() => import('./components/Limits'))
const MarginBalances = React.lazy(() => import('./components/MarginBalances'))
const MarketPage = React.lazy(() => import('./components/MarketPage'))
const NewTransfer = React.lazy(() => import('./components/Transfers/New'))
const OpenOrders = React.lazy(() => import('./components/OpenOrders'))
const OrderHedgeStatus = React.lazy(
  () => import('./components/OrderHedgeStatus'),
)
const Positions = React.lazy(() => import('./components/Positions'))
const Predictions = React.lazy(() => import('./components/Predictions'))
const RiskPage = React.lazy(() => import('./components/RiskPage'))
const Tenants = React.lazy(() => import('./components/Tenants'))
const Transfers = React.lazy(() => import('./components/Transfers'))
const TWAPs = React.lazy(() => import('./components/TWAPs'))
const UPnL = React.lazy(() => import('./components/UpnlPage'))
const UserPermissions = React.lazy(() => import('./components/UserPermissions'))
const Indices = React.lazy(() => import('./components/Indices'))
const Monitoring = React.lazy(() => import('./components/MonitoringPage'))

type AppProps = {
  rightNav: RightNavKey
  setRightNav: (key: RightNavKey) => void
}

function App({ rightNav, setRightNav }: AppProps): React.ReactElement {
  useHoneycomb()

  return (
    <>
      <Suspense fallback={<div>Loading page...</div>}>
        <Route
          path="/"
          render={({ history, location }): React.ReactElement => (
            <FireflyNavbar
              rightNav={rightNav}
              history={history}
              setRightNav={setRightNav}
              currentPath={location.pathname}
            />
          )}
        />
        <Switch>
          <Route path="/coin_deck" component={CoinDeck} />
          <Route path="/predictions" component={Predictions} />
          <Route path="/derivatives" component={Derivatives} />
          <Route path="/twaps" component={TWAPs} />
          <Route path="/balances" component={Balances} />
          <Route path="/positions" component={Positions} />
          <Route path="/banks" component={Banks} />
          <Route path="/collateral">
            <Redirect to="/limits/margin" />
          </Route>
          <Route path="/upnl" component={UPnL} />
          <Route path="/binance_margin_balances" component={MarginBalances} />
          <Route path="/historical_navs" component={HistoricalNAVs} />
          <Route path="/fireblocks" component={Fireblocks} />
          <Route path="/credentials" component={Credentials} />
          <Route path="/tenants" component={Tenants} />
          <Route path="/user_permissions" component={UserPermissions} />
          <Route path="/indices" component={Indices} />
          <Route path="/monitoring" component={Monitoring} />
          <Route path="/risk" component={RiskPage} />
          <Route path="/leverage_limits" component={LeverageLimits} />
          <Route path="/fills" component={Fills} />
          <Route path="/funding_history" component={FundingHistory} />
          <Route path="/orders" component={OpenOrders} />
          <Route path="/order_hedge_status" component={OrderHedgeStatus} />
          <Route path="/transfers/new" component={NewTransfer} />
          <Route path="/transfers" component={Transfers} />
          <Route path="/limits" component={Limits} />
          <Route path="/data" component={DataPage} />
          <Route
            path="/assets/ticker/:ticker"
            render={(props: RouteComponentProps<{ ticker: string }>) => (
              <AssetPage assetId={{ ticker: props.match.params.ticker }} />
            )}
          />
          <Route
            path="/assets/:id"
            render={(props: RouteComponentProps<{ id: string }>) => (
              <AssetPage assetId={{ id: +props.match.params.id }} />
            )}
          />
          <Route
            path="/markets/:exchange/:asset/:currency?"
            render={props => {
              const key = Object.values(props.match.params).join('/')
              return <MarketPage key={key} />
            }}
          />
        </Switch>
      </Suspense>
      <VersionWatcher />
    </>
  )
}

const LDProvider = ({ children }: { children: React.ReactNode }) => {
  const user = useTailnetUser()

  const Component = useMemo(() => {
    const inner = () => <>{children}</>
    if (config.launchDarklyID === undefined) {
      return inner
    } else {
      return withLDProvider({
        clientSideID: config.launchDarklyID,
        user: { key: `firefly:${user.username}` },
      })(inner)
    }
  }, [user.username, children])

  return <Component />
}

type LDFlags = {
  downtime?: boolean
  apiHostname?: string
}

// Separate router into its own render call to avoid issue arising from
// conflict between react-router and the React >16.3 Context API.
// See https://github.com/ReactTraining/react-router/issues/6072
// The issue is meant to be resolved in 16.4 but still doesn't work here.
function AppWithRouter(): React.ReactElement {
  const { downtime, apiHostname } = useFlags() as LDFlags
  const previousDowntime = usePrevious(downtime)
  const previousApiHostname = usePrevious(apiHostname)
  const triggerRefresh = useRefreshCoordinator()
  const [rightNav, setRightNav] = useState<RightNavKey>('currency')
  const [, setShouldRerender] = useState(false)

  function forceRerender() {
    setShouldRerender(true)
    setTimeout(() => {
      setShouldRerender(false)
    }, 1)
  }

  useEffect(() => {
    if (previousDowntime !== undefined && downtime !== previousDowntime) {
      triggerRefresh()
    }
  }, [downtime, previousDowntime, triggerRefresh])

  /**
   * Watch for changes to apiHostname, and update the config global if a new
   * value comes in.
   *
   * This whole thing (mutating global state + triggering a re-render) is super
   * gross, but it's a quick way to get this up-and-running using our existing
   * Launchdarkly setup, and avoids dealing with a circular dependency on the
   * LaunchDarkly ID, which is also set on the config global.
   */
  useEffect(() => {
    if (apiHostname && apiHostname !== previousApiHostname) {
      config.apiUrl = new URL(`https://${apiHostname}.tail6f62.ts.net`)
      forceRerender()
    } else if (previousApiHostname && !apiHostname) {
      // Fallback to the default if for some reason LaunchDarkly stops sending
      // us a value for apiHostname
      config.apiUrl = DEFAULT_PRODUCTION_API_URL
      forceRerender()
    }
  }, [apiHostname, previousApiHostname, triggerRefresh])

  if (config.launchDarklyID && downtime === undefined) {
    return <div />
  }

  if (downtime) {
    return <span>Firefly is currently down for maintenance.</span>
  }

  return (
    <AddSentryUser>
      <BrowserRouter>
        <CurrencySelection.Manager>
          <TenantProvider>
            <AppHotKeys setRightNav={setRightNav}>
              <AlertsProvider>
                <ApiProvider>
                  <ExchangesProvider>
                    <AssetsProvider>
                      <MarketsProvider>
                        <FairsProvider>
                          <WalletsProvider>
                            <App
                              rightNav={rightNav}
                              setRightNav={setRightNav}
                            />
                          </WalletsProvider>
                        </FairsProvider>
                      </MarketsProvider>
                    </AssetsProvider>
                  </ExchangesProvider>
                </ApiProvider>
              </AlertsProvider>
            </AppHotKeys>
          </TenantProvider>
        </CurrencySelection.Manager>
      </BrowserRouter>
    </AddSentryUser>
  )
}

function AuthenticatedApp(): React.ReactElement {
  return (
    <TailnetUserProvider>
      <LDProvider>
        <AppWithRouter />
      </LDProvider>
    </TailnetUserProvider>
  )
}

export default AuthenticatedApp
