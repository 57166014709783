// The HotKeys component looks like it uses some black class-pattern magic
// to register the this.hotKeys.__mousetrap__ prototype where it's
// accessible to this class. For now, I don't think it's possible to
// rewrite this as a function component, and the react-hotkeys package
// no longer uses MouseTrap as a dependency, so current docs are no help.
// I'm parking the Typescript upgrade here and we should eventually rewrite
// this component with a newer version of react-hotkeys which has a nicer API
//
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react'

import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { HotKeys } from 'react-hotkeys'

export type HotKeyHandlerMap<T> = Record<
  keyof T,
  (event: KeyboardEvent) => void
>

export default class HotkeysWithModal extends React.PureComponent {
  static propTypes = {
    ...HotKeys.propTypes,
    title: PropTypes.string.isRequired,
    // TODO: Add a prop for explanations, show explanation || name
  }

  state = { showModal: false }

  hideModal = () => this.setState({ showModal: false })
  showModal = () => this.setState({ showModal: true })

  handleHelp = e => {
    e.stopPropagation()
    this.showModal()
  }

  componentDidMount() {
    const original = this.hotKeys.__mousetrap__.__proto__.stopCallback.bind(
      this.hotKeys.__mousetrap__,
    )
    this.hotKeys.__mousetrap__.__proto__.stopCallback = (event, element) =>
      original(event, element) &&
      !event.ctrlKey &&
      element.type !== 'number' &&
      element.type !== 'datetime-local'
  }

  render() {
    const { handlers, title, ...otherProps } = this.props
    handlers.help = this.handleHelp

    return (
      <React.Fragment>
        <Modal show={this.state.showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Keyboard shortcuts for {title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {Object.entries(this.props.keyMap).map(([k, v]) => (
              <div key={k}>
                {v.toString()}: {k}
              </div>
            ))}
          </Modal.Body>
        </Modal>
        <HotKeys
          handlers={handlers}
          {...otherProps}
          ref={c => (this.hotKeys = c)}
        />
      </React.Fragment>
    )
  }
}
