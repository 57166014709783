import { useCallback } from 'react'

import useApi from 'contexts/Api'
import useHandleApiError from 'hooks/useHandleApiError'

import { MarginLimitKey, MarginLimitUsage } from './types'

export type MarginLimitsForApi = Record<MarginLimitKey, number> & {
  note: string | null
}

type UseActionsProps = {
  rewriteApiError?: (msg: string) => string
}

type UseActionsMethods = {
  upsert: (
    values: MarginLimitsForApi,
    row: MarginLimitUsage,
  ) => Promise<{ succeeded: boolean }>
  destroy: (row: MarginLimitUsage) => Promise<{ succeeded: boolean }>
}

export function useActions({
  rewriteApiError,
}: UseActionsProps): UseActionsMethods {
  const api = useApi()
  const handleApiError = useHandleApiError(rewriteApiError)

  const upsert: UseActionsMethods['upsert'] = useCallback(
    async (values, row) => {
      try {
        if (row.lower_limit_short === null) {
          await api.marginLimits.marginLimitsCreate({
            wallet_id: row.wallet_id,
            tenant_id: row.tenant_id,
            lower_limit_short: values.lower_limit_short,
            upper_limit_short: values.upper_limit_short,
            lower_limit_long: values.lower_limit_long,
            upper_limit_long: values.upper_limit_long,
            // the API type doesn't explicitly allow passing null, but not passing
            // the argument has the same effect
            note: values.note ?? undefined,
          })
        } else {
          await api.marginLimits.marginLimitsPartialUpdate(
            {
              lower_limit_short: values.lower_limit_short,
              upper_limit_short: values.upper_limit_short,
              lower_limit_long: values.lower_limit_long,
              upper_limit_long: values.upper_limit_long,
              // the API type doesn't explicitly allow passing null, and for
              // updates we can't work around this by just not passing it, because
              // that means "don't update", whereas we want "update with null";
              // despite the API typing, passing a typecasted null works correctly
              note: values.note as unknown as string | undefined,
            },
            {
              wallet_id: `eq.${row.wallet_id}`,
              tenant_id: `eq.${row.tenant_id}`,
            },
          )
        }
        return { succeeded: true }
      } catch (err) {
        handleApiError(err)
        return { succeeded: false }
      }
    },
    [api.marginLimits, handleApiError],
  )

  const destroy: UseActionsMethods['destroy'] = useCallback(
    async row => {
      try {
        await api.marginLimits.marginLimitsDelete({
          wallet_id: `eq.${row.wallet_id}`,
          tenant_id: `eq.${row.tenant_id}`,
        })
        return { succeeded: true }
      } catch (err) {
        handleApiError(err)
        return { succeeded: false }
      }
    },
    [api.marginLimits, handleApiError],
  )

  return { upsert, destroy }
}
