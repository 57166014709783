import { RowStatus } from '../types'

import {
  CapitalConcentrationLimitRow,
  CapitalConcentrationLimitUsage,
} from './types'

export const prepareCapitalConcentrationLimitsRow = (
  row: CapitalConcentrationLimitUsage,
): CapitalConcentrationLimitRow => {
  return {
    id: `${row.exchange_id}-${row.tenant_id}`,
    ...row,
    // Will cast string 'Infinity' to number Infinity
    concentration_proportion: +row.concentration_proportion,
    updated_at: new Date(row.updated_at),
  }
}

export const rowStatus = (row: CapitalConcentrationLimitRow): RowStatus => {
  if (typeof row.concentration_proportion !== 'number') {
    return 'unknown'
  }
  if (row.concentration_proportion >= 1.5) {
    return 'urgent-breach'
  }
  if (row.concentration_proportion >= 1) {
    return 'breach'
  }
  return 'normal'
}
