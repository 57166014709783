import React from 'react'

import Asset, { ASSET_COLORS } from 'components/Asset'
import Exchange from 'components/Exchange'
import Tenant from 'components/Tenant'
import { TenantId } from 'contexts/Tenants'
import { Wallet as W, useWallet } from 'contexts/Wallets'

type WalletId = number

type WalletProps = {
  walletId: WalletId
  tenantId: TenantId
  showTenant?: boolean
  showExchange?: boolean
  colored?: boolean
}

const colorizeWalletName = (name: string): React.ReactNode => {
  const ticker = Object.keys(ASSET_COLORS).find(k => name.includes(k))
  if (!ticker) return name

  const parts = name.split(' ')
  const idx = parts.findIndex(p => p === ticker)
  if (idx < 0) return name

  return parts.map((p, i) => {
    const spacer = i > 0 ? ' ' : ''
    const text = `${spacer}${p}`
    if (i !== idx) {
      return <React.Fragment key={i}>{text}</React.Fragment>
    }

    const style = {
      padding: '0 2px',
      color: '#000',
      ...ASSET_COLORS[ticker],
    }

    return (
      <span key={i} style={style}>
        {text}
      </span>
    )
  })
}

const Wallet = ({
  walletId,
  tenantId,
  showTenant = false,
  showExchange = false,
  colored = false,
}: WalletProps): JSX.Element => {
  const wallet: W | null = useWallet(tenantId, walletId)

  if (wallet) {
    const tenant = showTenant ? (
      <>
        <Tenant id={wallet.tenant_id} />{' '}
      </>
    ) : null
    const exchange = showExchange ? (
      <>
        <Exchange id={wallet.exchange_id} colored={colored} />{' '}
      </>
    ) : null
    const walletName = wallet.name ? (
      colored ? (
        colorizeWalletName(wallet.name)
      ) : (
        wallet.name
      )
    ) : (
      <Asset asset={{ id: wallet.asset_id }} link={false} colored={colored} />
    )

    return (
      <span style={wallet.disabled ? { textDecoration: 'line-through' } : {}}>
        {tenant}
        {exchange}
        {walletName} {wallet.wallet_type}
      </span>
    )
  } else if (walletId) {
    throw new Error(`Could not identify wallet ${walletId}`)
  } else {
    return <span>Loading...</span>
  }
}

export default Wallet
