import React, { useState } from 'react'

import { Alert, Button, Col, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import { AssetAutocompleteUncontrolled } from 'components/Autocomplete/Asset/AssetAutocompleteUncontrolled'
import useAlerts from 'contexts/Alerts'
import useSelectedTenant from 'contexts/SelectedTenant'
import useCheckbox from 'hooks/useCheckbox'

import { useActions } from './actions'

type FormValues = {
  asset_id: string
  delta_limit_lower: string
  delta_limit_upper: string
  note: string
}

export const NewDeltaLimit: React.FC = () => {
  const { register, control, handleSubmit } = useForm<FormValues>()
  const [show, setShow] = useState(false)
  const showAlert = useAlerts()
  const tenant = useSelectedTenant()
  const isGlobalTenant = !tenant.parent_id
  const { create } = useActions()
  const [isAllAssetsCheckbox, isAllAssets, toggleIsAllAssets] =
    useCheckbox(false)

  async function onSubmit({ asset_id, ...values }: FormValues) {
    const assetId = isAllAssets ? null : +asset_id
    const { succeeded } = await create(values, tenant.id, assetId)
    if (succeeded) {
      showAlert({
        message: 'Successfully created delta limit',
        style: 'success',
      })
      handleReset()
    }
  }

  function handleReset() {
    setShow(false)
    if (isAllAssets) {
      toggleIsAllAssets()
    }
  }

  if (!show) {
    return (
      <Button onClick={() => setShow(true)} variant="outline-secondary">
        New
      </Button>
    )
  }

  return (
    <>
      <h4>Create New Delta Limit</h4>
      <Form onSubmit={handleSubmit(onSubmit)} onReset={handleReset}>
        {isGlobalTenant && (
          <Alert variant="warning">
            You are creating a global limit, which will apply to deltas summed
            across all tenants. If you want the limit to apply to a specific
            tenant, select that tenant first.
          </Alert>
        )}
        <Form.Row>
          <Form.Group as={Col} md="2">
            <Form.Label>Asset</Form.Label>
            <AssetAutocompleteUncontrolled
              inputProps={{
                name: 'asset_id',
                disabled: isAllAssets,
                required: !isAllAssets,
              }}
              control={control}
              assetClasses={['crypto']}
            />
            <Form.Label style={{ marginTop: '0.5rem' }}>
              {isAllAssetsCheckbox} apply to all assets
            </Form.Label>
          </Form.Group>
          <Form.Group as={Col} md="2">
            <Form.Label>Delta Limit (lower)</Form.Label>
            <Form.Control
              type="number"
              name="delta_limit_lower"
              required
              ref={register}
              step="any"
            />
          </Form.Group>
          <Form.Group as={Col} md="2">
            <Form.Label>Delta Limit (upper)</Form.Label>
            <Form.Control
              type="number"
              name="delta_limit_upper"
              required
              ref={register}
              step="any"
            />
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Note</Form.Label>
            <Form.Control as="textarea" rows={1} name="note" ref={register} />
          </Form.Group>
          <Form.Group
            as={Col}
            md="auto"
            style={{ paddingTop: 'calc(1.5rem + 5px)' }}
          >
            <Button type="submit" variant="success">
              Create
            </Button>
          </Form.Group>
          <Form.Group
            as={Col}
            md="auto"
            style={{ paddingTop: 'calc(1.5rem + 5px)' }}
          >
            <Button type="reset" variant="danger">
              Cancel
            </Button>
          </Form.Group>
        </Form.Row>
      </Form>
    </>
  )
}
