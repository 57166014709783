import React, { useContext } from 'react'

import { Tenant } from './Tenants'

// TODO Don't hard code
//      Especially now that we're defaulting to a tenant with children
const defaultTenant: Tenant = {
  id: 1,
  name: 'Lantern',
  parent_id: null,
  children: [2, 3, 4, 5, 6],
}

const SelectedTenantContext = React.createContext<Tenant>(defaultTenant)

export { SelectedTenantContext }

export const useSelectedTenant = (): Tenant => useContext(SelectedTenantContext)

export default useSelectedTenant

export function checkGlobalTenant(tenant: Tenant): boolean {
  return !tenant.parent_id
}

export function useIsGlobalTenant(): boolean {
  const selectedTenant = useSelectedTenant()
  return checkGlobalTenant(selectedTenant)
}
