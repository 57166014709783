import { useCallback, useEffect, useRef, useState } from 'react'

// For whatever reason (I suspect related to the fact that we also import it in
// worker.js) doing import Comlink (without the "* as") doesn't work,
// notwithstanding that allowSyntheticDefaultImports is true in our TSConfig.
import * as Comlink from 'comlink'

import useAlerts from 'contexts/Alerts'
import { useId } from 'hooks/useId'
import { usePageVisibility } from 'hooks/usePageVisibility'

import { Query, getUrlKey, useParsedQuery } from './queries'
import { PublicStore, SubscriberCb } from './store'
import SharedWorker from './worker?sharedworker'

export * from './queries'

const worker = new SharedWorker()

const store = Comlink.wrap<PublicStore>(worker.port)

/**
 * Hook for running a query against an API endpoint periodically.
 *
 * The typical use-case is running a query against an endpoint that returns a
 * JSON array of objects. The type parameter defaults are set up to make this
 * use-case convenient (i.e. you only need to supply the first parameter,
 * corresponding to the shape of the objects contained by the returned array).
 * If you are running against an endpoint that returns a singleton (e.g. using
 * PostgREST single-object headers), then setting the second type parameter to
 * `false` allows you to override the implicit listification (`T` -> `T[]`) on
 * the return type.
 *
 * Since rules of hooks don't allow you to conditionally call usePollApi, you
 * can pass `undefined` as the `query` to unsubscribe.
 */
export function usePollApi<
  T,
  ReturnsArrayOfT extends boolean = true,
  R = ReturnsArrayOfT extends true ? T[] : T,
>(
  query: Query | undefined,
  delay = 1000,
  subscriptionCheckThreshold = Math.max(30000, delay * 2),
): R | undefined {
  const id = useId()
  const url = useParsedQuery(query)
  const key = url === undefined ? undefined : getUrlKey(url)
  const keyRef = useRef(key)
  const urlStr = url?.toString()
  const showAlerts = useAlerts()
  const visible = usePageVisibility()
  const [isDestroyed, setIsDestroyed] = useState(false)
  const lastUpdatedRef = useRef<undefined | number>(undefined)
  const disabled =
    typeof query === 'undefined' ||
    !visible ||
    delay === Infinity ||
    isDestroyed

  const [data, setData] = useState<R | undefined>(undefined)

  const onDataReceived: SubscriberCb<R> = useCallback(
    (key, err, data) => {
      if (err) {
        // Unsubscribe the query if the store has been destroyed
        if (err.message === `${id} destroyed`) {
          setIsDestroyed(true)
        } else {
          // Since we only call this callback if we're subscribed, and we only
          // subscribe if we have a url, url can't actually be undefined here.
          showAlerts({
            message: `Request error for ${url?.pathname}: ${err.message}`,
            style: 'danger',
          })
        }
        return
      }
      // This guards against active subscriptions that have registered a
      // callback prior to a query change and that run to completion before
      // being unsubscribed. The callback will eventually be garbage collected,
      // but while it remains active we don't want it overwriting data for the
      // current query with that returned for an outdated query.
      if (keyRef.current === key) {
        setData(data)
        lastUpdatedRef.current = Date.now()
      }
    },
    [id, showAlerts, url?.pathname],
  )

  /** Check that the subscription has received data from the callback recently */
  const checkSubscriptionIsUpdating = useCallback(() => {
    const lastUpdated = lastUpdatedRef.current
    if (!lastUpdated) return
    const threshold = Date.now() - subscriptionCheckThreshold
    if (lastUpdated < threshold) {
      showAlerts({
        message: `Request for ${url?.pathname} is taking a long time to update. If you notice data issues, try refreshing all windows with 'R R'`,
        style: 'warning',
        timeout: 3000,
      })
    }
  }, [showAlerts, subscriptionCheckThreshold, url?.pathname])

  /**
   * This effect does the initial subscription to a query, and unsubscribes if
   * the query changes or the component unmounts
   */
  useEffect(() => {
    // Don't bother subscribing if disabled. The `undefined` conditions are
    // redundant (if they are true then `disabled` is already true) but writing
    // them both exposes this fact to the typechecker.
    if (disabled || key === undefined || urlStr === undefined) {
      lastUpdatedRef.current = undefined
      return
    }
    const subscribe = () =>
      store.subscribe(id, key, urlStr, delay, Comlink.proxy(onDataReceived))
    // make initial subscription
    subscribe()
    // periodically re-subscribe. This functions as a ping, in order to ensure
    // that the query is not garbage collected, but if the query is
    // inadvertently unmounted (e.g. this query is blocked and doesn't ping in
    // time), this will allow the query to be re-subscribed gracefully.
    const ping = setInterval(subscribe, 2000)
    return () => {
      clearInterval(ping)
      store.unsubscribe(id, key)
    }
  }, [delay, id, key, onDataReceived, urlStr, disabled])

  /** This effect keeps track of how long since we last got an update */
  useEffect(() => {
    if (disabled) {
      return
    }
    const check = setInterval(checkSubscriptionIsUpdating, 2000)
    return () => clearInterval(check)
  }, [checkSubscriptionIsUpdating, delay, disabled])

  useEffect(() => {
    keyRef.current = key
  }, [key])

  return data
}
