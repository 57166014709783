/**
 * Drop-in replacement for BootstrapTable (from react-bootstrap-table-next),
 * with the following enhancements:
 *
 * - Ability to specify a `shortText` prop, which will display as the column
 *   header with an `<abbr /> tag to show the full `text` prop on hover
 * - Better positioning/wrapping of filter/sort elements
 * - Column headers are aligned to the top of the row, which ensures they're all
 *   at the same level, irrespective of whether a column has a filter in it or
 *   not
 * - Cells are set to `overflow: auto`, so that data doesn't spill into adjacent
 *   columns
 *
 * Can also be used as a wrapper/base for extending native BootstrapTable
 * functionality that we'd like to apply to all of our tables.
 */
import React from 'react'

import BootstrapTable, {
  BootstrapTableProps,
  ColumnDescription,
} from 'react-bootstrap-table-next'
import styled from 'styled-components'

// Copied directly from the react-bootstrap-table-next typings
// with s/ColumnDescription/ExtendedColumnDescription/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ExtendedHeaderFormatter<T extends object = any> = (
  column: ExtendedColumnDescription<T>,
  colIndex: number,
  components: {
    sortElement: JSX.Element
    filterElement: JSX.Element
  },
) => React.ReactNode

export type ExtendedColumnDescription<
  // We're using the `object` and `any` for exact parity with the
  // BootstrapTableProps generic
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends object = any,
> = Omit<ColumnDescription<T>, 'headerFormatter' | 'filterValue'> & {
  shortText?: string
  headerFormatter?: ExtendedHeaderFormatter<T>
  // Override filterValue to say that it can return either a string or a number
  // (the stock typings say it can only return a string, but number filters will
  // in fact do numeric comparisons)
  // Using `any` for parity with the existing RBT types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterValue?: (cell: any, row: T) => string | number
}

export type ExtendedBootstrapTableProps<
  // We're using the `object` and `any` for exact parity with the
  // BootstrapTableProps generic
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends object = any,
  K = number,
> = Omit<BootstrapTableProps<T, K>, 'columns'> & {
  columns: ExtendedColumnDescription<T>[]
}

const TableWrapper = styled.div`
  table {
    thead {
      th {
        vertical-align: top;
      }
    }
  }

  .header-flex-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .header-filter-element {
    align-self: flex-end;
  }
`

const headerFormatter: ExtendedColumnDescription['headerFormatter'] = (
  column,
  _colIndex,
  { sortElement, filterElement },
) => {
  return (
    <div className="header-flex-wrapper">
      <span>
        {column.shortText ? (
          <abbr title={column.text}>{column.shortText}</abbr>
        ) : (
          column.text
        )}
        {sortElement}
      </span>
      {filterElement && (
        <div className="header-filter-element">{filterElement}</div>
      )}
    </div>
  )
}

const wrapColumns = (
  columns: ExtendedColumnDescription[],
): ExtendedColumnDescription[] =>
  columns.map(col => ({
    headerFormatter,
    ...col,
    headerStyle: (...args) => {
      const definedStyle =
        typeof col.headerStyle === 'function'
          ? col.headerStyle(...args)
          : col.headerStyle
      return {
        overflowX: 'auto',
        ...definedStyle,
      }
    },
    style: (...args) => {
      const definedStyle =
        typeof col.style === 'function' ? col.style(...args) : col.style
      return {
        overflowX: 'auto',
        ...definedStyle,
      }
    },
  }))

export default function StyledBootstrapTable<
  // We're using the `object` and `any` for exact parity with the
  // BootstrapTableProps generic
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends object = any,
  K = number,
>({
  columns,
  ...bootstrapTableProps
}: ExtendedBootstrapTableProps<T, K>): JSX.Element {
  const wrappedCols = wrapColumns(columns)

  return (
    <TableWrapper>
      <BootstrapTable
        columns={wrappedCols as ColumnDescription<T>[]}
        {...bootstrapTableProps}
      />
    </TableWrapper>
  )
}
