import React, { useContext } from 'react'

import { usePollApi } from 'hooks/usePollApi'

export type Exchange = {
  id: number
  name: string
}

type ExchangeMap = { [k: number]: Exchange }
type ExchangeCollections = {
  exchanges: Exchange[]
  exchangesById: ExchangeMap
  exchangesByName: { [k: string]: Exchange }
}

export type ExchangeApi = {
  api_id: number
  name: string
}

export type CredentialProvider = {
  id: number
  name: string
}

const ExchangesContext = React.createContext<ExchangeCollections>({
  exchanges: [],
  exchangesById: {},
  exchangesByName: {},
})
const ExchangeApisContext = React.createContext([] as ExchangeApi[])
const CredentialProvidersContext = React.createContext(
  [] as CredentialProvider[],
)

type Children = { children: React.ReactNode }

export function ExchangesContextProvider(props: Children): JSX.Element {
  const exchanges = usePollApi<Exchange>('exchanges', 60000)

  if (!exchanges) {
    return <>Loading exchanges...</>
  }

  const exchangesById = exchanges.reduce(
    (em, e): ExchangeMap => Object.assign(em, { [e.id]: e }),
    {},
  )

  const exchangesByName = Object.fromEntries(
    exchanges.map<[string, Exchange]>(e => [e.name, e]),
  )

  const exchangesContext = { exchanges, exchangesById, exchangesByName }

  return (
    <ExchangesContext.Provider value={exchangesContext}>
      {props.children}
    </ExchangesContext.Provider>
  )
}

export function ExchangeApisContextProvider(props: Children): JSX.Element {
  const apis = usePollApi<ExchangeApi>('apis', 60000)

  if (!apis) {
    return <>Loading exchange APIs...</>
  }

  return (
    <ExchangeApisContext.Provider value={apis}>
      {props.children}
    </ExchangeApisContext.Provider>
  )
}

export function CredentialProvidersContextProvider(
  props: Children,
): JSX.Element {
  const credentialProviders = usePollApi<CredentialProvider>(
    'credential_providers',
    60000,
  )

  if (!credentialProviders) {
    return <>Loading credential providers...</>
  }

  return (
    <CredentialProvidersContext.Provider value={credentialProviders}>
      {props.children}
    </CredentialProvidersContext.Provider>
  )
}

export const ExchangesProvider = (props: Children): JSX.Element => (
  <ExchangesContextProvider>
    <ExchangeApisContextProvider>
      <CredentialProvidersContextProvider>
        {props.children}
      </CredentialProvidersContextProvider>
    </ExchangeApisContextProvider>
  </ExchangesContextProvider>
)

export const useExchanges = (): Exchange[] =>
  useContext(ExchangesContext).exchanges
export const useExchangeApis = (): ExchangeApi[] =>
  useContext(ExchangeApisContext)
export const useCredentialProviders = (): CredentialProvider[] =>
  useContext(CredentialProvidersContext)

export const useExchange = (id: number): Exchange | null => {
  const exchangesById = useContext(ExchangesContext).exchangesById
  return exchangesById[id]
}

export const useExchangeByName = (name: string): Exchange | null => {
  const { exchangesByName } = useContext(ExchangesContext)
  return exchangesByName[name]
}
