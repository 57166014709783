import React, { useContext, useEffect, useMemo, useRef } from 'react'

import { isEqual, pick } from 'lodash'

import { Fairs as FairsType } from 'api/FireflyApi'
import { usePollApi } from 'hooks/usePollApi'
import useSubscriptions from 'hooks/useSubscriptions'
import { Asset } from 'types/Asset'

import { useAssets } from './Assets'
import { useCurrencySelection } from './CurrencySelection'

export type Fairs = { [k: number]: number }

type FairsContext = {
  fairs: Fairs
  subscribe: (...ids: number[]) => void
  unsubscribe: (...ids: number[]) => void
}
export const FairsContext = React.createContext({} as FairsContext)

type FairRow = { asset_id: number; fair: number }

function FairsProviderInner({
  children,
}: {
  children: React.ReactNode
}): React.ReactElement {
  const [subscriptions, subscribe, unsubscribe] = useSubscriptions('fairs')

  const { currencyOptions } = useCurrencySelection()
  const optionIds = useAssets(currencyOptions).map(a => a.id)

  const comparableOptionIds = JSON.stringify(optionIds)
  useEffect(() => {
    const optionIds = JSON.parse(comparableOptionIds)
    subscribe(...optionIds)
    return (): void => {
      unsubscribe(...optionIds)
    }
  }, [subscribe, unsubscribe, comparableOptionIds])

  const ids = [...new Set([...subscriptions].map(Number))].sort()
  const fields = new Map([['asset_id', ids]])

  const fairRows = usePollApi<FairsType>({ path: 'fairs', fields }) ?? []

  const comparableFairRows = JSON.stringify(fairRows)
  const fairs = useMemo(() => {
    const obj: { [k: number]: number } = {}
    const rows: FairRow[] = JSON.parse(comparableFairRows)
    rows.forEach(({ asset_id, fair }) => {
      obj[asset_id] = fair
    })
    return obj
  }, [comparableFairRows])

  const context = useMemo(() => {
    return { fairs, subscribe, unsubscribe }
  }, [fairs, subscribe, unsubscribe])

  // We can't conditionally render the provider based on whether the fairs query
  // has loaded or not, because there are potential side-effects from child
  // components setting the available currencies that can cause an infinite loop
  return (
    <FairsContext.Provider value={context}>{children}</FairsContext.Provider>
  )
}

export const FairsProvider = React.memo(FairsProviderInner)

export function useFairs(assetIds: number[]): Fairs {
  const { fairs, subscribe, unsubscribe } = useContext(FairsContext)
  const ids = [...new Set(assetIds)].sort()

  // usePrevious doesn't work here; by the time the unmount-only cleanup
  // effect runs both prevIds and ids are back to []. It's a bit of a mystery,
  // but this fixes it.
  const prevIds = useRef(ids ?? [])

  useEffect(() => {
    if (!isEqual(prevIds.current, ids)) {
      unsubscribe(...prevIds.current)
      subscribe(...ids)
      prevIds.current = ids
    }
  })

  // Mount/unmount effects. The main effect doesn't ever run with ids = []
  // (either on mount or on unmount), so this is the only way to reliably
  // subscribe when mounting and unsubscribe when unmounting.
  useEffect(() => {
    subscribe(...ids)
    return (): void => {
      unsubscribe(...prevIds.current)
    }
    // Only run this on mount and unmount
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return pick(fairs, ids)
}

export function useFair(id: number): number {
  const { fairs, subscribe, unsubscribe } = useContext(FairsContext)

  useEffect(() => {
    subscribe(id)
    return (): void => unsubscribe(id)
  }, [id, subscribe, unsubscribe])

  return fairs[id]
}

/**
 * Given some assets, return a lookup table from asset id to asset value. The
 * asset value is based on a fair for the underlying and the contract size.
 */
export function useAssetValues(assets: Asset[] = []): Fairs {
  const getUnderlying = (a: Asset) => a.contract_size?.asset_id ?? a.id
  const fairs = useFairs(assets.map(getUnderlying))
  return Object.fromEntries(
    assets
      .filter(a => getUnderlying(a) in fairs)
      .map(a => [
        a.id,
        fairs[getUnderlying(a)] * (a.contract_size?.quantity ?? 1),
      ]),
  )
}
