import CurrencySelectionManager from './CurrencySelectionManager'
import CurrencySelector, { CurrencySelectorProps } from './CurrencySelector'

type CurrencySelectionType = {
  Manager: React.FC
  Selector: React.FC<CurrencySelectorProps>
}

const CurrencySelection: CurrencySelectionType = {
  Manager: CurrencySelectionManager,
  Selector: CurrencySelector,
}
export default CurrencySelection
