import React, { useContext, useEffect } from 'react'

export type CurrencyOption = 'Native' | 'BTC' | 'USD' | 'ETH' | 'USDT'

export type CurrencySelectionContextType = {
  currencyOptions: CurrencyOption[]
  setOptions: (xs: CurrencyOption[]) => void
  updateCurrency: (x: CurrencyOption) => void
}

export const CurrencySelectionContext = React.createContext({
  currencyOptions: ['Native'],
  setOptions: () => {},
  updateCurrency: () => {},
} as CurrencySelectionContextType)

export function useCurrencySelection(): CurrencySelectionContextType {
  return useContext(CurrencySelectionContext)
}

export const defaultOptions: CurrencyOption[] = [
  'Native',
  'BTC',
  'USD',
  'ETH',
  'USDT',
]

export function useCurrencyOptions(currencyOptions: string[]): void {
  const { setOptions } = useCurrencySelection()

  // Use JSON for array equality
  const currencyOptionsJSON = JSON.stringify([...new Set(currencyOptions)])
  useEffect(() => {
    const options = JSON.parse(currencyOptionsJSON)
    setOptions(options)
    return (): void => setOptions(defaultOptions)
  }, [setOptions, currencyOptionsJSON])
}
