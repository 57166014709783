import React from 'react'

import { Exchange, useExchanges } from 'contexts/Exchanges'

import { AutocompleteSingle, AutocompleteSingleProps } from './ClientSide'

type ExchangeAutocompleteProps = Omit<
  AutocompleteSingleProps<Exchange>,
  | 'items'
  | 'keyBy'
  | 'searchKey'
  | 'formatItem'
  | 'filterItems'
  | 'itemToString'
>
export function ExchangeAutocomplete(
  props: ExchangeAutocompleteProps,
): JSX.Element {
  const exchanges = useExchanges()

  return (
    <AutocompleteSingle
      items={exchanges}
      searchKey="name"
      keyBy="id"
      formatItem={item => item.name}
      {...props}
    />
  )
}
