/** Headers to make PostgREST return a single object, rather than an array */
export const singleObjectHeaders = new Headers({
  Accept: 'application/vnd.pgrst.object+json',
})

/** For POST/PATCH/DELETE requests, return the affected object */
export const returnRepresentationHeaders = new Headers({
  Prefer: 'return=representation',
})

type HeaderId = 'singleObject' | 'returnRepresentation'

const headersMap: Record<HeaderId, Headers> = {
  singleObject: singleObjectHeaders,
  returnRepresentation: returnRepresentationHeaders,
}

/** Get a `Headers` object with the requested PostgREST API headers */
export function getHeaders(...ids: HeaderId[]): Headers {
  const entries = ids.map(id => [...headersMap[id].entries()]).flat()
  return new Headers(entries)
}

/**
 * Get a plain JS object with the requested PostgREST API headers (despite the
 * stated typings, the generated API client can't parse `Headers` objects)
 */
export function getHeadersForApiClient(
  ...ids: HeaderId[]
): Record<string, string> {
  return Object.fromEntries(getHeaders(...ids).entries())
}
